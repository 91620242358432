import React from 'react';
import LazyLoad from 'react-lazyload';
import PostContent from './Post-content';

function PostLazyLoader({ post }) {
  return (
    <LazyLoad height={200} offset={100} once>
      <PostContent {...post} />
    </LazyLoad>
  );
}

export default PostLazyLoader;
