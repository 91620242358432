import { Modal, Button } from 'react-bootstrap';
import { AiOutlineClose } from "react-icons/ai";
import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';

import APICall from '../../api';

const NotificationModel = ({ showModal, handleClose, code }) => {
    // ---------------------------------------------
    // State hooks    
    const [message, setMessage] = useState('');
    const [modalTitle, setModalTitle] = useState('');

    // ---------------------------------------------
    // API Calling
    const setGroupActionApproval = async (action) => {
        try {
          const data = await checkIsInGroup();
          if (data.isGroup) {
            Swal.fire({
              icon: "warning",
              title: "You have joined the other group!",
              text: "Page will be refreshed to fetch new changes!",
            }).then((result) => {
              if (result.isConfirmed || result.dismiss === Swal.DismissReason.timer) {
                window.location.reload();
              }
            });
            return;            
          }

          const contentType = "application/json";
          const endPoint = code['msgCode'] === 'GROUP_JOIN_REQ' ? "User/setGroupJoinApproval" : "User/handleGroupInv";
          const bodyContent = {
            notification_id : code["notification_id"],
            correspond_id : code["correspond_id"],
            approval: action
          }
    
          APICall("POST", endPoint, bodyContent, contentType).then(
            response => {
              if (!response.success && response.message === 'SessionExpired') {
                localStorage.clear();
                window.location.href = '/login?sessionExpired=true';
              }

              if (response.success) {
                const data = response.data;
                Swal.fire({
                    icon: "success",
                    title: "SUCCESS",
                    text: "Action Done!",
                  }).then((result) => {
                    if (result.isConfirmed || result.dismiss === Swal.DismissReason.timer) {
                      window.location.reload();
                    }
                  });
              }
              else {
                console.error('Failed to get notifications', response.message);
                Swal.fire({
                  icon: "error",
                  title: "Get notifications failed!",
                  text: "Something went wrong, please try again later",
                });
              }
            }
          )    
        } catch (error) {
          console.error('An error occurred while fetching notifications', error);
        }      
    };    

    const checkIsInGroup = async () => {
      try {
          const contentType = "application/json";
          const endPoint = "User/isInGroup";
          const bodyContent = {
              userId : Cookies.get('userId'),
          }
          
          return APICall("POST", endPoint, bodyContent, contentType).then(
              response => {
                if (!response.success && response.message === 'SessionExpired') {
                  localStorage.clear();
                  window.location.href = '/login?sessionExpired=true';
                }
  
                if (response.success) {
                  const data = response.data;
                  return data;
                }
                else {
                  console.error('Failed to validate group', response.message);
                  Swal.fire({
                    icon: "error",
                    title: "Join group failed!",
                    text: "Something went wrong, please try again later",
                  });
                }
              }
            )
      } catch (error) {
          console.log("An error occured while validating group");
      }
    }

    // ---------------------------------------------
    // Effect hooks
    useEffect(() => {
        if (code['msgCode'] === 'GROUP_INV') {
          setModalTitle('GROUP INVITATION');
          setMessage("Please click the button to accept/reject the invitation!");
        }
    
        if (code['msgCode'] === 'GROUP_JOIN_REQ') {
          setModalTitle('GROUP JOIN REQUEST');
          setMessage("Please click the button to accept/reject the request!");
        }
      }, [code]);

    return (
        <Modal show={showModal}>      
        <Modal.Dialog className="modal-dialog-centered">       
            <Modal.Body className="modal-body p-4 px-5 d-flex flex-column">
            <Button className="close-btn" variant="none" aria-label="Close" onClick={handleClose} style={{ position: 'absolute', top: '0', marginRight: '-5%' }}>
              <span aria-hidden="true"><AiOutlineClose style={{ fontSize: '1.5rem' }} /></span>
            </Button>
              <div className="main-content text-center">
                <form action="#">
                  <label htmlFor="">{modalTitle}</label>
                  <p className="mb-4">{message}</p>
    
                  <div className="d-flex">
                    <div className="mx-auto d-flex align-items-center justify-content-center">
                      <Button type="button" className="custom-btn btn-primary d-flex align-items-center justify-content-center" 
                              style={{backgroundColor: 'Green', borderColor: 'white'}}
                              onClick={() => setGroupActionApproval("ACCEPT")}
                            onMouseEnter={(e) => {
                              e.target.style.backgroundColor = 'Green';
                              e.target.style.color = 'black';  // Change font color on hover
                            }}
                            onMouseLeave={(e) => {
                              e.target.style.backgroundColor = 'Green';
                              e.target.style.color = 'white';  // Revert font color when mouse leaves
                            }}>
                        Accept
                      </Button>
                      <Button type="button" className="custom-btn btn-primary d-flex align-items-center justify-content-center" 
                              style={{backgroundColor: 'Red', borderColor: 'white'}}
                              onClick={() => setGroupActionApproval("REJECT")}
                            onMouseEnter={(e) => {
                              e.target.style.backgroundColor = 'Red';
                              e.target.style.color = 'black';  // Change font color on hover
                            }}
                            onMouseLeave={(e) => {
                              e.target.style.backgroundColor = 'Red';
                              e.target.style.color = 'white';  // Revert font color when mouse leaves
                            }}>
                        Reject
                      </Button>                      
                    </div>
                  </div>
                </form>
    
              </div>
    
            </Modal.Body>
        </Modal.Dialog>
        </Modal>
    );
}

export default NotificationModel;