import React, {useState, useEffect, useRef} from "react";
import Cookies from "js-cookie";
import Swal from "sweetalert2";

import APICall from "../../../api";
import "sweetalert2/src/sweetalert2.scss";
import "../../../assets/css/GroupInvitation.css"

import $ from "jquery";
import "datatables.net-bs4/css/dataTables.bootstrap4.css";
import "datatables.net";
import "datatables.net-bs4";

const Invitation = ({groupId}) => {
  // ---------------------------------------------------------------------
  // State hooks
  const [availableUsers, setAvailableUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  // ---------------------------------------------------------------------
  // API Calling
  const retrieveAllUser = async () => {
    try {
        const contentType = "application/json";
        const endPoint = "Group/currentGroupUsers";
        const bodyContent = {
          groupId : groupId,
          userId : Cookies.get('userId')
        }
  
        APICall("POST", endPoint, bodyContent, contentType).then(
          response => {
            if (!response.success && response.message === 'SessionExpired') {
              localStorage.clear();
              window.location.href = '/login?sessionExpired=true';
            }

            if (response.success) {
              const data = response.data;
              setAvailableUsers(data);
            }
            else {
              console.error('Failed to get users', response.message);
              Swal.fire({
                icon: "error",
                title: "Get users failed!",
                text: "Something went wrong, please try again later",
              });
            }
          }
        )         
    } catch (error) {
        console.log("An error occured while retrieving user's data", error);
    }
  }

  const inviteUser = async (invited_userID) => {
    try {
      const contentType = "application/json";
      const endPoint = "Group/groupInvitation";
      const bodyContent = {
        groupId : groupId,
        inviting_userID : Cookies.get('userId'),
        invited_userID : invited_userID
      }

      APICall("POST", endPoint, bodyContent, contentType).then(
        response => {
          if (!response.success && response.message === 'SessionExpired') {
            localStorage.clear();
            window.location.href = '/login?sessionExpired=true';
          }

          if (response.success) {
            Swal.fire({
              icon: "success",
              title: "SUCCESS",
              text: "Successfully Invite User!",
            }).then((result) => {
              if (result.isConfirmed || result.dismiss === Swal.DismissReason.timer) {
                window.location.reload();
              }
            });
          }
          else {
            console.error('Failed to get users', response.message);
            Swal.fire({
              icon: "error",
              title: "Invite User failed!",
              text: "Something went wrong, please try again later",
            });
          }
        }
      )  

    } catch (error) {
      console.log("An error occured while inviting user", error);
    }
  }

  // ---------------------------------------------------------------------
  // Event functions
  const handleInviteClick = (id) => {
    inviteUser(id);
  }      


  // ---------------------------------------------------------------------
  // variables  
  const itemsPerPage = 5;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = availableUsers !== null ? availableUsers.slice(indexOfFirstItem, indexOfLastItem) : null;

  const tableRef = useRef();
  useEffect(() => {
    // Initialize DataTable when the component mounts
    if ($.fn.DataTable.isDataTable($(tableRef.current))) {
      $(tableRef.current).DataTable().destroy();
    }
  
    const dataTable = $(tableRef.current).DataTable({
      data: currentItems,
      columns: [
        {
            render: function (data, type, row) {
              return row.first_name + ' ' + row.last_name;
            },
            title: 'NAME'
          },
          { data: 'email', title: 'EMAIL'},
          {
            render: function (data, type, row) {
              // Customize the action column here, for example, add a button
              if (row.invitation_count > 0) {
                return '<button class="custom-btn" disabled>Invited</button>';  
              }
              return '<button class="custom-btn btn-1" id="' + row.id + '">Invite</button>';
            },
            title: 'ACTION',
          },
        ],
      paging: false,
      info: false,
      scrollY: false,      
    });
    // Handle the init event to ensure DataTable is ready
    dataTable.on('init.dt', function () {
      // Use event delegation to handle clicks on the Remove button
      $(tableRef.current).on('click', '.custom-btn', function () {
        const id = $(this).attr('id');
        handleInviteClick(id);
      });
    });

  }, [currentItems]); 


  // ---------------------------------------------------------------------
  // Effect hooks
  useEffect(() => {
    retrieveAllUser();
  }, []);  

  return (
    <div
      className="container d-flex justify-content-center"
      style={{ paddingTop: "40px" }}
    >
      <div className="col-lg-11 d-flex align-items-center justify-content-center">
        <div className="card shadow" style={{ width: "90%" }}>
          <div className="card-header border-0">
            <h3 className="mb-0">Available Members</h3>
          </div>
          <div className="table-responsive" style={{paddingLeft: '10px', paddingRight: '10px'}}>
            <table className="table align-items-center table-flush" ref={tableRef}>
            </table>
          </div>
          <div className="card-footer py-4">
            <nav aria-label="...">
                <ul className="pagination justify-content-end mb-0">
                {
                availableUsers ? (
                    Array.from({ length: Math.ceil(availableUsers.length / itemsPerPage) }, (_, i) => (
                        <li key={i} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
                        <a
                            className="page-link"
                            href="#"
                            onClick={() => setCurrentPage(i + 1)}
                        >
                            {i + 1}
                        </a>
                        </li>
                    ))                    
                ) : null
                }
                </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>    
  );
}

export default Invitation;