import React from 'react';
import LazyLoad from 'react-lazyload';
import Header from './Header';

function HeaderLazyLoad() {
  return (
    <LazyLoad height={200} offset={100} once>
      <Header />
    </LazyLoad>
  );
}

export default HeaderLazyLoad;
