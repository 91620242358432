import React, { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import { mdiCommentTextOutline } from "@mdi/js";
import { FcDisplay } from "react-icons/fc";
import { BsReplyAll } from "react-icons/bs";
import io from 'socket.io-client';
import { HiDotsVertical } from "react-icons/hi";
import { Dropdown } from "react-bootstrap";
import { MdDeleteForever } from "react-icons/md";

import Swal from "sweetalert2";
import Icon from "@mdi/react";
import Cookies from 'js-cookie';

import "bootstrap/dist/css/bootstrap.min.css";
import "../../../assets/css/PostNew.css";
import "../../../assets/css/Comment.css";
import "sweetalert2/src/sweetalert2.scss";

import { getImageDataFromLocalStorage } from "../../Login/login";
import APICall from "../../../api";

let showAmount = 15;

const Comment = ({ postID, postUserID, scivId, msgCode, groupID = null }) => {
  // ---------------------------------------------------------------------
  // State hooks 
  const [isModalOpen, setModalOpen] = useState(false);
  const [commentContents, setCommentContents] = useState(null);
  const [placeHolder, setplaceHolder] = useState('Add a comment...');
  const [commentId, setCommentId] = useState(0);
  const [textButton, setTextButton] = useState('Comment');
  const [textareaValue, setTextAreaValue] = useState();
  const [reply, setReply] = useState(false);
  const [replyToCid, setReplyToCid] = useState(0);
  const [userListPlace, setUserListPlace] = useState(null);
  const [tagUserList, setTagUserList] = useState([]);
  const [tagIndex, setTagIndex] = useState(-1);
  const [tagUserIds, setTagUserIds] = useState([]);
  const [showRemoveComment, setShowRemoveComment] = useState(false);
  const [removeCid, setRemoveCid] = useState("");
  const [tagUserName, setTagUserName] = useState([]);
  // const [showAmount, setShowAmount] = useState(15);
  const observer = useRef();
  const targetRef = useRef();

  const image_data = getImageDataFromLocalStorage();
  var checkCount = 0;

  // ---------------------------------------------------------------------
  // API Calling
  const retrieveComments = async () => {
    try {
      const contentType = "application/json";
      const endPoint = "Post/getComment";
      const bodyContent = {
        postId: postID,
        groupId: groupID,
        showAmount: showAmount
      }      
      APICall("POST", endPoint, bodyContent, contentType).then(
        response => {
          if (!response.success && response.message === 'SessionExpired') {
            localStorage.clear();
            window.location.href = '/login?sessionExpired=true';
          }

          if (response.success) {
            setCommentContents(response.data);
          }
          else {
            console.error("Failed to get Comment");
          }
        }
      )
    } catch (error) {
      console.error("An error occurred while getting comment section", error);
    }
  };  

  const removeComments = async (cid, outerIndex, innerIndex = null) => {
    let commentContent = commentContents.commentModal[outerIndex];
    const newData = {...commentContent};
    
    // Remove index at 2 (profile pic)
    newData.outerComment = newData.outerComment.filter((_,index) => index !== 2);
    newData.subMainComment = newData.subMainComment.map(sublist => {
        return sublist.filter((_, index) => index !== 2);
    });
    
    try {
      const contentType = "application/json";
      const endPoint = "Post/removeComment";
      const bodyContent = {
        "userId" : Cookies.get('userId'),
        "postId" : postID,
        "cid" : cid,
        "groupId" : groupID,
        "commentContent": newData,
        "innerIndex": innerIndex
      }

      APICall("POST", endPoint, bodyContent, contentType).then(
        response => {
          if (!response.success && response.message === 'SessionExpired') {
            localStorage.clear();
            window.location.href = '/login?sessionExpired=true';
          }

          if (response.success) {
            // Fire alert for success
            Swal.fire({
              icon: "success",
              title: "SUCCESS",
              text: "Comments Removed!",
            }).then(() => {
              retrieveComments();
            });
          }
          else {
          // Fire alert for fail
            Swal.fire({
              icon: "error",
              title: "Remove Comments failed!",
              text: "Something went wrong, please try again later",
            });
          }
        }
      )

    } catch (error) {
      console.log("Internal Server Error: " + error)
    }
  }

  const handleComment = () => {
    try {
      var cid;
      if (commentId === 0) {
        if (groupID !== null) { cid = "0:GROUP_OUTER:" + postUserID }
        else { cid = "0:OUTER:" + postUserID }
      } 
      else {
        cid = commentId
      }

      if (textareaValue.length <= 0) {
        // Fire alert for warning
        Swal.fire({
          icon: "warning",
          title: "Empty Imput",
          text: "Please at least type something!",
        });
        return;         
      }

      if (textareaValue.length > 300) {
        // Fire alert for warning
        Swal.fire({
          icon: "warning",
          title: "Words exceed limit!",
          text: "Only maximum 300 character allowed",
        });
        return;       
      }
       
      const contentType = "application/json";
      const endPoint = "Post/makeComment";
      const bodyContent = {
        "userId" : Cookies.get('userId'),
        "postId" : postID,
        "cid" : cid,
        "comment" : textareaValue,
        "groupId" : groupID,
        "tagUserdIds" : tagUserIds
      }
      
      APICall("POST", endPoint, bodyContent, contentType).then(
        response => {
          if (!response.success && response.message === 'SessionExpired') {
            localStorage.clear();
            window.location.href = '/login?sessionExpired=true';
          }

          if (response.success) {
            retrieveComments();
  
          // Fire alert for success
          Swal.fire({
            icon: "success",
            title: "SUCCESS",
            text: "Comments Added!",
          });
          handleCloseReply();
          }
          else {
          // Fire alert for fail
            Swal.fire({
              icon: "error",
              title: "Add Comments failed!",
              text: "Something went wrong, please try again later",
            });
          }
        }
      )
    } catch (error) {
      console.log("An error occured while adding comment", error);
    }

  }

  const fetchTagUserData = (value) => {
    try {
      const contentType = "application/json";
      const endPoint = "User/getAllSearchUser";
      const bodyContent = {};
      
      APICall("POST", endPoint, bodyContent, contentType).then(
        (response) => {
          if (!response.success && response.message === 'SessionExpired') {
            localStorage.clear();
            window.location.href = '/login?sessionExpired=true';
          }

          if (response.success) {
            const data = response.data;
            const results = data.filter((user) => {
              var name = user.first_name + user.last_name;
              return (
                user.uid != Cookies.get("userId") && 
                value &&
                user &&
                name &&
                name.toLowerCase().startsWith(value.toLowerCase()) &&
                (!groupID || parseInt(user.groupId) === parseInt(groupID))
              );
            });
            setTagUserList(results);
          } else {
            console.error('Failed to get all users', response.message);
            Swal.fire({
              icon: "error",
              title: "Get all users failed!",
              text: "Something went wrong, please try again later",
            });
          }
        }
      );
    } catch (error) {
      console.error("An error occurred while getting all users", error);
    }
  };  

  // ---------------------------------------------------------------------
  // Event functions
  const handleOpenReply = (event) => {
    setReply(true);
    setReplyToCid(event.currentTarget.id);
    setCommentId(event.currentTarget.id);
    setTextAreaValue(""); 
  }

  const handleCloseReply = () => {
    setReply(false);
    setReplyToCid(-1); 
    setCommentId(0);
    setTextAreaValue("");
    setTagUserList([]);
    setTagUserIds([]);
    setTagUserName([]);
    setShowRemoveComment(false);
  }

  const handleMention = (e, place) => {
    setUserListPlace(place);
    setTextAreaValue(e.target.value);
    
    if (e.target.value[0] !== '@' || e.target.value.length === 1) {
      setTagUserList([]);
    }
    
    if(e.target.value[e.target.value.length-1] === '@') {
      setTagIndex(e.target.value.length-1);
    }
    
    if (tagIndex >= 0 && e.target.value.length > 1) {
      fetchTagUserData(e.target.value.slice(tagIndex + 1, e.target.value.length));
    }
  }

  const inputMention = (first_name, last_name, uid) => {
    const mentionedUser = first_name + last_name
    const newText = textareaValue.substring(0, tagIndex) + '@' + mentionedUser + textareaValue.substring(textareaValue.length) + " "
    setTagUserList([]);
    setTagUserIds(prevTagUserIds => [...prevTagUserIds, uid]);
    setTagUserName(prevTagUserNames => [...prevTagUserNames, first_name + last_name]);
    setTextAreaValue(newText);
  }

  const countCommentLength = (comments) => {
    let totalLength = 0;
    for (let i = 0; i < comments.commentModal.length; i++) {
      totalLength += 1;
      totalLength += comments.commentModal[i].subMainComment.length;
    }
    return totalLength;
  }

  const renderStyledText = (text) => {
      // Regular expression to match all occurrences of '@username'
      const usernameRegex = /@\w+/g;

      // Extract '@username' parts
      const usernameParts = text.match(usernameRegex) || [];

      // Extract non-username parts
      const nonUsernameParts = text.split(usernameRegex);

      // Combine the parts with styled usernames
      const styledText = nonUsernameParts.map((part, index) => (
          <React.Fragment key={index}>
              {part}
              {usernameParts[index] && ( // Check if there's a corresponding username part
                  <span style={{ color: 'blue' }}>{usernameParts[index].slice(1)}</span>
              )}
          </React.Fragment>
      ));

      // Render the styled text
      return (
          <p className="mb-0 font-monospace" style={{ width: '345px', ...commentSettings }}>
              {styledText}
          </p>
      );
  };


  // ---------------------------------------------------------------------
  // Effect hooks
  useEffect(() => {
      retrieveComments();
      // After rendering the component, wait for 1 second before opening the modal
      if (msgCode && (msgCode === "POST_COMMENT" || msgCode === "POST_COMMENT_TAG" || msgCode === "POST_COMMENT_MENTION")) {
        const timer = setTimeout(() => {
          setModalOpen(scivId ? true : false);
          return () => clearTimeout(timer);
        }, 350);
      
        // Retry mechanism to look for the id
        const checkElement = () => {
          setTimeout(() => {
            const element = document.querySelector(`[comment-scrolliv-id="${scivId}"]`);
            if (element && msgCode !== "POST_COMMENT") {
                element.scrollIntoView({ behavior: 'smooth', block: 'center' });
                element.style.backgroundColor = '#FFFF99';
                setTimeout(() => {
                  element.style.transition = 'background-color 0.5s';
                  element.style.backgroundColor = '';
                }, 500);
            } else if (checkCount < 20) {
              checkCount++;
              checkElement(); // Retry
            } else {
              console.log("Element not found after 20 attempts.");
            }
          }, 1000); // Delay of 1 second before checking
      };
  
      checkElement();      
    }
  }, []);

  // For web socket usage
  useEffect(() => {
    const socket = io("https://www.jungp.online", {
      path: '/socket.io',
      query: {
        user_id: Cookies.get("userId")
      }
    });

    // Listen for the 'notifications' event
    socket.on('newcomments', (data) => {
      if (data.notify) {
        retrieveComments();
      }      
    });

    // Cleanup the socket connection when the component unmounts
    return () => {
      socket.disconnect();
    };
    }, []);

    // useEffect(() => {
    //   retrieveComments();
    // }, [showAmount]); 

    useEffect(() => {
      // Initialize the Intersection Observer only when the modal is open
      if (isModalOpen) {
        const options = {
          root: null,
          rootMargin: '0px',
          threshold: 1.0, // Trigger when the last item fully enters the viewport
        };

        observer.current = new IntersectionObserver(entries => {
          if (entries[0].isIntersecting) {
            showAmount += 5;
            retrieveComments();
          }
        }, options);
  
        if (targetRef.current) {
          observer.current.observe(targetRef.current);
        }
  
        // Cleanup function
        return () => {
          if (targetRef.current) {
            observer.current.unobserve(targetRef.current);
          }
        };
      }
    }, [commentContents, targetRef, isModalOpen]);

  // ---------------------------------------------------------------------
  // Modal prop
  const openModal = () => {
    setReply(false); 
    setCommentId(0);
    setTextButton('Comment');    
    setplaceHolder('Add a comment...');   
    setTextAreaValue("");    
    setModalOpen(true);
  };

  const closeModal = () => {
    handleCloseReply();
    setModalOpen(false);
  };


  // ---------------------------------------------------------------------
  // Comment section styling
  const imgSize = {
    width: '40px',
    height: '40px'
  };

  const commentSettings = {
    marginTop: '-3px',
    fontSize: '0.8em',
    overflowWrap: 'break-word',
    width: '400px'
  }

  const commentTime = {
    fontSize: '0.7em',
    marginTop: '2px'
  }

  const commentUserName = {
    fontSize: '0.8em',
    fontWeight: 800
  }

  const commentReplay = {
    color: '#A8A8A8',
    fontSize: '0.7em',
  }

  return (
    <>
      <div key={postID + postUserID}>
        <p
          onClick={openModal}
          style={{ cursor: "pointer" }}
          className="small-text text-black-50 text-xs"
        >
          <Icon
            path={mdiCommentTextOutline}
            size={1}
            style={{ margin: "0 5px" }}
          />
          {commentContents ? (
            <span>{commentContents.commentCounts}</span>
          ) : 0}
        </p>
        <Modal show={isModalOpen} contentLabel="Nested Comments Modal" dialogClassName="my-modal" onHide={closeModal}>
          <div className="container pb-3 pt-3" style={{backgroundColor: '#f7f6f6', borderRadius: '5px'}}>
            {/* Comment head reply area */}
            <div className="card mb-1" style={{ border: 'None', position: 'relative'}}>
              <div class="card-body d-flex align-items-center" style={{marginLeft: '14px', transform: 'translateY(1%)'}}>
                <img
                  className="mr-3 mb-2 rounded-circle"
                  style={imgSize}
                  alt="Bootstrap Media Preview"
                  src={
                    image_data === "null"
                      ? "/anon.jpg"
                      : `data:image/jpeg;base64,${image_data}`
                  }
                />
                
                <textarea class="form-control" id="exampleFormControlTextarea1" rows="2" type="text"
                          placeholder= {placeHolder} style={{marginLeft: '10px', border: 'None'}} 
                          onChange={e => reply !== true ? handleMention(e, 'OUTER'): null} 
                          value={reply !== true ? 
                            textareaValue
                            : ''}
                />
              </div>              
              <div className="results-list" style={{ zIndex: '1000', marginLeft: '95px', marginTop: '40px' }}>
                {tagUserList && tagUserList.length > 0 && userListPlace === 'OUTER' ? (
                  tagUserList.map((user, index) => (
                      <div className="search-result d-flex align-items-center justify-content-start" id={user.uid} 
                           onClick={() => inputMention(user.first_name, user.last_name, user.uid)}>
                        <img 
                        style={{width: '40px', height: '40px', borderRadius: '20px'}}
                          src={user.profilePic === null ?
                            "/anon.jpg" :
                            `data:image/jpeg;base64,${user.profilePic}`}
                        />
                        <p className="text-black-50, fw-semibold" style={{fontSize: '.9rem', marginLeft: '10px', marginTop: '10px'}}>{user.first_name + user.last_name}</p> 
                      </div>
                  ))
                  ): null
                }
              </div>
            </div>

            {/* Comment body */}
            <div className="card" style={{borderRadius: '0px', border: 'None'}}>
              <div className="card-body row" style={{}}>
                <div className="col-md-12">
                  <div className="row" style={{paddingLeft: '15px', paddingRight: '10px'}}>
                    <div className="col-md-12">
                        {commentContents ?
                          commentContents.commentModal.length > 0 ? (
                            commentContents.commentModal.map((comments, outerIndex) => {
                            return (
                              <div  key={'comment-compo-' + postID + '-' + comments.outerComment[3] + '-' + outerIndex}>
                                {/* Outer comment section */}
                                <div className="d-flex align-items-center" style={{marginBottom: '12px'}}>
                                  <img
                                    className="mr-3 mb-2 rounded-circle"
                                    style={imgSize}
                                    alt="Bootstrap Media Preview"
                                    src={
                                      comments.outerComment[2] === null
                                        ? "/anon.jpg"
                                        : `data:image/jpeg;base64,${comments.outerComment[2]}`
                                    }
                                  />
                                  <div className="media-body" style={{marginLeft: '15px'}} 
                                      comment-scrolliv-id={`${postID}${groupID !== null ? ":GROUP_SUB_MAIN" : ":SUB_MAIN"}:${comments.outerComment[3]}`}>
                                    <div className="d-flex align-items-center">
                                      <h5 className="mb-0" style={commentUserName}>{comments.outerComment[1]}</h5>
                                      <span className="ms-2 text-black-50 fw-bold" style={commentTime}>- {comments.outerComment[4]}</span>
                                    </div>
                                    {comments.outerComment[0][0] === '@' ?
                                      (
                                            renderStyledText(comments.outerComment[0])
                                      ) : (
                                        renderStyledText(comments.outerComment[0])
                                        // <p className="mb-0 font-monospace" style={commentSettings}>{comments.outerComment[0]}</p> 
                                      )
                                    }
                                    <div className="d-flex align-items-center">
                                      <p className="mb-0 fw-bold" style={commentReplay}>Reply</p>
                                      <BsReplyAll style={{marginLeft: '5px', cursor: 'pointer', color: '#A8A8A8'}}
                                                  id={comments.outerComment[3] + (groupID !== null ? ":GROUP_SUB_MAIN" : ":SUB_MAIN") + ":" + comments.outerComment[5] }
                                                  onClick={(e) => handleOpenReply(e)}/>
                                    </div>
                                    {
                                    replyToCid && 
                                      replyToCid === comments.outerComment[3] + (groupID !== null ? ":GROUP_SUB_MAIN" : ":SUB_MAIN") + ":" + comments.outerComment[5] ? (
                                      <div className="card mt-1" style={{border: 'None', position: 'relative'}} key={comments.outerComment[3] + (groupID !== null ? ":GROUP_SUB_MAIN" : ":SUB_MAIN") + ":" + comments.outerComment[5]}>
                                        <textarea className="form-control" placeholder="Reply here..." style={{paddingRight: '120px'}}
                                                  onChange={e => handleMention(e, 'INNER')} value={textareaValue}></textarea>
                                        
                                        <div className="results-list" style={{ zIndex: '1000', marginLeft: '1px', marginTop: '40px' }}>
                                          {tagUserList && tagUserList.length > 0 && userListPlace === 'INNER' ? (
                                            tagUserList.map((user, index) => (
                                                <div className="search-result d-flex align-items-center justify-content-start" id={user.uid} 
                                                    onClick={() => inputMention(user.first_name, user.last_name, user.uid)}>
                                                  <img 
                                                  style={{width: '40px', height: '40px', borderRadius: '20px'}}
                                                    src={user.profilePic === null ?
                                                      "/anon.jpg" :
                                                      `data:image/jpeg;base64,${user.profilePic}`}
                                                  />
                                                  <p className="text-black-50, fw-semibold" style={{fontSize: '.9rem', marginLeft: '10px', marginTop: '10px'}}>{user.first_name + user.last_name}</p> 
                                                </div>
                                            ))
                                            ): null
                                          }
                                        </div>
                                        
                                        <button className="btn btn-primary rounded-1 mb-1"
                                                onClick={() => handleCloseReply()}
                                                style={{position: 'absolute', bottom: '0', right: '0', background: 'gray', border: 'gray'}}
                                                onMouseEnter={(e) => {
                                                  e.target.style.color = 'white';  // Change font color on hover
                                                }}
                                                onMouseLeave={(e) => {
                                                  e.target.style.color = 'white';  // Revert font color when mouse leaves
                                                }}>
                                                  Close
                                        </button>
                                        <button className="btn btn-primary rounded-1 mb-1" 
                                                style={{position: 'absolute', bottom: '0', right: '80px', background: 'gray', border: 'gray'}}
                                                onClick={handleComment}
                                                onMouseEnter={(e) => {
                                                  e.target.style.color = 'white';  // Change font color on hover
                                                }}
                                                onMouseLeave={(e) => {
                                                  e.target.style.color = 'white';  // Revert font color when mouse leaves
                                                }}                                              >
                                                  Submit
                                        </button>
                                      </div>
                                    ) : null
                                    }                                  
                                  </div>
                                  { parseInt(comments.outerComment[5]) === parseInt(Cookies.get("userId")) ? (
                                      <HiDotsVertical style={{fontSize: '1.1rem', color: 'gray', cursor: 'pointer'}} 
                                                      onClick={() => {setShowRemoveComment(!showRemoveComment); 
                                                                      setRemoveCid(`${postID}${groupID !== null ? ":GROUP_SUB_MAIN" : ":SUB_MAIN"}:${comments.outerComment[3]}`)}}/>
                                    ) : null
                                  }
                                  {
                                    showRemoveComment && removeCid === `${postID}${groupID !== null ? ":GROUP_SUB_MAIN" : ":SUB_MAIN"}:${comments.outerComment[3]}` ? (
                                    <Dropdown className="form-group justify-content-between align-items-center" 
                                              style={{position: 'absolute', left: 370, zIndex: 1000}} show={showRemoveComment}>
                                      <Dropdown.Menu style={{minWidth: '1px', width: '160px', border: 'none'}}>
                                        <div className="card d-flex align-items-start">
                                          <ul className="list-group list-group-flush">
                                            <li className="list-group-item d-flex justify-content-start align-items-center" style={{height: '50px'}}>
                                              <div className="d-flex justify-content-start align-items-center" style={{marginLeft: '-8px', cursor: 'pointer'}}
                                                    onClick={() => removeComments(`${postID}${groupID !== null ? ":GROUP_SUB_MAIN" : ":SUB_MAIN"}:${comments.outerComment[3]}`, outerIndex)}>
                                                <MdDeleteForever style={{fontSize: '1.6rem', color: 'gray', marginRight: '5px'}}/>
                                                <div className="flex-column">
                                                  <p className="text-black heading fw-semibold" style={{fontSize: '.9rem', marginBottom: '-4px'}}>
                                                      Delete
                                                  </p>
                                                  <p className="text-black-50 mb-0 fw-semibold" style={{fontSize: '.7rem'}}>
                                                    Remove Comment
                                                  </p>
                                                </div>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                    ) : null
                                  }
                                </div>

                                {/* Sub Main comment section */}
                                {comments.subMainComment.map((subComments, innerIndex) => (
                                  <div className="d-flex align-items-center" style={{marginLeft: '50px', marginBottom: '10px'}} 
                                        key={`${postID}:${groupID !== null ? "GROUP_" + subComments[5] : subComments[5]}:${subComments[3]}`}>
                                    <img
                                      className="mr-3 mb-2 rounded-circle"
                                      style={imgSize}
                                      alt="Bootstrap Media Preview"
                                      src={
                                        subComments[2] === null
                                          ? "/anon.jpg"
                                          : `data:image/jpeg;base64,${subComments[2]}`
                                      }
                                    />
                                    <div className="media-body" style={{marginLeft: '15px'}}
                                        comment-scrolliv-id={`${postID}:${groupID !== null ? "GROUP_" + subComments[5] : subComments[5]}:${subComments[3]}`}>
                                      <div className="d-flex align-items-center">
                                        <h5 className="mb-0" style={commentUserName}>{subComments[1]}</h5>
                                        <span className="ms-2 text-black-50 fw-bold" style={commentTime}>- {subComments[4]}</span>
                                      </div>
                                        {
                                          subComments[0].slice(0, 2) === 'u:' ? (
                                            renderStyledText(subComments[0])
                                          ) : (
                                            renderStyledText(subComments[0])
                                          )
                                        }   
                                        <div className="d-flex align-items-center">                                                                
                                          <p className="mb-0 fw-bold" style={commentReplay}>Reply</p>
                                            <BsReplyAll style={{marginLeft: '5px', cursor: 'pointer', color: '#A8A8A8'}}
                                                id={subComments[3] + ":" + (groupID !== null ? "GROUP_" + subComments[5]
                                                                            : subComments[5]) + ":" + subComments[6] + ":" + comments.outerComment[3]}
                                                onClick={(e) => handleOpenReply(e)}
                                                />         
                                        </div>   
                                        {
                                          replyToCid && 
                                            replyToCid === subComments[3] + ":" + (groupID !== null ? "GROUP_" + subComments[5]
                                                                                   : subComments[5]) + ":" + subComments[6] + ":" + comments.outerComment[3] ? (
                                              <div className="card mt-1" style={{border: 'None', position: 'relative'}}>
                                              <textarea className="form-control" placeholder="Reply here..." style={{paddingRight: '120px'}}
                                                        onChange={e => handleMention(e, 'NESTED')} value={textareaValue}></textarea>
                                              <div className="results-list" style={{ zIndex: '1000', marginLeft: '1px', marginTop: '40px' }}>
                                                {tagUserList && tagUserList.length > 0 && userListPlace === 'NESTED' ? (
                                                  tagUserList.map((user, index) => (
                                                      <div className="search-result d-flex align-items-center justify-content-start" id={user.uid} 
                                                          onClick={() => inputMention(user.first_name, user.last_name, user.uid)}>
                                                        <img 
                                                        style={{width: '40px', height: '40px', borderRadius: '20px'}}
                                                          src={user.profilePic === null ?
                                                            "/anon.jpg" :
                                                            `data:image/jpeg;base64,${user.profilePic}`}
                                                        />
                                                        <p className="text-black-50, fw-semibold" style={{fontSize: '.9rem', marginLeft: '10px', marginTop: '10px'}}>{user.first_name + user.last_name}</p> 
                                                      </div>
                                                  ))
                                                  ): null
                                                }
                                              </div>                                                 
                                              <button className="btn btn-primary rounded-1 mb-1" 
                                                      onClick={() => handleCloseReply()}
                                                      style={{position: 'absolute', bottom: '0', right: '0', background: 'gray', border: 'gray'}}
                                                      onMouseEnter={(e) => {
                                                        e.target.style.color = 'white';  // Change font color on hover
                                                      }}
                                                      onMouseLeave={(e) => {
                                                        e.target.style.color = 'white';  // Revert font color when mouse leaves
                                                      }}>
                                                        Close
                                              </button>
                                              <button className="btn btn-primary rounded-1 mb-1" 
                                                      style={{position: 'absolute', bottom: '0', right: '80px', background: 'gray', border: 'gray'}}
                                                      onClick={handleComment}
                                                      onMouseEnter={(e) => {
                                                        e.target.style.color = 'white';  // Change font color on hover
                                                      }}
                                                      onMouseLeave={(e) => {
                                                        e.target.style.color = 'white';  // Revert font color when mouse leaves
                                                      }}                                              >
                                                        Submit
                                              </button>
                                            </div>
                                          ) : null
                                        }                                                                          
                                    </div>
                                    {/* {console.log(parseInt(subComments[6]) === parseInt(Cookies.get("userId")))} */}
                                    { parseInt(subComments[6]) === parseInt(Cookies.get("userId")) ? (
                                      <div style={{ marginLeft: '-5%' }}>
                                      <HiDotsVertical style={{fontSize: '1.1rem', color: 'gray', cursor: 'pointer'}} 
                                                      onClick={() => {setShowRemoveComment(!showRemoveComment);
                                                                      setRemoveCid(`${postID}:${groupID !== null ? "GROUP_" + subComments[5] : subComments[5]}:${subComments[3]}`)}}/>
                                      </div>
                                      ) : null
                                    }
                                    {
                                      showRemoveComment && removeCid === `${postID}:${groupID !== null ? "GROUP_" + subComments[5] : subComments[5]}:${subComments[3]}` ? (
                                      <Dropdown className="form-group justify-content-between align-items-center" 
                                                style={{position: 'absolute', left: 370, zIndex: 1000}} show={showRemoveComment}>
                                        <Dropdown.Menu style={{minWidth: '1px', width: '160px', border: 'none'}}>
                                          <div className="card d-flex align-items-start">
                                            <ul className="list-group list-group-flush">
                                              <li className="list-group-item d-flex justify-content-start align-items-center" style={{height: '50px'}}>
                                                <div className="d-flex justify-content-start align-items-center" style={{marginLeft: '-8px', cursor: 'pointer'}}
                                                      onClick={() => removeComments(`${postID}:${groupID !== null ? "GROUP_" + subComments[5] : subComments[5]}:${subComments[3]}`, outerIndex, innerIndex)}>
                                                  <MdDeleteForever style={{fontSize: '1.6rem', color: 'gray', marginRight: '5px'}}/>
                                                  <div className="flex-column">
                                                    <p className="text-black heading fw-semibold" style={{fontSize: '.9rem', marginBottom: '-4px'}}>
                                                        Delete
                                                    </p>
                                                    <p className="text-black-50 mb-0 fw-semibold" style={{fontSize: '.7rem'}}>
                                                      Remove Comment
                                                    </p>
                                                  </div>
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                      ) : null
                                    }
                                  </div>     
                                ))}
                                {parseInt(outerIndex) === commentContents.commentModal.length - 1 ? (
                                  <div ref={targetRef} style={{ display: showAmount > countCommentLength(commentContents) ? 'none' : 'block'}}>
                                    .
                                  </div>
                                ) : null}
                              </div>
                            )})
                          ) : ( 
                            <>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              <FcDisplay style={{fontSize: '100px'}}/>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              <h3>No Comment Yet!</h3>
                            </div>                            
                            </>
                            ) : (null)             
                        }                                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Modal.Footer>
            <div class="d-flex align-items-center justify-content-end">     
              <button type="button" class="btn btn-secondary" data-mdb-ripple-init 
                      style={{borderRadius: '5px', height:'40px', width: '75px', marginRight: '10px', marginTop: '-4px', fontFamily: 'monospace'}}
                      onClick={closeModal}>Close</button>                        
              <button type="button" class="btn btn-secondary" data-mdb-ripple-init 
                      style={{borderRadius: '5px', height:'40px', width: '90px', marginTop: '-4px', fontFamily: 'monospace'}}
                      onClick={handleComment}>Comment</button>                   
            </div>  
            </Modal.Footer>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Comment;

