import { Modal, Button, ModalBody } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';

import PostContent from '../Post/Post-content';
import GroupPostContent from '../Group/Group-post-content';
import APICall from '../../../api';

const PostNotificationModel = ({ showModal, handleClose, id, msgCode }) => {
    // ---------------------------------------------
    // State hooks    
    const [postData, setPostsData] = useState(null)
    const [isClosing, setIsClosing] = useState(false);
    const [groupId, setGroupId] = useState(-1);
    
    // ---------------------------------------------
    // API Calling
    const retrievePostDB = async () => {
      const data = await checkIsInGroup();
      if (!data.inGroup) { return; }
      setGroupId(data.groupId);

      try {
        const contentType = "application/json";
        const endPoint = "Post/getPostwId";
        var bodyContent;
        if (id.includes("GROUP")) {
          bodyContent = {
            userId : Cookies.get('userId'),
            postId : id.includes(":") ? id.split(":")[0] : id,
            groupId : data.groupId
          }
        } else {
          bodyContent = {
            userId : Cookies.get('userId'),
            postId : id.includes(":") ? id.split(":")[0] : id
          }
        }
        
        APICall("POST", endPoint, bodyContent, contentType).then(
          response => {
            if (!response.success && response.message === 'SessionExpired') {
              localStorage.clear();
              window.location.href = '/login?sessionExpired=true';
            }

            if (response.success) {
              const data = response.data[0];
              console.log(data)
              setPostsData(data);
            }
            else {
              console.error('Failed to get post', response.message);
              Swal.fire({
                icon: "error",
                title: "Get post failed!",
                text: "Something went wrong, please try again later",
              });
            }
          }
        )    
      } catch (error) {
        console.error('An error occurred while fetching posts', error);
      }      
    };   
    
    const checkIsInGroup = async () => {
      try {
          const contentType = "application/json";
          const endPoint = "User/isInGroup";
          const bodyContent = {
              userId : Cookies.get('userId'),
          }
          
          return APICall("POST", endPoint, bodyContent, contentType).then(
              response => {
                if (!response.success && response.message === 'SessionExpired') {
                  localStorage.clear();
                  window.location.href = '/login?sessionExpired=true';
                }
  
                if (response.success) {
                  const data = response.data;
                  return data;
                }
                else {
                  console.error('Failed to join group', response.message);
                  Swal.fire({
                    icon: "error",
                    title: "Join group failed!",
                    text: "Something went wrong, please try again later",
                  });
                }
              }
            )
      } catch (error) {
          console.log("An error occured while validating group");
      }
    }

    // ---------------------------------------------
    // Effect hooks
    useEffect(() => {
        retrievePostDB();
      }, []);

    // ---------------------------------------------
    // Event functions
    const handleModalClose = () => {
        setIsClosing(true); // Trigger animation
        setTimeout(() => {
            handleClose(); // Close modal after animation duration
        }, 300);
    };

    return (
        <Modal show={showModal} dialogClassName="post-notificaion-modal" 
               onHide={handleModalClose}>        
               <div className={`post-notificaion-modal ${isClosing ? 'slide-out' : ''}`}>
                {   
                    postData && id.includes("GROUP") ? (
                      <Modal.Dialog style={{maxWidth: '800px'}}>
                        <div className='d-flex align-items-center justify-content-center' style={{marginBottom: '-15px', marginLeft: '-40px', marginRight: '-40px'}}>
                          <GroupPostContent post={postData} groupId={groupId} scivId={id.includes(":") ? id : null} msgCode={msgCode}/>                      
                        </div>
                      </Modal.Dialog>
                    ) : postData && (
                        <PostContent {...postData} scivId={id.includes(":") ? id : null} msgCode={msgCode}/>
                    )
                }
                <div style={{marginBottom: '-30px', marginTop: '-700px'}}></div>
                </div>
        </Modal>
    );
}

export default PostNotificationModel;