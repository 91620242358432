import React, { useState } from "react";
import { mdiHeartOutline, mdiHeart } from "@mdi/js";
import { useNavigate } from "react-router-dom";
import Icon from "@mdi/react";
import Carousel from "react-bootstrap/Carousel";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import { MdDeleteForever } from "react-icons/md";
import { AiTwotoneDelete } from "react-icons/ai";
import { HiDotsVertical } from "react-icons/hi";
import { Dropdown } from "react-bootstrap";

import "sweetalert2/src/sweetalert2.scss";

import Comment from "./Comment";
import FullPost from "./FullPost";
import APICall from "../../../api";

const PostContent = ({
  postID,
  first_name,
  last_name,
  profilePic,
  content,
  postLikes,
  postTime,
  is_admin,
  mediaType,
  image_data,
  currentUserHitLikes,
  userID,
  userLikeList,
  sharePostID,
  sharedAmt,
  oriPostUserId,
  oriPostUserPf,
  oriPostUserfn,
  oriPostUserln,
  oriPostProfilePic,
  oriPostContent,
  oriPostImageData,
  oriPostPostTime,
  oriIsAdmin,
  oriMediaType,
  scivId,
  msgCode
}) => {
  // ---------------------------------------------------------------------
  // State hooks   
  const [togglelikes, setToggleLikes] = useState(currentUserHitLikes === undefined ? false : true);
  const [postLLikes, setPostLikes] = useState(postLikes);
  const [userLikesList, setUserLikeList] = useState(userLikeList);
  const [showFullPost, setShowFullPost] = useState(false);
  const [showRemovePost, setShowRemovePost] = useState(false);
  const navigate = useNavigate();
  
  // ---------------------------------------------------------------------
  // API Calling
  const handleLike = async () => {
    try {
      const contentType = "application/json";
      const endPoint = "Post/likePost";
      const bodyContent = {
        userId: Cookies.get("userId"),
        postId: postID,
        likeHit: togglelikes,
        recipientId: userID
      }

      APICall("POST", endPoint, bodyContent, contentType).then(
        response => {
          if (!response.success && response.message === 'SessionExpired') {
            localStorage.clear();
            window.location.href = '/login?sessionExpired=true';
          }

          if (response.success) {
            const newPostLikes = response.data;
            setPostLikes(newPostLikes["newPostLikes"]);
            setUserLikeList(newPostLikes["newPostULL"]);
            setToggleLikes(!togglelikes); 
          }
          else {
            console.error('Failed to get likes', response.message);
            Swal.fire({
              icon: "error",
              title: "Get likes failed!",
              text: "Something went wrong, please try again later",
            });
          }
        }
      )       
    } catch (error) {
      console.error("An error occurred while liking post", error);
    }
  };

  const removePost = async (postId) => {
    try {
      const contentType = "application/json";
      const endPoint = "Post/deletePost";
      const bodyContent = {}
      const urlArgs = "?postId="+postId
      
      APICall("DELETE", endPoint, bodyContent, contentType, urlArgs).then(
        response => {
          if (!response.success && response.message === 'SessionExpired') {
            localStorage.clear();
            window.location.href = '/login?sessionExpired=true';
          }

          if (response.success) {
            Swal.fire({
              icon: "success",
              title: "SUCCESS",
              text: "Post Removed!",
            }).then((result) => {
              if (result.isConfirmed || result.dismiss === Swal.DismissReason.timer) {
                window.location.reload();
              }
            });
          }
          else {
            console.error('Failed to get likes', response.message);
            Swal.fire({
              icon: "error",
              title: "Get likes failed!",
              text: "Something went wrong, please try again later",
            });
          }
        }
      )       
    } catch (error) {
      console.error("An error occurred while liking post", error);
    }
  }


  // ---------------------------------------------------------------------
  // Event functions
  const redirectViewProfile = (directUid) => {
    navigate(`/home/profile/${directUid}`);
  }

  const handleCloseFullPost = () => {
    setShowFullPost(false);
  }

  const isPortrait = (base64Data) => {
    const image = new Image();
    image.src = `data:image/jpeg;base64,${base64Data}`; // Adjust this based on the image format
    return image.height >= image.width;
  };

  const getImageStyle = (imgSrc, width) => {
    const isPotrait = isPortrait(imgSrc);
    if (isPotrait) {
      return {
        width: 'auto',
        marginLeft: 'auto',
        marginRight: 'auto',
      }
    }
    return {
      width: '100%'
    }
  }

  return (
    <div className="card col-lg-10 mb-4 rounded-4" style={{ width: "100%" }} id={`post-${postID}`}>
      {/* Post's owner information */}
      <div className="mb-2" style={{ marginLeft: "25px" }}>
        <div className="d-flex align-items-start mt-2">
          <img
            src={
              profilePic === null
                ? "/anon.jpg"
                : `data:image/jpeg;base64,${profilePic}`
            }
            onClick={() => redirectViewProfile(userID)}
            className="rounded-circle mt-n5"
            alt="coder"
            style={{ width: "50px", height: "50px", marginTop: '5px', cursor: 'pointer' }}
          />
          <div className="flex-column mt-1" style={{ marginLeft: "8px" }}>
            <div className="d-flex mt-2">
              <h5
                className="card-title fw-semibold"
                style={{ fontSize: "1rem", cursor: 'pointer' }}
                onClick={() => redirectViewProfile(userID)}
              >
                {first_name + last_name} 
              </h5>
              <h5 className="small-text text-black-50 fw-semibold" style={{fontSize: '0.75em', marginLeft: '4px', marginTop: '3.2px'}}> - {postTime}</h5>
            </div>
            <p
              className="small-text text-black-50 text-xs mb-2"
              style={{ marginTop: "-8px", marginBottom: "0", fontSize: "75%" }}
            >
              {`${is_admin === 1 ? 'Admin' : 'User'} | ${sharePostID !== null ? 'Shared publicly' : 'Post publicly'} | ${mediaType.charAt(0).toUpperCase() + mediaType.slice(1)}`}
            </p>
          </div>
          {
            parseInt(userID) === parseInt(Cookies.get("userId")) ? (
            <div style={{marginLeft: 'auto', marginRight: '10px', marginTop: '8px'}}>
              <HiDotsVertical style={{fontSize: '1.3rem', color: 'gray', cursor: 'pointer'}} onClick={() => setShowRemovePost(!showRemovePost)}/>
              {
                showRemovePost ? (
                  <Dropdown className="form-group justify-content-between align-items-center" 
                            style={{position: 'absolute', left: 435, zIndex: 1000, marginTop: '15px'}} show={showRemovePost}>
                  <Dropdown.Menu style={{minWidth: '1px', width: '160px', border: 'none'}}>
                    <div className="card d-flex align-items-start">
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item d-flex justify-content-start align-items-center" style={{height: '50px'}}>
                          <div className="d-flex justify-content-start align-items-center" style={{marginLeft: '-8px', cursor: 'pointer'}}
                               onClick={() => removePost(postID)}>
                            <MdDeleteForever style={{fontSize: '1.6rem', color: 'gray', marginRight: '5px'}}/>
                            <div className="flex-column">
                              <p className="text-black heading fw-semibold" style={{fontSize: '.9rem', marginBottom: '-4px'}}>
                                  Delete
                              </p>
                              <p className="text-black-50 mb-0 fw-semibold" style={{fontSize: '.7rem'}}>
                                Remove the post
                              </p>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </Dropdown.Menu>
              </Dropdown>
                ) : null
              }
            </div>
            ) : null
          }
        </div>
        {/* Post's owner information Ends here */}

        {/* Post's content */}
        <p
          className="text-black-50 mt-2 mb-0"
          style={{ color: "#757a91", textAlign: "justify", maxWidth: "95%", whiteSpace: "pre-line", fontSize: '.9rem', fontWeight: '500' }}
        >
          {content}
        </p>
        {/* Post's content Ends here */}
      </div>

      {/* Post's images */}
      { sharePostID !== null && oriPostUserId === undefined ? (
        <div className="d-flex align-items-center justify-content-center">
          <AiTwotoneDelete style={{fontSize: '2rem', marginTop: '-12px'}}/>
          <p className="text-black fw-semibold" style={{lineHeight: 1}}>The original post has been deleted <br></br> by the post's owner</p>
        </div>
        ) :
      sharePostID !== null ? (
        <div class="form-group mb-2 d-flex justify-content-center align-items-center">
          <div className="card col-lg-10 mb-4 rounded-4" style={{ width: "95%" }}>
            <div className="mb-2" style={{ marginLeft: "25px"}}>
                <div className="d-flex align-items-start mt-2">
                <img
                    src={
                      oriPostProfilePic === null
                        ? "/anon.jpg"
                        : `data:image/jpeg;base64,${oriPostProfilePic}`
                    }
                    className="rounded-circle mt-n5"
                    alt="coder"
                    style={{ width: "50px", height: "50px", marginTop: '5px', cursor: 'pointer'}}
                    onClick={() => redirectViewProfile(oriPostUserId)}
                />
                <div className="flex-column mt-1" style={{ marginLeft: "8px" }}>
                    <h5
                    className="card-title mt-2 fw-semibold"
                    style={{ fontSize: "1rem", cursor: 'pointer' }}
                    onClick={() => redirectViewProfile(oriPostUserId)}
                    >
                    {oriPostUserfn + oriPostUserln}
                    </h5>
                    <p
                    className="small-text text-black-50 text-xs mb-2"
                    style={{ marginTop: "-3%", marginBottom: "0", fontSize: "75%" }}
                    >
                    {`${oriIsAdmin === 1 ? 'Admin' : 'User'} | Post publicly | ${oriMediaType.charAt(0).toUpperCase() + oriMediaType.slice(1)}`}
                    </p>
                </div>
                </div>
                <p
                className="text-black-50 mt-2 mb-0"
                style={{ color: "#757a91", textAlign: "justify", maxWidth: "95%", fontSize: '.9rem', fontWeight: '500' }}
                >
                {oriPostContent}
                </p>
            </div>
            <div className="text-center" onClick={() => setShowFullPost(!showFullPost)} style={{cursor: 'pointer'}}>
                {oriPostImageData.length > 4 ? (
                  // 4 images
                  <div className="row" style={{height: '340px', padding: '5px'}}>
                    <div className="col-md-6" style={{ width: '60%', maxHeight: '100%', display: 'flex', flexDirection: 'column', marginRight: '-15px', marginLeft: '7px'}}>
                      <img src={`data:image/jpeg;base64,${oriPostImageData[0]}`} alt="Image 1" className="rounded-3" 
                            style={{ height: '49%', marginBottom: '5px', ...getImageStyle(oriPostImageData[0], '49%') }}/>
                      <img src={`data:image/jpeg;base64,${oriPostImageData[1]}`} alt="Image 2" className="rounded-3" 
                            style={{ height: '49%', marginBottom: '5px', ...getImageStyle(oriPostImageData[1], '49%') }} />
                    </div>
                    <div className="col-md-6" style={{ width: '40%', maxHeight: '100%', display: 'flex', flexDirection: 'column' }}>
                      <img src={`data:image/jpeg;base64,${oriPostImageData[2]}`} alt="Image 3" className="rounded-3 d-flex" 
                            style={{ height: '32%', marginBottom: '5px', ...getImageStyle(oriPostImageData[2], '32%') }} />
                      <img src={`data:image/jpeg;base64,${oriPostImageData[3]}`} alt="Image 4" className="rounded-3" 
                            style={{ height: '32%', marginBottom: '5px', ...getImageStyle(oriPostImageData[3], '32%') }} />
                      <img src={`data:image/jpeg;base64,${oriPostImageData[4]}`} alt="Image 5" className="rounded-3" 
                            style={{ height: '32%', marginBottom: '5px', ...getImageStyle(oriPostImageData[4], '32%') }} />
                    </div>
                  </div>                  
                ) : oriPostImageData.length > 3 ? (
                    // 4 images
                    <div className="row" style={{height: '340px', padding: '5px'}}>
                      <div className="col-md-6" style={{ width: '50%', maxHeight: '100%', display: 'flex', flexDirection: 'column', marginRight: '-15px', marginLeft: '7px'}}>
                        <img src={`data:image/jpeg;base64,${oriPostImageData[0]}`} alt="Image 1" className="rounded-3"
                              style={{ height: '49%', marginBottom: '5px', ...getImageStyle(oriPostImageData[0], '49%') }}/>
                        <img src={`data:image/jpeg;base64,${oriPostImageData[1]}`} alt="Image 2" className="rounded-3" 
                              style={{ height: '49%', marginBottom: '5px', ...getImageStyle(oriPostImageData[1], '49%') }} />
                      </div>
                      <div className="col-md-6" style={{ width: '50%', maxHeight: '100%', display: 'flex', flexDirection: 'column' }}>
                        <img src={`data:image/jpeg;base64,${oriPostImageData[2]}`} alt="Image 4" className="rounded-3" 
                              style={{ height: '49%', marginBottom: '5px', ...getImageStyle(oriPostImageData[2], '49%') }} />
                        <img src={`data:image/jpeg;base64,${oriPostImageData[3]}`} alt="Image 5" className="rounded-3" 
                              style={{ height: '49%', marginBottom: '5px', ...getImageStyle(oriPostImageData[3], '49%') }} />
                      </div>
                    </div>
                ) : oriPostImageData.length > 1 ? (
                  <Carousel>
                  {oriPostImageData.map((image, index) => (
                    <Carousel.Item key={index}>
                      <img
                        className="d-block rounded-3 mb-3 mx-auto"
                        src={`data:image/jpeg;base64,${image}`}
                        // src="/banner.jpg"
                        style={{ height: "330px", width: 'auto' }}
                      />
                    </Carousel.Item>
                  ))}
                </Carousel>                  
                ) : oriPostImageData.length === 1 && oriPostImageData[0] !== null ? (
                <img
                    className="d-block rounded-3 mb-3 mx-auto"
                    src={`data:image/jpeg;base64,${oriPostImageData[0]}`}
                    // src="/banner.jpg"
                    style={{ height: "330px", width: 'auto'  }}
                />
                ): null}
            </div>         
          </div>                                           
        </div>                       
      ) : (
        <div className="text-center" onClick={() => setShowFullPost(!showFullPost)}  style={{cursor: 'pointer'}}>
        {image_data.length > 4 ? (
          // 4 images
          <div className="row" style={{height: '340px', padding: '5px', marginBottom: '5px'}}>
            <div className="col-md-6" style={{ width: '60%', maxHeight: '100%', display: 'flex', flexDirection: 'column', marginRight: '-15px', marginLeft: '7px'}}>
            <img src={`data:image/jpeg;base64,${image_data[0]}`} alt="Image 5" className="rounded-3"
                          onLoad={(e) => {
                            const img = e.target;
                            const settings = getImageStyle(image_data[0], '49%');
                            img.style.width = settings.width;
                            img.style.height = '49%';
                            img.style.marginLeft = settings.marginLeft;
                            img.style.marginRight = settings.marginRight;
                            }}
                          style={{ marginBottom: '5px'}} />
              <img src={`data:image/jpeg;base64,${image_data[1]}`} alt="Image 5" className="rounded-3" 
                          onLoad={(e) => {
                            const img = e.target;    
                            const settings = getImageStyle(image_data[1], '49%');
                            img.style.width = settings.width;
                            img.style.height = '49%';
                            img.style.marginLeft = settings.marginLeft;
                            img.style.marginRight = settings.marginRight;                            
                            }}
                          style={{ marginBottom: '5px'}} />
            </div>
            <div className="col-md-6" style={{ width: '40%', maxHeight: '100%', display: 'flex', flexDirection: 'column' }}>
            <img src={`data:image/jpeg;base64,${image_data[2]}`} alt="Image 5" className="rounded-3" 
                          onLoad={(e) => {
                            const img = e.target;    
                            const settings = getImageStyle(image_data[2], '32%');
                            img.style.width = settings.width;
                            img.style.height = '32%';
                            img.style.marginLeft = settings.marginLeft;
                            img.style.marginRight = settings.marginRight;                           
                            }}
                          style={{ marginBottom: '5px'}} />
              <img src={`data:image/jpeg;base64,${image_data[3]}`} alt="Image 5" className="rounded-3" 
                          onLoad={(e) => {
                            const img = e.target;  
                            const settings = getImageStyle(image_data[3], '32%');  
                            img.style.width = settings.width;
                            img.style.height = '32%';
                            img.style.marginLeft = settings.marginLeft;
                            img.style.marginRight = settings.marginRight;                               
                            }}
                          style={{ marginBottom: '5px'}} />
              <img src={`data:image/jpeg;base64,${image_data[4]}`} alt="Image 5" className="rounded-3" 
                          onLoad={(e) => {
                            const img = e.target;    
                            const settings = getImageStyle(image_data[4], '32%');  
                            img.style.width = settings.width;
                            img.style.height = '32%';
                            img.style.marginLeft = settings.marginLeft;
                            img.style.marginRight = settings.marginRight;                               
                            }}
                          style={{ marginBottom: '5px'}} />
            </div>
          </div>    
        ) : image_data.length > 3 ? (
            // 4 images
            <div className="row" style={{height: '340px', padding: '5px', marginBottom: '15px'}}>
              <div className="col-md-6" style={{ width: '50%', maxHeight: '100%', display: 'flex', flexDirection: 'column', marginRight: '-15px', marginLeft: '7px'}}>
              <img src={`data:image/jpeg;base64,${image_data[0]}`} alt="Image 4" className="rounded-3" 
                          style={{marginBottom: '15px'}}
                            onLoad={(e) => {
                              const img = e.target;
                              const settings = getImageStyle(image_data[0], '49%');    
                              img.style.width = settings.width;
                              img.style.height = '49%';
                              img.style.marginLeft = settings.marginLeft;
                              img.style.marginRight = settings.marginRight;
                              }} />
                <img src={`data:image/jpeg;base64,${image_data[1]}`} alt="Image 4" className="rounded-3" 
                            style={{marginBottom: '15px'}}
                            onLoad={(e) => {
                              const img = e.target;    
                              const settings = getImageStyle(image_data[1], '49%'); 
                              img.style.width = settings.width;
                              img.style.height = '49%';
                              img.style.marginLeft = settings.marginLeft;
                              img.style.marginRight = settings.marginRight;
                              }} />
              </div>
              <div className="col-md-6" style={{ width: '50%', maxHeight: '100%', display: 'flex', flexDirection: 'column' }}>
                <img src={`data:image/jpeg;base64,${image_data[2]}`} alt="Image 4" className="rounded-3" 
                            style={{marginBottom: '15px'}}
                            onLoad={(e) => {
                              const img = e.target;    
                              const settings = getImageStyle(image_data[2], '49%'); 
                              img.style.width = settings.width;
                              img.style.height = '49%';
                              img.style.marginLeft = settings.marginLeft;
                              img.style.marginRight = settings.marginRight;
                              }} />
                <img src={`data:image/jpeg;base64,${image_data[3]}`} alt="Image 5" className="rounded-3" 
                            style={{marginBottom: '15px'}}
                            onLoad={(e) => {
                              const img = e.target;    
                              const settings = getImageStyle(image_data[3], '49%'); 
                              img.style.width = settings.width;
                              img.style.height = '49%';
                              img.style.marginLeft = settings.marginLeft;
                              img.style.marginRight = settings.marginRight;
                              }}
                      />
              </div>
            </div> 
        ) : image_data.length > 1 ? (
            <Carousel>
              {image_data.map((image, index) => (
                <Carousel.Item key={index}>
                  <img
                    className="d-block rounded-3 mb-3 mx-auto"
                    src={`data:image/jpeg;base64,${image}`}
                    // src="/banner.jpg"
                    onLoad={(e) => {
                      const img = e.target;    
                      if (isPortrait(image)) {
                        img.style.height = '340px';
                        img.style.width = 'auto';
                      }
                      else {
                        img.style.height = '340px';
                        img.style.width = '94%';
                      }
                  }} 
                    // style={{ width: isPortrait(image) ? 'auto' : '94%', height: "340px", }}
                  />
                </Carousel.Item>
              ))}
            </Carousel>         
        ) : image_data.length === 1 && image_data[0] !== null ? (
          <img
            className="d-block rounded-3 mb-3 mx-auto"
            src={`data:image/jpeg;base64,${image_data[0]}`}
            onLoad={(e) => {
              const img = e.target;    
              if (isPortrait(image_data[0])) {
                img.style.height = '340px';
                img.style.width = 'auto';
              }
              else {
                img.style.height = '340px';
                img.style.width = '94%';
              }
          }} 
            // style={{ width: isPortrait(image_data[0]) ? 'auto' : '94%', height: "340px", }}
          />
        ) : (
          <div className="mb-2"></div>
        )}
      </div>
      )}
      {/* Post's images Ends here */}

      {/* Full Post */}
      {
        showFullPost &&  (
          <FullPost handleClose={handleCloseFullPost} showModal={showFullPost}     
            postProps={{
              postID,
              first_name,
              last_name,
              profilePic,
              content,
              postLLikes,
              postTime,
              is_admin,
              mediaType,
              image_data,
              togglelikes,
              userID,
              userLikeList,
              sharePostID,
              sharedAmt,
              oriPostUserId,
              oriPostUserPf,
              oriPostUserfn,
              oriPostUserln,
              oriPostProfilePic,
              oriPostContent,
              oriPostImageData,
              oriPostPostTime,
              oriIsAdmin,
              scivId,
              msgCode,
          }}/> 
        )
      }
      {/* Full Post Ends Here */}

      {/* Post bottom part - userLikelist, likes, comment, share */}
      <div
        className="mb-2"
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginRight: "5%",
        }}
      >
        <div
          style={{
            marginRight: "auto",
            marginTop: "-3%",
            marginLeft: "30px",
            display: "flex",
          }}
        >
          {userLikesList !== undefined && userLikesList.length > 2 ? (
            <>
            {userLikesList[0][1] === null ? (
              <img
                src="/anon.jpg"
                className="rounded-circle mt-n5"
                alt="coder"
                style={{ width: "40px", height: "40px", marginLeft: "-10px", border: "2px solid #ffffff", borderRadius: "50%" }}
              />              
            ) : (
              <img
                src={`data:image/jpeg;base64,${userLikesList[0][1]}`}
                className="rounded-circle mt-n5"
                alt="coder"
                style={{ width: "40px", height: "40px", marginLeft: "-10px" }}
              />  
            )}
            {userLikesList[1][1] === null ? (
              <img
                src="/anon.jpg"
                className="rounded-circle mt-n5"
                alt="coder"
                style={{ width: "40px", height: "40px", marginLeft: "-10px", border: "2px solid #ffffff", borderRadius: "50%" }}
              />              
            ) : (
              <img
                src={`data:image/jpeg;base64,${userLikesList[1][1]}`}
                className="rounded-circle mt-n5"
                alt="coder"
                style={{ width: "40px", height: "40px", marginLeft: "-10px"}}
              />  
            )}            
            <div style={{ marginLeft: "10px" }}>
              <p
                className="text-small fw-semibold"
                style={{ marginBottom: "-4px", fontSize: ".9rem" }}
              >
                {userLikesList[0][0]}, {userLikesList[1][0]}
              </p>
              <p
                className="small-text text-black-50 text-xs fw-semibold"
                style={{ margin: "0", fontSize: ".8rem" }}
              >
                and {postLLikes-2} more liked this
              </p>
            </div>
            </>
          ) : userLikesList && userLikesList.length === 2 ? (
            <>
            <img
              src={
                userLikesList[0][0] !== null ?
                `data:image/jpeg;base64,${userLikesList[0][1]}`:
                '/anon.jpg'
              }
              className="rounded-circle mt-n5"
              alt="coder"
              style={{ width: "40px", height: "40px", marginLeft: "-10px", border: "2px solid #ffffff", borderRadius: "50%" }}
            />
            <img
                src={
                  userLikesList[0][0] !== null ?
                  `data:image/jpeg;base64,${userLikesList[1][1]}`:
                  '/anon.jpg'
                }
                className="rounded-circle mt-n5"
                alt="coder"
                style={{ width: "40px", height: "40px", marginLeft: "-10px"}}
            />  
            <div style={{ marginLeft: "10px" }}>
              <p
                className="text-small fw-semibold"
                style={{ marginBottom: "-4px", fontSize: ".9rem" }}
              >
                {userLikesList[0][0]}, {userLikesList[1][0]}
              </p>
              <p
                className="small-text text-black-50 text-xs fw-semibold"
                style={{ margin: "0", fontSize: ".8rem" }}
              >
                liked this
              </p>
            </div>
            </>
          ) : userLikesList !== undefined && userLikesList.length === 1 ? (
            <>
              <img
                src={
                  userLikesList[0][1] !== null ?
                  `data:image/jpeg;base64,${userLikesList[0][1]}`
                  : "/anon.jpg"
                }
                className="rounded-circle mt-n5"
                alt="coder"
                style={{ width: "40px", height: "40px", marginLeft: "-10px", border: "2px solid #ffffff", borderRadius: "50%" }}
              />      
            <div style={{ marginLeft: "10px" }}>
              <p
                className="text-small fw-semibold"
                style={{ marginBottom: "-4px", fontSize: ".9rem" }}
              >
                {userLikesList[0][0]}
              </p>
              <p
                className="small-text text-black-50 text-xs fw-semibold"
                style={{ margin: "0", fontSize: ".8rem" }}
              >
                liked this
              </p>
            </div>              
            </>
          ) : (
              <>
              <div style={{ marginLeft: "-5px" }}>
                <p
                  className="text-small fw-semibold"
                  style={{ marginBottom: "-4px", fontSize: ".9rem" }}
                >
                  No one
                </p>
                <p
                  className="small-text text-black-50 text-xs fw-semibold"
                  style={{ margin: "0", fontSize: ".8rem" }}
                >
                  liked this yet!
                </p>
              </div>              
             </>
          )}        
        </div>
        <div className="d-flex" style={{marginRight: '-3%'}} key={'comment-' + postID + '-' + userID}>
          <p
            className="small-text text-black-50 text-xs"
            onClick={handleLike}
            style={{ cursor: "pointer" }}
          >
            <Icon
              path={togglelikes ? mdiHeart : mdiHeartOutline}
              size={1}
              style={{ margin: "0 5px" }}
            />
            {postLLikes}
          </p>
              {/* <SharePost postId={postID} userId={userID} fname={first_name} lname={last_name} imgData={image_data} sharedContent={content} sharedAmount={sharedAmt}
              profilePic={profilePic} sharePostID={sharePostID} oriPostContent={oriPostContent} oriPostImageData={oriPostImageData} oriPostPostTime={oriPostPostTime}
              oriPostUserId={oriPostUserId} oriPostUserfn={oriPostUserfn} oriPostUserln={oriPostUserln} mediaType={mediaType} oriPostUserPf={oriPostUserPf}/> */}
          
          <Comment key={postID + userID} postID={postID} postUserID={userID} scivId={scivId} msgCode={msgCode}/>
        </div>
      </div>
      {/* Post bottom part - userLikelist, likes, comment, share Ends here*/}
    </div>
  );
};

export default PostContent;
