import React, { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import styled from 'styled-components';
import io from 'socket.io-client';
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { CiBellOn } from "react-icons/ci";
import { IoHomeOutline } from "react-icons/io5";
import { GrGroup } from "react-icons/gr";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxOpen } from '@fortawesome/free-solid-svg-icons';
import { HiOutlineTicket } from "react-icons/hi2";
import { MdOutlineLogout } from "react-icons/md";
import { HiOutlineMail } from "react-icons/hi";
import { FaSignsPost } from "react-icons/fa6";
import { AiFillLike } from "react-icons/ai";
import { FiPhone } from "react-icons/fi";
import { GiWorld } from "react-icons/gi";
import { MdOutlineDriveFileRenameOutline } from "react-icons/md";
import { VscIssues } from "react-icons/vsc";
import { FaShareSquare } from "react-icons/fa";
import { FaRegCommentDots } from "react-icons/fa";
import { IoTicketSharp } from "react-icons/io5";
import { HiOutlineUserGroup } from "react-icons/hi2";
import { LuUserCog } from "react-icons/lu";
import Swal from 'sweetalert2';

import NotificationModel from './NotificaionModel';
import PostNotificationModel from './Post/Post-notification';
import APICall from '../../api';
import { SearchBar } from "./Search/SearchBar";
import { SearchResultsList } from "./Search/SearchResultList";
import { getImageDataFromLocalStorage } from '../Login/login';

const HoverableIcon = styled.div`
  font-size: 1.5rem;
  position: relative;
  cursor: pointer;

  &::before {
    content: ${({ hasUnseen }) => (hasUnseen ? "'●'" : "''")};
    position: absolute;
    top: 0px;
    right: 0px;
    color: green;
    font-size: 13px;
  }
`;

const Header = () => {
  // ----------------------------------------------------
  // State hooks
  const [showDropdownNotification, setShowDropdownNotification] = useState(false);
  const [showDropdownTicket, setShowDropdownTicket] = useState(false);
  const [showDropDownGroup, setshowDropDownGroup] = useState(false);
  const [showPostNotiModel, setShowPostNotiModel] = useState(false);
  const [tickets, setTickets] = useState('');
  const [ticketSeen, setTicketSeen] = useState(localStorage.getItem('userSeenTicket') === 'true' ? true : false);
  const [notifications, setNotifications] = useState('');
  const [showGroupModal, setShowGroupModal] = useState(false);
  const [msgCode, setMsgCode] = useState({});
  const [searchUser, setSearchUser] = useState('');
  const [messageCode, setMessageCode] = useState(null);
  const [correspondId, setCorrespondId] = useState(null);
  const [checkIssue, setCheckIssue] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // ----------------------------------------------------
  // Event functions
  const imageData = getImageDataFromLocalStorage();

  const handleTicket = () => {
    setShowDropdownTicket(!showDropdownTicket);
    localStorage.setItem('userSeenTicket', true);
    setTicketSeen(true);
  }

  const redirectTicketPage = () => {
    if (Cookies.get("is_admin") === '1') {
      window.location.href = '/home/admin/ticket';
    }
    else {
      window.location.href = '/home/user/ticket';
    }
  }

  const redirectViewProfile = (directUid) => {
    navigate(`/home/profile/${directUid}`);
  }

  const redirectGroupPage = async () => {
    const data = await checkIsInGroup();
    if (data.inGroup) {
      const targetPath = `/home/group/${data.groupId}`;

      if (location.pathname === targetPath) {
        // Reload the page if already on the target path
        window.location.reload();
      } else {
        // Navigate to the target path
        navigate(targetPath);
      }
    }
    else {
        Swal.fire({
          icon: "warning",
          title: "No Group Found",
          text: "You will be redirect to all group page as you're not in a group yet!",
        }).then((result) => {
          if (result.isConfirmed || result.dismiss === Swal.DismissReason.timer) {
            navigate(`/home/group/all`);
          }
        });
    }
  }

  const handleNotificationPopupModal = (msgCode, correspond_id, notification_id) => {
    setMsgCode({
      msgCode: msgCode,
      correspond_id: correspond_id,
      notification_id: notification_id
    });
    setShowGroupModal(true);
  }

  const handleNotificationClick = async (msgCode, correspond_id, notification_id, seen) => {
    if (seen) {
      return;
    }
    
    switch (msgCode) {
      case 'GROUP_INV':
      case 'GROUP_JOIN_REQ':
        handleNotificationPopupModal(msgCode, correspond_id, notification_id);
        break;

      case 'GROUP_JOIN_REQ_RE':
        redirectGroupPage();
        updateNotificationResponse(notification_id);
        break;
      
      case 'GROUP_INV_RE':
        redirectGroupPage();
        updateNotificationResponse(notification_id);
        break;
      
      case 'GROUP_REMOVE':
        redirectGroupPage();
        updateNotificationResponse(notification_id);
        break;
      
      case 'GROUP_LEADER_TRANS':
        redirectGroupPage();
        updateNotificationResponse(notification_id);
        break;
      
      case 'POST_COMMENT':
        setCorrespondId(correspond_id);
        setMessageCode(msgCode);
        setShowPostNotiModel(true);
        updateNotificationResponse(notification_id);
        break;

      case 'POST_COMMENT_TAG':
        setCorrespondId(correspond_id);
        setMessageCode(msgCode);
        setShowPostNotiModel(true);
        updateNotificationResponse(notification_id);
        break;

      case 'POST_LIKE':
        setCorrespondId(correspond_id);
        setMessageCode(msgCode);
        setShowPostNotiModel(true);
        updateNotificationResponse(notification_id);
        break;
      
      case 'POST_COMMENT_MENTION':
        setCorrespondId(correspond_id);
        setMessageCode(msgCode);
        setShowPostNotiModel(true);
        updateNotificationResponse(notification_id);
        break;
      
      case 'GROUP_CHAT_TAG':
        const gctData = await checkIsInGroup();
        if (gctData.inGroup) {
          const targetPath = `/home/group/${gctData.groupId}`;
          updateNotificationResponse(notification_id);
          const url = targetPath + "?openchat=true&correspondId="+correspond_id;
          window.location.href = url;   
        }
      break;

      case 'GROUP_CHAT_ALL':
        const gcData = await checkIsInGroup();
        if (gcData.inGroup) {
          const targetPath = `/home/group/${gcData.groupId}`;
          setCorrespondId(correspond_id);
          updateNotificationResponse(notification_id);
          const url = targetPath + "?openchat=true&correspondId="+correspond_id;
          window.location.href = url;
        }
      break;
      
      case 'ISSUES':
        updateNotificationResponse(notification_id);
        navigate(Cookies.get("is_admin") === '1' ? '/home/admin/issue' : '/home/user/issue', { state: { pop: 'true' }});
        break;
    }
  }

  const CloseNotificationPopupModal = () => {
    setShowGroupModal(false);
  }

  const closeOtherDropDown = (currentState, setThisDropDown) => {
    setShowDropdownNotification(false);
    setShowDropdownTicket(false);
    setshowDropDownGroup(false);
  
    // Open the specified dropdown
    setThisDropDown(!currentState);
  }

  const closePostNotificationModel = () => {
    setShowPostNotiModel(false);
  }

  const handleLogOut = () => {
    updateUserActiveStatus();
    const cookieNames = Cookies.get();
    for (const cookie in cookieNames) {
      Cookies.remove(cookie);
    }
    localStorage.clear();
    window.location.href = '/login';
  }

  const handleClickTicket = (id) => {
    if (id !== null) {
      updateTicketStatus(id);
    }
    redirectTicketPage();
  }
  
  // ---------------------------------------------------
  // API Calls
  const updateTicketStatus = async (id) => {
    try {
      const contentType = "application/json";
      const endPoint = "User/updateTicket";
      const bodyContent = {
        userId : Cookies.get('userId'),
        utmId : id
      }

      APICall("POST", endPoint, bodyContent, contentType).then(
        response => {
          if (!response.success && response.message === 'SessionExpired') {
            localStorage.clear();
            window.location.href = '/login?sessionExpired=true';
          }

          if (response.success) {
            const data = response.data;    
            setNotifications(data);
          }
          else {
            Swal.fire({
              icon: "error",
              title: "Get notifications failed!",
              text: "Something went wrong, please try again later",
            });
          }
        }
      )    
    } catch (error) {
      console.error('An error occurred while fetching notifications', error);
    } 
  }

  const fetchIssues = async () => {
    try {
      const contentType = "application/json";
      const endPoint = "Admin/GetAllIssues";
      const bodyContent = {
        adminId : Cookies.get("userId"),
        isAdmin : Cookies.get("is_admin")
      }
      
      APICall("POST", endPoint, bodyContent, contentType).then(
        response => {
          if (!response.success && response.message === 'SessionExpired') {
            localStorage.clear();
            window.location.href = '/login?sessionExpired=true';
          }

          if (response.success) {
            const data = response.data;
            setCheckIssue(data);
          }
          else {
            Swal.fire({
              icon: "error",
              title: "Get Issues Failed!",
              text: "Something went wrong, please try again later",
            });
          }
        }
      )    
    } catch (error) {
      console.error('An error occurred while fetching posts', error);
    }      
  };

  const retrieveNotifications = async () => {
    try {
      const contentType = "application/json";
      const endPoint = "User/getNotifications";
      const bodyContent = {
        userId : Cookies.get('userId')
      }

      APICall("POST", endPoint, bodyContent, contentType).then(
        response => {
          if (!response.success && response.message === 'SessionExpired') {
            localStorage.clear();
            window.location.href = '/login?sessionExpired=true';
          }

          if (response.success) {
            const data = response.data;
            if (notifications.length <= 0) {
              setNotifications(data);
            } 
            else {
              setNotifications(prevNotifications => ({
                ...prevNotifications,
                allSeen: data.allSeen,
                messages: [...data.messages]
              }));              
            }
          }
          else {
            console.error('Failed to get notifications', response.message);
            Swal.fire({
              icon: "error",
              title: "Get notifications failed!",
              text: "Something went wrong, please try again later",
            });
          }
        }
      )    
    } catch (error) {
      console.error('An error occurred while fetching notifications', error);
    }      
  };

  const fetchTicket = async () => {
    try {
      const contentType = "application/json";
      const endPoint = "Ticket/getTickets";
      const bodyContent = {
        userId : Cookies.get('userId'),
        role: Cookies.get("is_admin") === '1' ? 'admin' : 'member'
      }      

      APICall("POST", endPoint, bodyContent, contentType).then(
        response => {
          if (!response.success && response.message === 'SessionExpired') {
            localStorage.clear();
            window.location.href = '/login?sessionExpired=true';
          }

          if (response.success) {
            const data = response.data;
            setTickets(data);
          }
          else {
            console.error('Failed to get tickets', response.message);
            Swal.fire({
              icon: "error",
              title: "Get tickets failed!",
              text: "Something went wrong, please try again later",
            });
          }
        }
      )         

    } catch (error) {
      console.error('An error occurred while fetching tickets', error);
    }
  }

  const checkIsInGroup = async () => {
    try {
        const contentType = "application/json";
        const endPoint = "User/isInGroup";
        const bodyContent = {
            userId : Cookies.get('userId'),
        }
        
        return APICall("POST", endPoint, bodyContent, contentType).then(
            response => {
              if (!response.success && response.message === 'SessionExpired') {
                localStorage.clear();
                window.location.href = '/login?sessionExpired=true';
              }

              if (response.success) {
                const data = response.data;
                return data;
              }
              else {
                console.error('Failed to join group', response.message);
                Swal.fire({
                  icon: "error",
                  title: "Join group failed!",
                  text: "Something went wrong, please try again later",
                });
              }
            }
          )
    } catch (error) {
        console.log("An error occured while validating group");
    }
  }

  const updateNotificationResponse = async (notification_id) => {
    try {
      const contentType = "application/json";
      const endPoint = "Notification/updateNotificationResponse";
      const bodyContent = {
        notification_id: notification_id
      }
      
      return APICall("POST", endPoint, bodyContent, contentType).then(
          response => {
            if (!response.success && response.message === 'SessionExpired') {
              localStorage.clear();
              window.location.href = '/login?sessionExpired=true';
            }

            if (response.success) {
              const data = response.data;
              if (data) {
                retrieveNotifications();
              }
            }
            else {
              console.error('Failed to update notification', response.message);
              Swal.fire({
                icon: "error",
                title: "Update notification failed!",
                text: "Something went wrong, please try again later",
              });
            }
          }
        )
    } catch (error) {
        console.log("An error occured while updating notification");
    }    
  }

  const updateUserActiveStatus = async () => {
    try {
      const contentType = "application/json";
      const endPoint = "User/setIsActive";
      const bodyContent = {
        userId : Cookies.get("userId"),
      }
      
      return APICall("POST", endPoint, bodyContent, contentType).then(
          response => {
            if (!response.success && response.message === 'SessionExpired') {
              localStorage.clear();
              window.location.href = '/login?sessionExpired=true';
            }

            if (response.success) {
              const data = response.data;
            }
            else {
              console.error('Failed to loggout', response.message);
              Swal.fire({
                icon: "error",
                title: "Logging Out failed!",
                text: "Please wait for a while and try again later",
              });
            }
          }
        )
    } catch (error) {
        console.log("An error occured while logging out");
    }        
  }

  // ----------------------------------------------------
  // Effect hooks
  // Web Socket hooks
  useEffect(() => {
    const socket = io("https://www.jungp.online", {
      query: {
        path: '/socket.io',
        user_id: Cookies.get("userId")
      }
    });

    // Listen for the 'notifications' event
    socket.on('notifications', (data) => {
      if (data.notify) {
        retrieveNotifications();
      }      
    });

    // Listen for the 'notifications' event
    socket.on('gchat_notifications', (data) => {
      if (data.notify && parseInt(Cookies.get("groupId")) == parseInt(data.groupId)) {
        retrieveNotifications();
      }      
    });    

    // Listen for the 'ticket' event (for admin only)
    if (Cookies.get("is_admin") === '1') {
      socket.on('tickets', (data) => {
        if (data.notify) {
          fetchTicket();
        }      
      });       
    }

    // Listen for the 'ticket update' event (for user only)
    if (Cookies.get("is_admin") === '0') {
      socket.on('tickets_update', (data) => {
        if (data.notify) {
          fetchTicket();
        }
      });       
    }

    // Listen for the 'join group req' event (for group leader only)
    socket.on('group_join_request', (data) => {
      if (data.notify) {
        retrieveNotifications();
      }
    });

    // Listen for the 'transfer_leader' event
    socket.on('transfer_leader', (data) => {
      if (data.notify) {
        retrieveNotifications();
      }
    });

    // Listen for the 'update_notification' event
    socket.on('update_notification', (data) => {
      if (data.notify) {
        retrieveNotifications();
      }
    });

    // Cleanup the socket connection when the component unmounts
    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    retrieveNotifications().then(() => {
      fetchTicket();
      if (Cookies.get("is_admin") === '1') {
        fetchIssues();
      }
    });
  }, [])

  // ----------------------------------------------------
  // Styling
  const cardList = {
    height: "4rem",
    borderTop: '1px solid #ccc',
    borderLeft: 'None',
    borderRight: 'None', 
    borderBottom: 'None', 
    borderTopLeftRadius: '0rem', 
    borderTopRightRadius: '0rem',
    cursor: 'pointer',
    marginBottom: '5px'
  };


  return (
    <>
    <header>
      <div style={{marginBottom: '-1%'}}>
        <nav className="navbar navbar-expand-lg fixed-top" style={{background: 'white', height: '43px'}}>
          <div className="container" >

            {/* Home buttom */}
            <HoverableIcon hasUnseen={false} style={{fontSize: '1.3rem'}}
                           onClick={() => (window.location.href = "/home")}>
              <IoHomeOutline style={{color: 'gray', marginTop: '-1px', marginRight: '20px'}}/>
            </HoverableIcon>

            {/* Notification part */}
            <HoverableIcon onClick={() => closeOtherDropDown(showDropdownNotification, setShowDropdownNotification)} 
                           hasUnseen={notifications ? !notifications.allSeen : false}>
              <CiBellOn />
              {/* DropDown for notifications */}
              <Dropdown className="form-group mb-2 btn-dropdown justify-content-between align-items-center"   
                        style={{
                          position: 'absolute',
                          top: '50px', // Adjust the top distance as needed
                          marginLeft: '-240%',
                          zIndex: '1000', // Adjust the z-index if necessary
                          border: '0px',
                          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      show={showDropdownNotification}> 

                      <Dropdown.Menu style={{marginTop: '10px', minWidth: '20rem', borderTopLeftRadius: '1rem', borderTopRightRadius: '1rem', borderBottomLeftRadius: '.7rem', borderBottomRightRadius: '.7rem'}}> 
                      <div className="col-lg-4" style={{width: "100%" }}>
                        <div className="card border-bottom-0 d-flex justify-content-center" 
                            style={{ height: '40px', border: 'None', borderTopLeftRadius: '1rem', borderTopRightRadius: '1rem', 
                                      borderBottomLeftRadius: '0rem', borderBottomRightRadius: '0rem'}}>
                          <div className="card-body" style={{cursor: 'default'}}>
                            <div className="d-flex align-items-center">
                              <h5 className="card-text text-black-50 fw-bold mt-2" style={{fontSize: '.7rem'}}>NOTIFICATIONS</h5>
                              <CiBellOn className="fw-bold" style={{marginLeft: 'auto', fontWeight: 1000, fontSize: '1.4rem', color: 'gray'}}/>
                            </div>
                          </div>
                        </div>
                        <div className="card mb-2" style={{border: 'None'}}>
                          <ul className="list-group list-group-flush" 
                              style={{ borderLeft: 'None', borderRight: 'None', borderBottom: 'None', borderTopLeftRadius: '0rem', borderTopRightRadius: '0rem'}}>
                            {
                              notifications && notifications.messages.length > 0 ? 
                              notifications.messages.slice(0,6).map((notification, index) => {
                                return (
                                  <li className="list-group-item d-flex align-items-center" style={{...cardList, cursor: notification.seen ? 'default' : 'pointer'}} 
                                  onClick={notification.seen ? null : () => handleNotificationClick(notification.MESSAGE_CODE, notification.correspond_id, notification.notification_id, notification.seen)}
                                  key={notification.MESSAGE_CODE + notification.correspondId + index}>                                    
                                  {
                                      notification.sender_profilePic !== null && notification.sender_profilePic.length === 2 ? (
                                        <>
                                        <img 
                                          src={notification.sender_profilePic[0] === null ?
                                            "/anon.jpg" :
                                            `data:image/jpeg;base64,${notification.sender_profilePic[0]}`
                                          } 
                                          className="rounded-circle" alt="coder" 
                                          style={{ width: '25px', height: '25px', marginLeft: '-3px'}} 
                                        />
                                        <img 
                                          src={notification.sender_profilePic[1] === null ?
                                            "/anon.jpg" :
                                            `data:image/jpeg;base64,${notification.sender_profilePic[1]}`
                                          } 
                                          className="rounded-circle" alt="coder" 
                                          style={{ width: '25px', height: '25px', marginLeft: '-15px', marginTop: '10px' }} 
                                        />                                        
                                        </>
                                      ) : (
                                        <>
                                        <img 
                                        src={notification.sender_profilePic[0] === null ?
                                              "/anon.jpg" :
                                              `data:image/jpeg;base64,${notification.sender_profilePic}`
                                        } 
                                        className="rounded-circle" alt="coder" 
                                        style={{ width: '35px', height: '35px', marginLeft: '-3px' }} 
                                      />
                                      </>
                                      )
                                    }
                                    <div className="flex-column mr-2 mt-2" style={{marginLeft: '3%', width: '70%'}}>
                                      <span className="fw-semibold" style={{ marginBottom: "1%", fontSize: '.8rem', display : 'block', lineHeight: '1.1' }}> {notification.message} </span>
                                      <span className="text-black-50" style={{ fontSize: '.68rem', fontWeight: 600, display: 'block' }}> {notification.time}</span>
                                    </div>
                                    <div>
                                      {
                                        notification.MESSAGE_CODE.includes("GROUP") ? (
                                          <GrGroup style={{fontSize: '1.3rem', color: 'gray'}}/>
                                        ) : notification.MESSAGE_CODE === "POST_COMMENT" ? (
                                          <FaSignsPost style={{fontSize: '1.3rem', color: 'gray'}}/>
                                        ) : notification.MESSAGE_CODE === "POST_COMMENT_TAG" ? (
                                          <FaRegCommentDots style={{fontSize: '1.3rem', color: 'gray'}}/>
                                        )  : notification.MESSAGE_CODE === "POST_COMMENT_MENTION" ? (
                                          <FaRegCommentDots style={{fontSize: '1.3rem', color: 'gray'}}/>
                                        ) : notification.MESSAGE_CODE === "POST_SHARE" ? (
                                          <FaShareSquare style={{fontSize: '1.3rem', color: 'gray'}}/>
                                        ) : notification.MESSAGE_CODE === "POST_LIKE" ? (
                                          <AiFillLike style={{fontSize: '1.3rem', color: 'gray'}}/>
                                        ) : null
                                      }
                                    </div>
                                    {(!notification.seen) && <div style={{ position: 'relative', top: '-15px', left: '5px', width: '8px', height: '8px', backgroundColor: 'green', borderRadius: '50%' }}></div>}
                                  </li>                     
                                )}

                                )
                              : notifications && notifications.messages.length <= 0 && (
                                <li className="d-flex align-items-center justify-content-center" style={{height: '10rem', borderTop: '1px solid #ccc'}}>
                                  <div className="d-flex flex-column align-items-center">
                                    <span className="text-black-50 fw-semibold" style={{display: 'block'}}>No Notifications Yet!</span>
                                    <FontAwesomeIcon icon={faBoxOpen} style={{fontSize: '3rem', display: 'block'}}/>
                                  </div>
                                </li>
                              )
                            }
                            {
                              notifications && notifications.messages.length > 6 ? (
                                <div className="d-flex align-items-center justify-content-center" style={{borderTop: '1px solid #D3D3D3', height: '25px', minHeight: '1px'}}>
                                    <h5 className="card-text text-black-50 fw-bold mt-2" 
                                        style={{fontSize: '.7rem', marginBottom: '1px'}}
                                        onClick={() => (window.location.href = "/home/notification")}
                                        >Click to view more</h5>
                                </div>                               
                              ) : null
                            } 
                            {
                              showGroupModal && (
                                <NotificationModel showModal={showGroupModal} code={msgCode} handleClose={CloseNotificationPopupModal}/>
                              )
                            }
                          </ul>
                        </div>
                      </div>   
                      </Dropdown.Menu>
                      {/* Post Notification Model */}
                      {
                        showPostNotiModel === true ? (
                            <div>
                            <PostNotificationModel showModal={showPostNotiModel} handleClose={closePostNotificationModel} 
                                                   id={correspondId} msgCode={messageCode}/>
                            </div>
                          ) : null
                      }
              </Dropdown>          
            </HoverableIcon>
            {/* Notification part Ends Here*/}

            {/* Ticket part */}
            <HoverableIcon onClick={() => closeOtherDropDown(showDropdownTicket, setShowDropdownTicket)} 
                           hasUnseen={Cookies.get("is_admin") === '0' && tickets ? ( tickets.some(ticket => ticket.status === 0 ) ) : 
                                      tickets && tickets.length > 0 ? true : false         
                           }>
              <HiOutlineTicket style={{color: 'gray', marginLeft: '22px'}}/>
              {/* DropDown for tickets */}
              <Dropdown className="form-group mb-2 btn-dropdown justify-content-between align-items-center"   
                        style={{
                          position: 'absolute',
                          top: '50px', // Adjust the top distance as needed
                          marginLeft: '-130%',
                          zIndex: '1000', // Adjust the z-index if necessary
                          border: '0px',
                          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                        }}      
                      show={showDropdownTicket}
                      onToggle={handleTicket}>

                      <Dropdown.Menu style={{marginTop: '10px', minWidth: '20rem', borderTopLeftRadius: '1rem', borderTopRightRadius: '1rem', borderBottomLeftRadius: '.7rem', borderBottomRightRadius: '.7rem'}}> 
                      <div className="col-lg-4" style={{width: "100%" }}>
                        <div className="card border-bottom-0 d-flex justify-content-center" 
                            style={{ height: '40px', border: 'None', borderTopLeftRadius: '1rem', borderTopRightRadius: '1rem', 
                                      borderBottomLeftRadius: '0rem', borderBottomRightRadius: '0rem'}}>
                          <div className="card-body">
                            <div className="d-flex align-items-center" style={{cursor: 'default'}}>
                              <h5 className="card-text text-black-50 fw-bold mt-2" style={{fontSize: '.7rem'}}>TICKETS</h5>
                              <HiOutlineTicket className="fw-bold" style={{marginLeft: 'auto', fontWeight: 1000, fontSize: '1.4rem', color: 'gray', cursor: 'pointer'}}
                                               onClick={() => Cookies.get("is_admin") === '1' ? window.location.href = "/home/admin/ticket" : window.location.href = "/home/user/ticket"}/>
                            </div>
                          </div>
                        </div>
                        <div className="card mb-2" style={{border: 'None'}}>
                          <ul className="list-group list-group-flush" 
                              style={{ borderLeft: 'None', borderRight: 'None', borderBottom: 'None', borderTopLeftRadius: '0rem', borderTopRightRadius: '0rem'}}>
                              {
                                tickets && tickets.length > 0 ? (
                                  tickets.slice(0, 6).map((ticket, index) => (
                                    <li class="list-group-item d-flex align-items-center" style={cardList} 
                                        onClick = {ticket.status !== null && ticket.status === 0 ? () => handleClickTicket(ticket.utmId) : redirectTicketPage}> 
                                      <img 
                                        src={ticket.reqUserProfilePic === null ?
                                              "/anon.jpg" :
                                              `data:image/jpeg;base64,${ticket.reqUserProfilePic}`
                                        } 
                                        className="rounded-circle" alt="coder" 
                                        style={{ width: '35px', height: '35px', marginLeft: '-3px' }} 
                                      />
                                      <div className="flex-column mr-2 mt-2" style={{marginLeft: '5%', width: '70%'}}>
                                        <span className="fw-semibold" style={{ marginBottom: "1%", fontSize: '.8rem', display : 'block', lineHeight: '1.1' }}> {ticket.message} </span>
                                        <span className="text-black-50" style={{ fontSize: '.68rem', fontWeight: 600, display: 'block' }}> {ticket.time}</span>
                                      </div>
                                      <div>
                                      {
                                        ticket.REQ_TYPE === 'Email' ? <HiOutlineMail style={{fontSize: '1.3rem', color: 'gray'}}/> : (
                                          ticket.REQ_TYPE === 'Phone' ? <FiPhone style={{fontSize: '1.3rem', color: 'gray'}}/> : (
                                            ticket.REQ_TYPE === 'Country' ? <GiWorld style={{fontSize: '1.3rem', color: 'gray'}}/> : (
                                              ticket.REQ_TYPE === 'Name' ? <MdOutlineDriveFileRenameOutline style={{fontSize: '1.3rem', color: 'gray'}}/> : (
                                                ticket.REQ_TYPE === null ? <IoTicketSharp style={{fontSize: '1.3rem', color: 'gray'}}/> : null
                                              )
                                            )
                                          )
                                        )
                                      }
                                      </div>
                                      {
                                        ticket.status === 0 ? (
                                          <div style={{ position: 'relative', top: '-15px', left: '5px', width: '8px', height: '8px', backgroundColor: 'green', borderRadius: '50%' }}></div>
                                        ) : null
                                      }
                                    </li>    
                                  ))
                                ) : tickets && (
                                  <li className="d-flex align-items-center justify-content-center" style={{height: '10rem', borderTop: '1px solid #ccc'}}>
                                    <div className="d-flex flex-column align-items-center">
                                      <span className="text-black-50 fw-semibold" style={{display: 'block'}}>No Opened Tickets!</span>
                                      <FontAwesomeIcon icon={faBoxOpen} style={{fontSize: '3rem', display: 'block'}}/>
                                    </div>
                                  </li>
                                )
                              }
                          </ul>
                        </div>
                      </div>  
                      {
                        tickets && tickets.length > 6 ? (
                          <div className="card-body d-flex align-items-center justify-content-center" style={{borderTop: '1px solid #D3D3D3'}} onClick = {redirectTicketPage}>
                              <h5 className="card-text text-black-50 fw-bold mt-2" style={{fontSize: '.7rem', marginBottom: '10px'}}>More On Ticket Page</h5>
                          </div>                               
                        ) : null
                      } 
                      </Dropdown.Menu>
              </Dropdown>                 
            </HoverableIcon>
            {/* Ticket part Ends Here*/}

            {/* Group part */}
            <HoverableIcon style={{marginLeft: '20px', fontSize: '1.5rem', marginTop: '-2px', color: 'gray', cursor:'pointer'}}
                                onClick={() => closeOtherDropDown(showDropDownGroup, setshowDropDownGroup)}>
            <HiOutlineUserGroup />  
            <Dropdown className="form-group mb-2 btn-dropdown justify-content-between align-items-center"   
                        style={{
                          position: 'absolute',
                          top: '50px', // Adjust the top distance as needed
                          marginLeft: '-410%',
                          zIndex: '1000', // Adjust the z-index if necessary
                          border: '0px',
                          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                        }}      
                      show={showDropDownGroup}>

                      <Dropdown.Menu style={{marginTop: '10px', minWidth: '15rem', borderTopLeftRadius: '1rem', borderTopRightRadius: '1rem', borderBottomLeftRadius: '.7rem', borderBottomRightRadius: '.7rem'}}> 
                      <div className="col-lg-4" style={{width: "100%" }}>
                        <div className="card border-bottom-0 d-flex justify-content-center" 
                            style={{ height: '40px', border: 'None', borderTopLeftRadius: '1rem', borderTopRightRadius: '1rem', 
                                      borderBottomLeftRadius: '0rem', borderBottomRightRadius: '0rem'}}>
                          <div className="card-body">
                            <div className="d-flex align-items-center" style={{cursor: 'default'}}>
                              <h5 className="card-text text-black-50 fw-bold mt-2" style={{fontSize: '.7rem'}}>GROUPS</h5>
                              <HiOutlineUserGroup className="fw-bold" style={{marginLeft: 'auto', fontWeight: 1000, fontSize: '1.4rem', color: 'gray'}}/>
                            </div>
                          </div>
                        </div>
                        <div className="card mb-2" style={{border: 'None'}}>
                          <ul className="list-group list-group-flush" 
                              style={{ borderLeft: 'None', borderRight: 'None', borderBottom: 'None', borderTop: '1px solid #D3D3D3', 
                                       borderTopLeftRadius: '0rem', borderTopRightRadius: '0rem'}}>
                              <li className='d-flex' style={{height: '26px', paddingLeft: '15px'}}>
                                <h5 className="card-text text-black-50 fw-bold mt-2" style={{fontSize: '.7rem'}} 
                                    onClick={() => window.location.href = "/home/group/all"}>ALL GROUPS PAGE</h5>
                              </li>
                              <li className='d-flex align-items-center' style={{borderTop: '1px solid #D3D3D3', paddingLeft: '15px'}}>
                                <h5 className="card-text text-black-50 fw-bold mt-2" style={{fontSize: '.7rem'}}
                                    onClick={() => redirectGroupPage()}>MY GROUP</h5>
                              </li>                              
                          </ul>
                        </div>
                      </div>  
                      </Dropdown.Menu>
            </Dropdown>                               
            </HoverableIcon>
            {/* Group part Ends here */}

            {/* Client part */}
            {
              Cookies.get("is_admin") === '1' ? (
                <HoverableIcon hasUnseen={false} style={{fontSize: '1.3rem', marginLeft: '23px'}}
                               onClick={() => (window.location.href = "/home/client")}>
                  <LuUserCog  style={{color: 'gray', marginTop: '-1px', marginRight: '0px'}}/>
                </HoverableIcon>
              ) : null
            }

            {/* Issue part */}
              <HoverableIcon hasUnseen={checkIssue && checkIssue.filter(issue => issue.status === 0).length > 0 ? true : false} 
                              style={{fontSize: '1.55rem', marginLeft: '19px', marginTop: '1px', fontWeight: 800}}
                              onClick={() => (window.location.href = Cookies.get("is_admin") === '0' ? "/home/user/issue" : "/home/admin/issue")}>
                <VscIssues  style={{color: 'gray', marginTop: '-1px', marginRight: '0px'}}/>
              </HoverableIcon>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav ms-auto align-items-center">
              </ul>
            </div>
            <div className='search-bar-container'>
              <SearchBar setSearchUser={setSearchUser} />
              {searchUser && searchUser.length > 0 && <SearchResultsList results={searchUser} />}
            </div>
            <div style={{ position: 'relative', display: 'inline-block' }}>
              <img 
                style={{ width: '35px', height: '35px', borderRadius: '20px', cursor:'pointer' }}
                src={imageData === "null" ? "/anon.jpg" : `data:image/jpeg;base64,${imageData}`}
                onClick={() => redirectViewProfile(Cookies.get("userId"))}
              />
                <div
                  style={{
                    position: 'absolute',
                    top: '0px',
                    right: '2px',
                    width: '8px',
                    height: '8px',
                    backgroundColor: '#5072A7',
                    borderRadius: '50%',
                  }}
                />
            </div>
            <MdOutlineLogout style={{fontSize: '1.3rem', marginLeft: '10px', color:'#A0A0A0', cursor:'pointer'}} onClick={() => handleLogOut()}/>
          </div>
        </nav>
      </div>
    </header>
    </>
  );
}

export default Header;
