import React, { useState, useEffect, useRef, useCallback } from 'react'
import { BsReply } from "react-icons/bs";
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import io from 'socket.io-client';

import APICall from '../../api';
import "../../assets/css/PopChat.css";

let call = false;
let fiiV = false;
let send = false;

const PopChat = ({gid, handleClose, chatShow}) => {
  // ----------------------------------------------------
  // State hooks
  const [messages, setMessage] = useState([]);
  const [text, setText] = useState('');
  const [replyText, setReplyText] = useState(null);
  const [replyId, setReplyId] = useState(null);
  const [tagUserList, setTagUserList] = useState([]);
  const [tagUserIds, setTagUserIds] = useState([]);
  const [tagIndex, setTagIndex] = useState(-1);
  const [lastSeen, setLastSeen] = useState(null);
  const [showAmount, setShowAmount] = useState(10);
  const [focus, setFocus] = useState(null);
  const [tagUserName, setTagUserName] = useState([]);
  
  const chatRef = useRef(null);
  const observer = useRef();
  const lastItemRef = useRef(null);
  const firstItemRef = useRef(null); // Ref for the first rendered item
  
  let timeInterval = '';
  let lastSeenCheck = false;

  // ----------------------------------------------------
  // API Calls
  const fetchChat = async () => {
    try {
      call = false;
      const contentType = "application/json";
      const endPoint = "Group/getChat";
      const bodyContent = {
        groupId: gid,
        showAmount : showAmount
      }

      APICall("POST", endPoint, bodyContent, contentType).then(
        response => {
          if (!response.success && response.message === 'SessionExpired') {
            localStorage.clear();
            window.location.href = '/login?sessionExpired=true';
          }

          if (response.success) {
            const data = response.data;
            call = false;
            setMessage(data);         
          }
          else {
            console.error('Failed to fetch group chat', response.message);
            Swal.fire({
              icon: "error",
              title: "Failed to fetch group chat",
              text: "Something went wrong, please try again later",
            });
          }
        }
      )   
      } catch (error) {
      console.error('An error occurred while fetching group chat', error);
    }
  };

  const sendChat = async () => {
    try {
      const contentType = "application/json";
      const endPoint = "Group/sendChat";
      const bodyContent = {
        groupId: gid,
        userId: Cookies.get("userId"),
        replyId: replyId !== null ? replyId : null,
        text: text,
        tagUserIds: tagUserIds.length > 0 ? tagUserIds : null
      }

      APICall("POST", endPoint, bodyContent, contentType).then(
        response => {
          if (!response.success && response.message === 'SessionExpired') {
            localStorage.clear();
            window.location.href = '/login?sessionExpired=true';
          }

          if (response.success) {
            const data = response.data;
            if (data) {
              storeLastSeen();
              getLastSeen();
              setReplyText(null);
              fetchChat();
            }
                   
          }
          else {
            console.error('Failed to send group chat', response.message);
            Swal.fire({
              icon: "error",
              title: "Failed to send group chat",
              text: "Something went wrong, please try again later",
            });
          }
        }
      )   
      } catch (error) {
      console.error('An error occurred while sending group chat', error);
    }
  };  

  const fetchTagUserData = (value) => {
    try {
      const contentType = "application/json";
      const endPoint = "User/getAllSearchUser";
      const bodyContent = {};
      
      APICall("POST", endPoint, bodyContent, contentType).then(
        (response) => {
          if (!response.success && response.message === 'SessionExpired') {
            localStorage.clear();
            window.location.href = '/login?sessionExpired=true';
          }

          if (response.success) {
            const data = response.data;
            const results = data.filter((user) => {
              var name = user.first_name + user.last_name;
              return (
                user.uid != Cookies.get("userId") && 
                value &&
                user &&
                name &&
                name.toLowerCase().startsWith(value.toLowerCase()) &&
                (!gid || parseInt(user.groupId) === parseInt(gid))
              );
            });
            setTagUserList(results);
          } else {
            console.error('Failed to get all users', response.message);
            Swal.fire({
              icon: "error",
              title: "Get all users failed!",
              text: "Something went wrong, please try again later",
            });
          }
        }
      );
    } catch (error) {
      console.error("An error occurred while getting all users", error);
    }
  };

  const storeLastSeen = () => {

    try {
      const contentType = "application/json";
      const endPoint = "Group/storeLastSeen";
      const bodyContent = {
        userId : Cookies.get("userId")
      };
      
      APICall("POST", endPoint, bodyContent, contentType).then(
        (response) => {
          if (!response.success && response.message === 'SessionExpired') {
            localStorage.clear();
            window.location.href = '/login?sessionExpired=true';
          }

          if (response.success) {
            
          } else {
            console.error('Failed to store last seen', response.message);
            Swal.fire({
              icon: "error",
              title: "Store last seen failed!",
              text: "Something went wrong, please try again later",
            });
          }
        }
      );
    } catch (error) {
      console.error("An error occurred while getting all users", error);
    }    
  }

  const getLastSeen = () => {
    try {
      const contentType = "application/json";
      const endPoint = "Group/getLastSeen";
      const bodyContent = {
        userId : Cookies.get("userId")
      };
      
      APICall("POST", endPoint, bodyContent, contentType).then(
        (response) => {
          if (!response.success && response.message === 'SessionExpired') {
            localStorage.clear();
            window.location.href = '/login?sessionExpired=true';
          }

          if (response.success) {
            const data = response.data;
            setLastSeen(data);
          } else {
            console.error('Failed to get last seen', response.message);
            Swal.fire({
              icon: "error",
              title: "Get last seen failed!",
              text: "Something went wrong, please try again later",
            });
          }
        }
      );
    } catch (error) {
      console.error("An error occurred while getting all users", error);
    }    
  }  

  // ----------------------------------------------------
  // Event functions
  const checkTimeInterval = (curTime) => {
    const prevTime = new Date(timeInterval);
    const time = new Date(curTime);

    const differenceMs = Math.abs(time - prevTime);
    // Convert milliseconds to seconds, minutes, hours, and days
    const seconds = Math.floor(differenceMs / 1000);
    const minutes = Math.floor(seconds / 60);

    timeInterval = curTime;
    return minutes;
  }

  const compareTime = (compTime) => {    
    const messageTime = new Date(compTime);
    const lastSeenTime = new Date(lastSeen);

    if (lastSeenTime > messageTime) {
      return false;
    }

    return true;
  };

  const formatDate = (dateString) => {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  
    // Split the date string into its components
    const [d, day, monthIndex, year, time] = dateString.split(' ');
  
    // Extract hour and minute components from time
    const [hour, minute, second] = time.split(':');
  
    // Convert month index to month abbreviation
    const month = months[parseInt(monthIndex) - 1];
  
    // Extract hour in 12-hour format and determine AM/PM
    let hour12 = parseInt(hour);
    const ampm = hour12 >= 12 ? 'PM' : 'AM';
    hour12 = hour12 % 12 || 12; // Convert hours to 12-hour format
  
    // Construct the formatted date string
    const formattedDate = `${d} ${months.indexOf(monthIndex) + 1}/${day}, ${hour12}:${minute} ${ampm}`;
  
    return formattedDate;
  };

  const toggle = () => {
    handleClose();
    localStorage.setItem('chatFocus', false);

    if (chatShow === true) {
      setTagUserList([]);
      setTagUserName([]);
      setTagUserIds([]);
      setText('');
      storeLastSeen();
    }
  }

  const handleSend = () => {
    if (text.length > 150) {
      Swal.fire({
        icon: "warning",
        title: "Exceeds limit",
        text: "No more than 150 words!",
      });
      return; 
    }
    if (text.length > 0) {
      sendChat();
      setText('');
      setFocus(true);
      setTagUserList([]);
      setTagUserName([]);
      setTagUserIds([]);
      send = true;
      // setSend(true);
    }
  }

  const handleMention = useCallback((e) => {
    
    setText(e.currentTarget.value);
    if (e.currentTarget.value[0] !== '@' || e.currentTarget.value.length === 1) {
      setTagUserList([]);
    }
    
    if(e.currentTarget.value[e.currentTarget.value.length-1] === '@') {
      setTagIndex(e.currentTarget.value.length-1);
    }
    if (tagIndex >= 0 && e.currentTarget.value.length > 1) {
      fetchTagUserData(e.currentTarget.value.slice(tagIndex + 1, e.currentTarget.value.length));
    }

  }, [tagIndex, setText, setTagUserList, setTagIndex, fetchTagUserData]);

  const handleReply = (id, target_name, msg) => {
    if (id === replyId) {
      setReplyId(null);
      setReplyText(null);
      return;
    }

    setReplyId(id);
    if (msg.length > 10) {
      setReplyText("Replying to " + target_name + ": " + msg.slice(0, 9) + "...");
    } else {
      setReplyText("Replying to " + target_name + ": " + msg);
    }
  }

  const inputMention = (first_name, last_name, uid) => {
    const mentionedUser = first_name + last_name
    const newText = text.substring(0, tagIndex) + '@' + mentionedUser + text.substring(text.length) + " "
    setTagUserList([]);
    setTagUserIds(prevTagUserIds => [...prevTagUserIds, uid]);
    setTagUserName(prevTagUserNames => [...prevTagUserNames, first_name + last_name]);
    setText(newText);
  }
  
  const scrollIntoView = (id) => {
    const scvid = gid + ":GROUP_CHAT:" + id;
    const element = document.getElementById(scvid);
    if (element) {
      element.classList.remove('blink-animation');
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      
      setTimeout(() => {
        element.classList.add('blink-animation');
      }, 300);
    }
  }

  // ---------------------------------------------------------------------
  // Effect hooks
  useEffect(() => {
    // const socket = io(config['WEBSOCKET_ADDRESS'] + config['PORT'], {
      const socket = io("https://www.jungp.online", {
      path: '/socket.io',
      query: {
        user_id: Cookies.get("userId")
      }
    });

    // Listen for the 'group chat' event
    socket.on('gchat_notifications', (data) => {
      if (data.notify && parseInt(Cookies.get("groupId")) === parseInt(data.groupId)) {
        fetchChat();
      }      
    });    

    // Listen for the 'last seen' event
    socket.on('last_seen', (data) => {
      if (data.notify) {

        getLastSeen();
      }      
    });    

    // Cleanup the socket connection when the component unmounts
    return () => {
      socket.disconnect();
    };
    }, [showAmount]);

  useEffect(() => {
    fetchChat().then(() => {
      getLastSeen();
    });
  }, []);

  useEffect(() => {
    const handleBeforeUnload = () => {
      if (chatShow === true) {
        storeLastSeen();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [showAmount]);  

  useEffect(() => {
    if (fiiV) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }

    if (send) {
      send = false;
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  }, [messages])

  useEffect(() => {
    if (call) {
      call = false;
      fetchChat();
    }
    call = false;
  }, [showAmount])

  useEffect(() => {
    // Infinite scrolling
    const options = {
      root: null,
      rootMargin: '12px',
      threshold: 0.1
    };

    observer.current = new IntersectionObserver(entries => {
      entries.forEach((entry) => {
        if (!call && entry.isIntersecting) {
          call = true;
          setShowAmount(prevAmount => prevAmount + 7);
        }
      });
    }, options)

    if (lastItemRef.current) {
      observer.current.observe(lastItemRef.current);
    }

    return () => {
      if (lastItemRef.current) {
        observer.current.unobserve(lastItemRef.current);
      }
    };    
  }, [lastItemRef, fetchChat]);

  useEffect(() => {
    // Intersection Observer setup to observe the first rendered item
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        fiiV = true;
      } else {
        fiiV = false;
      }
    }, { root: null, rootMargin: '12px', threshold: 1 });

    // Observe the first rendered item
    if (firstItemRef.current) {
      observer.observe(firstItemRef.current);
    }

    // Cleanup
    return () => {
      if (firstItemRef.current) {
        observer.unobserve(firstItemRef.current);
      }
    };
  });


  useEffect(() => {
    // Scroll to bottom when the chat is opened
    if (chatShow && chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }

    // Scroll to the last seen element
    // const lastSeenElement = document.getElementById("LAST_SEEN_CHAT");
    // if (lastSeenElement) {
    //   lastSeenElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    // }    

  }, [chatShow]);  
  

  return (
    <div id='chatCon'>
    <div className="chat-box" style={{ display: chatShow ? 'block' : 'none' }}>
    <div className="header">Group Chat</div>
    <div className="msg-area d-flex flex-column-reverse" ref={chatRef}>
      { 
        messages !== null && messages.map((message, i) => {
          // Check for last seen place and time interval display
          const min = checkTimeInterval(message.created_at);
          const lastSeenHere = compareTime(message.created_at);
          let lastSeenElement = null;
          
          if (lastSeenCheck !== true && lastSeenHere && focus === null) {
            lastSeenElement = (
              <div className='d-flex align-items-center justify-content-center' id={"LAST_SEEN_CHAT"}>
                <div className='flex-column'>
                  <p className='d-flex align-items-center justify-content-center fw-semibold' style={{marginBottom: '-15px', fontSize: '.8rem'}}>Last Seen</p>
                  <hr className='d-flex align-items-center justify-content-center' style={{width: '300px'}}></hr>
                </div>
              </div>
            )            
            lastSeenCheck = true; 
          }
          
          return (
            <>
            {i === 0 ? (
              <div style={{ marginTop: "-20px", marginBottom: "0px", fontSize: '.1rem' }} ref={firstItemRef}>.</div>
            ) : null
            }

            {parseInt(message.userId) !== parseInt(Cookies.get("userId")) ? (
              <div className="d-flex align-items-center justify-content-end right mb-3" id={message.groupId+":GROUP_CHAT:"+message.chatId} 
                    key={message.groupId+":GROUP_CHAT:"+message.chatId+i}>
                <div className="flex-column" key={message.groupId+":GROUP_CHAT:INNER"+message.chatId+i}>
                  <div className='d-flex justify-content-end' key={message.groupId+":GROUP_CHAT:INNER"+message.chatId+i}> 
                    <p class="right fw-semibold" style={{marginBottom: '-1px', fontSize: '.7rem', marginRight: '4px', color:'black'}}>{message.first_name+message.last_name}</p>
                    <BsReply style={{cursor: 'pointer'}} onClick={(e) => handleReply(message.chatId, message.first_name+message.last_name, message.message)}
                      key={`reply-${message.groupId}:${message.chatId}:${i}`}
                    />
                  </div>
                  <div className='flex-column'>
                    <p className="right" style={{marginBottom: '2px'}}><span className='fw-normal'>{ message.message }</span></p>
                    {
                      message.replyId !== null ? (
                        messages.
                          filter(msgs => parseInt(msgs.chatId) === parseInt(message.replyId))
                          .map((msg, index) => {
                            return (
                              <>
                              <div className='card d-inline-block'
                                style={{backgroundColor: 'white', display: 'inline-block', height: '35px', border: 'None', cursor: 'pointer', paddingRight: '5px'}}
                                onClick={() => scrollIntoView(msg.chatId)}>
                                <p className='text-black-50 fw-normal' style={{ marginTop: '3px', marginLeft: '5px', fontSize: '.75rem'}}>
                                  Reply to 
                                  <span className='fw-semibold text-black' style={{backgroundColor: 'white', padding: 0, marginLeft: '2px'}}>
                                    {parseInt(msg.userId) !== parseInt(message.userId) ? " " + msg.first_name + msg.last_name : " his own"}
                                  </span>: 
                                  {msg.message.length > 10 ? " " + msg.message.slice(0,10) + "..." : " " + msg.message}
                                </p>
                              </div>             
                              </>             
                            )
                          })
                      ) : null
                    }                   
                  </div>
                </div>
                <img
                  src={
                    message.profilePic !== null ?
                    `data:image/jpeg;base64,${message.profilePic}` :
                    "/anon.jpg"                  
                  }
                  className="img-fluid rounded-4"
                  style={{ width: "35px", height: "35px", marginLeft: '3px' }}
                  alt="User"
                />                          
              </div>
            ) : (
              <div className="d-flex align-items-center mb-3" id={message.groupId+":GROUP_CHAT:"+message.chatId} key={message.groupId+":GROUP_CHAT:"+message.chatId+i}>
              <img
                  src={
                    message.profilePic !== null ?
                    `data:image/jpeg;base64,${message.profilePic}` :
                    "/anon.jpg"                  
                  }
                  className="img-fluid rounded-4"
                  style={{ width: "35px", height: "35px", marginRight: '3px' }}
                  alt="User"
                />                                        
                <div className="flex-column">
                  <div className='d-flex'>
                    <p className="left fw-semibold" style={{marginBottom: '-1px', fontSize: '.7rem', marginLeft: '3px', color:'black'}}>{message.first_name+message.last_name}</p>
                    <BsReply style={{marginLeft: '2px', cursor: 'pointer'}} onClick={(e) => handleReply(message.chatId, message.first_name+message.last_name, message.message)}
                      key={`reply-${message.groupId}:${message.chatId}:${i}`}/>
                  </div>
                  <div className='flex-column'>
                    <p className="left" style={{marginBottom: '2px'}}><span className='fw-normal'>{ message.message }</span></p>
                    {
                      message.replyId !== null ? (
                        messages.
                          filter(msgs => parseInt(msgs.chatId) === parseInt(message.replyId))
                          .map((msg, index) => {
                            return (
                              <>
                              <div className='card d-inline-block' 
                                style={{backgroundColor: 'white', display: 'inline-block', height: '35px', border: 'None', cursor: 'pointer', paddingRight: '5px'}}
                                onClick={() => scrollIntoView(msg.chatId)}>
                                <p className='text-black-50 fw-normal' style={{ marginTop: '3px', marginLeft: '5px' ,fontSize: '.75rem'}}>
                                  Reply to 
                                  <span className='fw-semibold text-black'>
                                    {parseInt(msg.userId) !== parseInt(message.userId) ? " " + msg.first_name + msg.last_name : " his own"}
                                  </span>: 
                                  {msg.message.length > 10 ? " " + msg.message.slice(0,10) + "..." : " " + msg.message}
                                </p>
                              </div>             
                              </>             
                            )
                          })
                      ) : null
                    }                  
                  </div>
                </div>
              </div>
            )}
            {i === 0 || min > 5 ? (
              <div className="d-flex align-items-center justify-content-center" style={{marginBottom: '-10px'}}>
                <p className="text-black-50 fw-semibold" style={{fontSize: '.8rem'}}>{formatDate(message.created_at)}</p>
              </div>
            ) : null }
            {lastSeenElement ? lastSeenElement : null}
            {i === messages.length - 1 ? (
              <div style={{ display: showAmount > messages.length ? 'none' : 'block'}}
               ref={lastItemRef}>.</div>
            ) : null
            }

            </>
          )
        })
      }

    </div>
    <div>
      <div className='flex-column'>
      {
        replyText && replyText.length > 0 ? (
          <>
          <div className='card d-flex justify-content-center' 
               style={{backgroundColor: 'white', marginTop: '-28px', width: '200px', marginLeft: '15px', position:'absolute', height: '30px', border: 'None'}}>
            <p className='text-black-50 fw-medium' style={{ marginTop: '3px', marginLeft: '5px' ,fontSize: '.75rem'}}>{replyText}</p>
          </div>
          </>
        ) : null
      }
      
      <div className='d-flex align-items-center'>
        {
          <div className="results-list-chat" style={{ zIndex: '1000' }}>
          {tagUserList && tagUserList.length > 0 ? (
            tagUserList.map((user, index) => (
                <div className="search-result d-flex align-items-center justify-content-start" id={user.uid} 
                      onClick={() => inputMention(user.first_name, user.last_name, user.uid)}>
                  <img 
                  style={{width: '40px', height: '40px', borderRadius: '20px'}}
                    src={user.profilePic === null ?
                      "/anon.jpg" :
                      `data:image/jpeg;base64,${user.profilePic}`}
                  />
                  <p className="text-black-50, fw-semibold" style={{fontSize: '.9rem', marginLeft: '10px', marginTop: '10px'}}>{user.first_name + user.last_name}</p> 
                </div>
            ))
            ): null
          }
        </div>
        }        
        <input type="text" className='form-control rounded-4 mt-1' style={{width: '93%', height: '38px', marginLeft: '10px'}}
              value={text}
              onChange={(e) => handleMention(e)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {                  
                  e.preventDefault();
                  handleSend();
                }
              }}
        />
      </div>
      </div>
    </div>
    </div>
    <div className="pop" style={{marginLeft: '200px', marginBottom: '20px'}}>
      <span>
        <img onClick={toggle} src="/icon.png" alt="" />
      </span>   
    </div>
    </div>
  )
}

export default PopChat;