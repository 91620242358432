import React from 'react';
import LazyLoad from 'react-lazyload';
import GroupPostContent from './Group-post-content';

function GroupPostLazyLoader({ post, groupId }) {
  return (
    <LazyLoad offset={100} once>
      <GroupPostContent post={post} groupId={groupId} />
    </LazyLoad>
  );
}

export default GroupPostLazyLoader;
