import React from "react";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

import "sweetalert2/src/sweetalert2.scss";

const Register = () => {
  const [registrationFailed, setRegistrationFailed] = React.useState(false);

  // Call API for registration
  const handleRegister = (e) => {
    e.preventDefault();

    const email = e.target.elements.Email.value;
    const password = e.target.elements.Password.value;
    const firstName = e.target.elements.FirstName.value;
    const lastName = e.target.elements.LastName.value;
    const phone = e.target.elements.Phone.value;
    const country = e.target.elements.Country.value;

    // API Calling
    const xhr = new XMLHttpRequest();
    xhr.open("POST", "https://www.jungp.online/api/v1/register", true);
    xhr.setRequestHeader("Content-Type", "application/json");

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          // Fire alert for success
          Swal.fire({
            icon: "success",
            title: "Registration successful!",
            text: "You have successfully registered!",
          }).then((result) => {
            if (
              result.isConfirmed ||
              result.dismiss === Swal.DismissReason.timer
            ) {
              window.location.href = "/login";
            }
          });
        } else if (xhr.status === 400) {
            Swal.fire({
              icon: "warning",
              title: "User Exist!",
              text: "User with same email already exist!",
            });
        } else {
          // Fire alert for fail
          Swal.fire({
            icon: "error",
            title: "Registration failed!",
            text: "Something went wrong, please try again later"
          });
        }
      }
    };

    xhr.send(
      JSON.stringify({
        email: email,
        password: password,
        first_name: firstName,
        last_name: lastName,
        phone: phone,
        country: country,
      })
    );
  };

  // Error checking for registration
  const handleSubmit = (e) => {
    e.preventDefault();

    const password = e.target.elements.Password.value;
    const confirmPassword = e.target.elements.ConfirmPassword.value;

    if (password !== confirmPassword) {
      alert("Passwords do not match!");
      e.target.elements.Password.value = "";
      e.target.elements.ConfirmPassword.value = "";
    }

    const validEmailEndings =
        /(@outlook\.com|@gmail\.com|@kj-planning\.co\.jp)$/;
      const email = e.target.elements.Email.value;
      if (!validEmailEndings.test(email)) {
        alert("Invalid Email");
        e.target.elements.Email.value = "";
        return false;
      }
      return true;
    };

  // Call both handle helper
  const handleSubmitRegister = (e) => {
    e.preventDefault();
    const succeed = handleSubmit(e);
    if (succeed) {
      handleRegister(e);
    }
  };

  return (
    <div>
      <head>
        {/* <link rel="stylesheet" href="./css/bootstrap.min.css" /> */}
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.7.2/font/bootstrap-icons.css"
        />
        {/* <link rel="stylesheet" type="text/css" href="./css/style.css" /> */}
      </head>
      <body>
        <div class="container-fluid vh-100 overflow-auto">
          <div class="row vh-100 ">
            <div class="col-lg-6 vh-100" style={{marginRight: '-40px'}}>
              <form onSubmit={handleSubmitRegister}>
                <div class="row d-flex vh-100">
                    <div class="col-md-8 p-4 ikigui m-auto text-center align-items-center">
                      <div class='flex-column'>
                        <p class="text-center fw-normal mb-0 fs-3" style={{ color: 'black' }}>Welcome To Jungle Platform Sign Up! 🖖🏼</p>
                      <div class='flex-column' style={{ fontSize: '.8rem' }}>
                        <p>Please Crate An Account And Start Your Adventure!</p>
                      </div>
                    </div>
                    <div class="input-group mb-4">
                      <span
                        class="input-group-text border-end-0 inbg"
                        id="basic-addon1"
                      >
                        <i class="bi bi-person"></i>
                      </span>
                      <input
                        required
                        type="text"
                        class="form-control ps-2 border-start-0 fs-7 inbg form-control-lg mb-0"
                        placeholder="Enter First Name"
                        name="FirstName"
                      />
                    </div>
                    <div class="input-group mb-4">
                      <span
                        class="input-group-text border-end-0 inbg"
                        id="basic-addon1"
                      >
                        <i class="bi bi-person"></i>
                      </span>
                      <input
                        required
                        type="text"
                        class="form-control ps-2 border-start-0 fs-7 inbg form-control-lg mb-0"
                        placeholder="Enter Last Name"
                        name="LastName"
                      />
                    </div>
                    <div class="input-group mb-4">
                      <span
                        class="input-group-text border-end-0 inbg"
                        id="basic-addon1"
                      >
                        <i class="bi bi-envelope"></i>
                      </span>
                      <input
                        required
                        type="text"
                        class="form-control ps-2 border-start-0 fs-7 inbg form-control-lg mb-0"
                        placeholder="Enter Email Address"
                        name="Email"
                      />
                    </div>
                    <div class="input-group mb-4">
                      <span
                        class="input-group-text border-end-0 inbg"
                        id="basic-addon1"
                      >
                        <i class="bi bi-lock"></i>
                      </span>
                      <input
                        required
                        type="password"
                        class="form-control ps-2 fs-7 border-start-0 form-control-lg inbg mb-0"
                        placeholder="Enter Password"
                        name="Password"
                      />
                    </div>
                    <div class="input-group mb-4">
                      <span
                        class="input-group-text border-end-0 inbg"
                        id="basic-addon1"
                      >
                        <i class="bi bi-lock"></i>
                      </span>
                      <input
                        required
                        type="password"
                        class="form-control ps-2 fs-7 border-start-0 form-control-lg inbg mb-0"
                        placeholder="Enter Same Password Again"
                        name="ConfirmPassword"
                      />
                    </div>
                    <div class="input-group mb-4">
                      <span
                        class="input-group-text border-end-0 inbg"
                        id="basic-addon1"
                      >
                        <i class="bi bi-telephone"></i>
                      </span>
                      <input
                        required
                        type="text"
                        class="form-control ps-2 fs-7 border-start-0 form-control-lg inbg mb-0"
                        placeholder="Enter Phone Number"
                        name="Phone"
                      />
                    </div>
                    <div class="input-group mb-4">
                      <span
                        class="input-group-text border-end-0 inbg"
                        id="basic-addon1"
                      >
                        <i class="bi bi-globe"></i>
                      </span>
                      <input
                        required
                        type="text"
                        class="form-control ps-2 fs-7 border-start-0 form-control-lg inbg mb-0"
                        placeholder="Enter Country"
                        name="Country"
                      />
                    </div>

                    <button class="btn btn-lg fw-bold fs-7 btn-success  w-100">
                      Register
                    </button>
                    {registrationFailed && (
                      <p className="text-failed mt-3">Registration Failed!</p>
                    )}
                  </div>
                </div>
              </form>
            </div>
            <div class="col-lg-6 bg-gray p-5 text-center" style={{marginTop: '110px'}}>
              <h4 class="text-center fw-bolder fs-2">Login</h4>
              <p class="mb-3 fs-7">Already have an account ?</p>
              <Link to="/login">
                <a href="login.html">
                  <button class="btn fw-bold mb-0 btn-outline-success px-4 rounded-pill">
                    Log In
                  </button>
                </a>
              </Link>
              <div class="img-cover p-4" style={{}}>
                <img src="/K-JACK1.png" style={{ height: "42%", width: '42%' }} />
                {/* <img src="/loginbg.svg" alt=""/> */}
              </div>
            </div>
          </div>
        </div>
      </body>
    </div>
  );
};

export default Register;
