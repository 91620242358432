import React, { useState, useEffect, useRef  } from "react";
import Header from "../Header";
import Swal from "sweetalert2";
import { MdOutlineDateRange } from "react-icons/md";
import { FaUsers } from "react-icons/fa";

import $ from "jquery";
import "datatables.net-bs4/css/dataTables.bootstrap4.css";
import "datatables.net";
import "datatables.net-bs4";

import "../../../assets/css/Client.css";
import "sweetalert2/src/sweetalert2.scss";

import APICall from "../../../api";

const Client = () => {
  // ---------------------------------------------------------------------
  // State hooks
  const [userList, setUserList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  // ---------------------------------------------------------------------
  // API Calling
  const retrieveClient = async () => {
    try {
      const contentType = "application/json";
      const endPoint = "Admin/getAllUser";
      const bodyContent = {};

      APICall("POST", endPoint, bodyContent, contentType).then((response) => {
        if (!response.success && response.message === 'SessionExpired') {
          localStorage.clear();
          window.location.href = '/login?sessionExpired=true';
        }

        if (response.success) {
          const data = response.data;
          setUserList(data);
        } else {
          console.error("Failed to get user list", response.message);
          Swal.fire({
            icon: "error",
            title: "Get user list failed!",
            text: "Something went wrong, please try again later"
          });
        }
      });
    } catch (error) {
      console.log("An error occured while retrieving users data", error);
    }
  };

  // -------------------------------------------------------------------
  // Effect hooks
  useEffect(() => {
    retrieveClient();
  }, []);

  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  var currentItems = userList ? userList.slice(indexOfFirstItem, indexOfLastItem) : 0;
  
  
  // -------------------------------------------------------------------
  // Reference states
  const tableRef = useRef();

  useEffect(() => {
    // Initialize DataTable when the component mounts
    if ($.fn.DataTable.isDataTable($(tableRef.current))) {
      $(tableRef.current).DataTable().destroy();
    }
  
    $(tableRef.current).DataTable({
      data: currentItems,
      columns: [
        { data: 'id', title: 'User Id' },
        {
          data: null,
          title: 'Name',
          render: function(data, type, row) {
            // Combine first_name and last_name
            return row.first_name + ' ' + row.last_name;
          },
        },
        { 
          data: 'createdAt', 
          title: 'Registration Date',
          render: function(data, type, row) {
            // Format the date if needed
            if (type === 'display') {
              return new Date(data).toLocaleString();
            }
            return data;
          }
        },
        { data: 'email', title: 'Email' },
        { 
          data: 'groupId', 
          title: 'Group',
          render: function(data, type, row) {
            // Customize the rendering of the group data
            if (type === 'display' && row.groupId !== null) {
              return `ID: ${row.groupId}, ${row.groupName}`;
            } 
            else {
              return "No Group";
            }
          }
        },
        { 
          data: 'is_active', 
          title: 'Status',
          render: function(data, type, row) {
            // Customize the rendering of the status data
            if (type === 'display') {
              return data === 1 ? 'Active' : 'Inactive';
            }
            return data;
          }
        },
        {
          data: 'kizumi_wallet',
          title: 'Kizumi Wallet',
          render: function(data) {
            return data || ''; // Display data or empty string if undefined
          },
        },
        {
          data: 'mt4_account',
          title: 'MT4 Account',
          render: function(data) {
            return data || ''; // Display data or empty string if undefined
          },
        },
      ],
      paging: false,
      info: false
    });
  }, [currentItems]);

  return (
    <>
      <Header />

      <div className="content">
        <div className="container">
          <h2 className="mb-4">
            USER LIST{" "}
            <FaUsers style={{ marginTop: "-6px", fontSize: "1.6rem" }} />
          </h2>

          <div className="mb-n2 d-flex justify-content-end" style={{marginTop: '-20px'}}>
          </div>
          <div className="table-responsive" style={{ marginBottom: "-23px" }}>
            <table className="table table-striped custom-table table-sm" ref={tableRef}>
              <thead>
                <tr>
                  <th scope="col">User Id</th>
                  <th scope="col">Name</th>
                  <th scope="col">Registration Date</th>
                  <th scope="col">Email</th>
                  <th scope="col">Group</th>
                  <th scope="col">Status</th>
                  <th scope="col">Kizumi Wallet</th>
                  <th scope="col">MT4 Account</th>
                </tr>
              </thead>
              <tbody>
              </tbody>
            </table>
          </div>
          <div className="card-footer py-4">
            <nav aria-label="...">
              <ul className="pagination justify-content-end mb-0">
                {userList
                  ? Array.from(
                      { length: Math.ceil(userList.length / itemsPerPage) },
                      (_, i) => (
                        <li
                          key={i}
                          className={`page-item ${
                            currentPage === i + 1 ? "active" : ""
                          }`}
                        >
                          <a
                            className="page-link"
                            href="#"
                            onClick={() => setCurrentPage(i + 1)}
                          >
                            {i + 1}
                          </a>
                        </li>
                      )
                    )
                  : null}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default Client;