import React, {useState, useEffect, useRef} from "react";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import "../../../assets/css/Group.css";
import "sweetalert2/src/sweetalert2.scss";

import $ from "jquery";
import "datatables.net-bs4/css/dataTables.bootstrap4.css";
import "datatables.net";
import "datatables.net-bs4";

import APICall from "../../../api";
import { getImageDataFromLocalStorage } from "../../Login/login";

const cardList = {
  height: "3.7rem",
  cursor: 'pointer'
};

const GroupMember = ({ groupId }) => {
  // ---------------------------------------------------------------------
  // State hooks
  const [groupData, setGroupData] = useState([]);
  const [groupMember, setGroupMember] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);  

  // ---------------------------------------------------------------------
  // API Calling
  const retrieveGroup = async () => {
    try {
        const contentType = "application/json";
        const endPoint = "Group/getGroup";
        const bodyContent = {
          userId : Cookies.get('userId'),
          groupId : groupId
        }
  
        APICall("POST", endPoint, bodyContent, contentType).then(
          response => {
            if (!response.success && response.message === 'SessionExpired') {
              localStorage.clear();
              window.location.href = '/login?sessionExpired=true';
            }

            if (response.success) {
              const data = response.data;
              setGroupData(data);
            }
            else {
              console.error('Failed to get group', response.message);
              Swal.fire({
                icon: "error",
                title: "Get group failed!",
                text: "Something went wrong, please try again later",
              });
            }
          }
        )         
    } catch (error) {
        console.log("An error occured while retrieving groups data", error);
    }
  }

  const retrieveGroupMember = async () => {
    try {
        const contentType = "application/json";
        const endPoint = "Group/getGroupMember";
        const bodyContent = {
          groupId : groupId
        }

        APICall("POST", endPoint, bodyContent, contentType).then(
            response => {
              if (!response.success && response.message === 'SessionExpired') {
                localStorage.clear();
                window.location.href = '/login?sessionExpired=true';
              }

              if (response.success) {
                const data = response.data;
                setGroupMember(data);
              }
              else {
                console.error('Failed to get group members', response.message);
                Swal.fire({
                  icon: "error",
                  title: "Get group members failed!",
                  text: "Something went wrong, please try again later",
                });
              }
            }
          ) 
    } catch (error) {

    }
  }

  // ---------------------------------------------------------------------
  // Effect hooks
  useEffect(() => {
    retrieveGroup().then(() => {
      retrieveGroupMember();
    });
  }, []);


  // ---------------------------------------------------------------------
  // variables  
  const itemsPerPage = 5;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  var currentItems;
  
  if (groupMember) {
    currentItems = groupMember ? groupMember.slice(indexOfFirstItem, indexOfLastItem) : 0;
  }

  const tableRef = useRef();
  useEffect(() => {
    // Initialize DataTable when the component mounts
    if ($.fn.DataTable.isDataTable($(tableRef.current))) {
      $(tableRef.current).DataTable().destroy();
    }
  
    $(tableRef.current).DataTable({
      data: currentItems,
      columns: [
          {
            render: function (data, type, row) {
            return row.first_name + ' ' + row.last_name;
          },
          title: 'NAME', 
          width: '10%' },
        { data: 'email', title: 'EMAIL', width: '15%' },
        { data: 'groupName', title: 'GROUP', width: '15%' },
        { data: 'role', title: 'ROLE', width: '13%' },
        { data: 'dateJoinGroup', title: 'DATE JOINED', width: '15%' },          
      ],
      paging: false,
      info: false,
      scrollY: false,
    });
  }, [currentItems]);  

  // ---------------------------------------------------------------------
  // Local storage/cookies data
  const imageData = getImageDataFromLocalStorage();

  return (
    <>
    <div
      className="container d-flex justify-content-center"
      style={{ paddingTop: "40px" }}
    >
      <div className="col-lg-11 d-flex align-items-center justify-content-center">
        <div className="card shadow" style={{ width: "90%" }}>
          <div className="card-header border-0">
            <h3 className="mb-0">Group Members</h3>
          </div>
          <div className="table-responsive">
            <table className="table align-items-center table-flush" ref={tableRef}>
            </table>
          </div>
          <div className="card-footer py-4">
            <nav aria-label="...">
                <ul className="pagination justify-content-end mb-0">
                {
                groupMember? (
                    Array.from({ length: Math.ceil(groupMember.length / itemsPerPage) }, (_, i) => (
                        <li key={i} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
                        <a
                            className="page-link"
                            href="#"
                            onClick={() => setCurrentPage(i + 1)}
                        >
                            {i + 1}
                        </a>
                        </li>
                    ))                    
                ) : null
                }
                </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </>
  );
};

export default GroupMember;
