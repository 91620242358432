import React, { useState } from "react";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import { Modal, Button } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";

import APICall from "../../../api";
import "sweetalert2/src/sweetalert2.scss";

const UpdateAbout = ({showModal, handleClose, groupId}) => {
    // -------------------------------------------------
    // state hooks
    const [isFocused, setIsFocused] = useState(false);
    const [textareaValue, setTextareaValue] = useState('');

    const textareaStyle = {
      border: isFocused ? '2px solid black' : 'none',
      borderRadius : '5px',
      outline: 'none', // To remove default outline when focused
      height: '150px',
      width: '100%',   // Set width as needed
    };

    // -------------------------------------------------
    // API Call
    const updateAbout = async () => {
        if (!checkWordsLimit(textareaValue)) {
            return;
        }
        
        try {
            const contentType = "application/json";
            const endPoint = "Group/UpdateAbout";
            const bodyContent = {
                words : textareaValue,
                groupId : groupId
            }

            APICall("POST", endPoint, bodyContent, contentType).then(
                response => {
                  if (!response.success && response.message === 'SessionExpired') {
                    localStorage.clear();
                    window.location.href = '/login?sessionExpired=true';
                  }
    
                    if (response.success) {
                    Swal.fire({
                            icon: "success",
                            title: "Updated successfully!",
                            text: "Page will be automatically refreshed!",
                        }).then((result) => {
                            window.location.reload();
                    });
                    }
                    else {
                    console.error('Failed to update groups about', response.message);
                    Swal.fire({
                        icon: "error",
                        title: "Updater failed!",
                        text: "Something went wrong, please try again later",
                    });
                    }
                }
            )    
        } catch (error) {
            console.error('An error occurred while updating groups about', error);
        }   
    }

    // -------------------------------------------------
    // Event functions   
    const handleTextareaChange = (event) => {
        const inputValue = event.target.value;
        setTextareaValue(inputValue);
    };

    const checkWordsLimit = (words) => {
        const length = words.replace(/\s/g, '').length;
        if (length > 100) {
            Swal.fire({
                icon: "warning",
                title: "Words Limit Exceeded",
                text: "Words exceed length limit(100)",
            });
            return false;
        }

        if (length <= 0) {
          Swal.fire({
              icon: "warning",
              title: "Empty Value",
              text: "Please at least type in some words!",
          });
          return false;
      }
        return true;
    }

    return (
        <Modal show={showModal}>      
        <Modal.Dialog className="modal-dialog-centered">       
            <Modal.Body className="modal-body p-4 px-5 d-flex flex-column">
            <Button className="close-btn" variant="none" aria-label="Close" onClick={handleClose} style={{ position: 'absolute', top: '0', marginRight: '-5%' }}>
              <span aria-hidden="true"><AiOutlineClose style={{ fontSize: '1.5rem' }} /></span>
            </Button>
              <div className="main-content text-center">
                <form action="#">
                  <label htmlFor="">Update your group's about</label>
                  <p className="mb-4">Note length is limited to within 100 words</p>

                  <textarea type="text" className="form-request form-control text-center mb-3"
                            onFocus={() => setIsFocused(true)} onBlur={() => setIsFocused(false)} style={textareaStyle}
                            onChange={handleTextareaChange}
                            value={textareaValue}
                            placeholder={`Enter The New About Note`}/>
    
                  <div className="d-flex">
                    <div className="mx-auto d-flex align-items-center justify-content-center">
                      <Button type="button" className="custom-btn btn-primary d-flex align-items-center justify-content-center" 
                              style={{backgroundColor: 'gray', borderColor: 'white'}}
                              onClick={() => updateAbout()}
                            onMouseEnter={(e) => {
                              e.target.style.backgroundColor = 'gray';
                              e.target.style.color = 'black';  // Change font color on hover
                            }}
                            onMouseLeave={(e) => {
                              e.target.style.backgroundColor = 'gray';
                              e.target.style.color = 'white';  // Revert font color when mouse leaves
                            }}>
                        Update
                      </Button>                    
                    </div>
                  </div>
                </form>
    
              </div>
    
            </Modal.Body>
        </Modal.Dialog>
        </Modal>
    );
};

export default UpdateAbout;