import Cookies from 'js-cookie';

async function APICall(method, endpoint, jsonBody, contentType, args) {
    let apiUrl = "https://www.jungp.online/api/v1/" + endpoint;
    
    const token = {
        "access_token" : Cookies.get("accessToken"),
        "refresh_token" : Cookies.get("refreshToken")
    }

    try {
        // Constructing request structure
        let requestOptions = {
            method: method,
            headers: {
                "Content-Type": contentType,
                "Access-Token": `Bearer ${token.access_token}`,
                "Refresh-Token": token.refresh_token
            }
        };

        if (args === undefined || args === null) {
            requestOptions.body = JSON.stringify(jsonBody);
        }
        else {
            apiUrl += args;
        }

        const response = await fetch(apiUrl, requestOptions);

        // Processing response
        if (response.ok) {
            const responseData = await response.json();
            
            // If new token gets returned
            if (response.headers.get("Access-Token") !== null) {
                Cookies.set('accessToken', response.headers.get("Access-Token"));
            }

            // If session ends
            if (response.headers.get("Session") !== null && response.headers.get("Session") === 'True') {
                Cookies.set('session', response.headers.get("Session"))
            } else {
                return { success: false, message: "SessionExpired" }
            }

            return { success: true, data: responseData['data'] };
        } else {
            const errorData = await response.json();
            
            if (response.headers.get("Session") !== null && response.headers.get("Session") === 'False') {
                return { success: false, message: "SessionExpired" }
            }

            return { success: false, message: errorData['message'] };
        }

    } catch (error) {
        console.error("An error occurred while making the API call", error);
        return { success: false, message: "An error occurred while making the API call" };
    }
}

export default APICall;

