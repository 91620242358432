import React, { useState } from "react";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import { Modal, Button } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { FaUnlock } from "react-icons/fa6";
import { RiGitRepositoryPrivateFill } from "react-icons/ri";

import APICall from "../../../api";
import "sweetalert2/src/sweetalert2.scss";

const Visibility = ({showModal, handleClose, visibility, groupId}) => {
    // -------------------------------------------------
    // API Call
    const updateVisibility = async () => {
        try {
            const contentType = "application/json";
            const endPoint = "Group/UpdateVisibility";
            const bodyContent = {
                groupId : groupId,
                visibility: visibility
            }

            APICall("POST", endPoint, bodyContent, contentType).then(
                response => {
                    if (!response.success && response.message === 'SessionExpired') {
                      localStorage.clear();
                      window.location.href = '/login?sessionExpired=true';
                    }
    
                    if (response.success) {
                    Swal.fire({
                            icon: "success",
                            title: "Updated successfully!",
                            text: "Page will be automatically refreshed!",
                        }).then((result) => {
                            window.location.reload();
                    });
                    }
                    else {
                    console.error('Failed to update groups visibility', response.message);
                    Swal.fire({
                        icon: "error",
                        title: "Updater failed!",
                        text: "Something went wrong, please try again later",
                    });
                    }
                }
            )    
        } catch (error) {
            console.error('An error occurred while updating groups visibility', error);
        }   
    }

    return (
        <Modal show={showModal}>      
        <Modal.Dialog className="modal-dialog-centered">       
            <Modal.Body className="modal-body p-4 px-5 d-flex flex-column">
            <Button className="close-btn" variant="none" aria-label="Close" onClick={handleClose} style={{ position: 'absolute', top: '0', marginRight: '-5%' }}>
              <span aria-hidden="true"><AiOutlineClose style={{ fontSize: '1.5rem' }} /></span>
            </Button>
              <div className="main-content text-center">
                <form action="#">
                  <label htmlFor="">Update your group's visibility</label>
                  <p className="mb-4">Your group's current visibility status</p>
                  <div className="d-flex align-items-center justify-content-center">
                    <p className="fw-semibold" style={{color: 'black'}}>{visibility === 1 ? "PRIVATE" : "PUBLIC"}</p>
                    {visibility === 1 ? (
                            <RiGitRepositoryPrivateFill style = {{marginLeft: '5px', marginTop: '-15px', fontSize: '1.3rem', color:'black'}}/>
                        ) : (
                            <FaUnlock style = {{marginLeft: '5px', marginTop: '-15px', fontSize: '1.2rem', color:'black'}}/>
                        )
                    }
                  </div>

                  <p className="fw-semibold" style={{color: 'red'}}>
                    Notes: Changing your vibility will effect the privacy of your groups  
                  </p>
    
                  <div className="d-flex">
                    <div className="mx-auto d-flex align-items-center justify-content-center">
                      <Button type="button" className="custom-btn btn-primary d-flex align-items-center justify-content-center" 
                              style={{backgroundColor: 'red', borderColor: 'white'}}
                              onClick={() => updateVisibility()}
                            onMouseEnter={(e) => {
                              e.target.style.backgroundColor = 'red';
                              e.target.style.color = 'white';  // Change font color on hover
                            }}
                            onMouseLeave={(e) => {
                              e.target.style.backgroundColor = 'red';
                              e.target.style.color = 'white';  // Revert font color when mouse leaves
                            }}>
                        Change
                      </Button>                    
                    </div>
                  </div>
                </form>
    
              </div>
    
            </Modal.Body>
        </Modal.Dialog>
        </Modal>
    );
};

export default Visibility;