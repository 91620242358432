import React, { useState } from "react";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import { Modal, Button } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";

import APICall from "../../../api";
import "sweetalert2/src/sweetalert2.scss";

const QuitGroup = ({showModal, handleClose, groupId}) => {
    // -------------------------------------------------
    // API Call
    const Quit = async () => {
        try {
            const contentType = "application/json";
            const endPoint = "Group/QuitGroup";
            const bodyContent = {
                groupId : groupId,
                userId: Cookies.get("userId")
            }

            APICall("POST", endPoint, bodyContent, contentType).then(
                response => {
                  if (!response.success && response.message === 'SessionExpired') {
                    localStorage.clear();
                    window.location.href = '/login?sessionExpired=true';
                  }
    
                    if (response.success) {
                    Swal.fire({
                            icon: "success",
                            title: "Quit successfully!",
                            text: "You will be redirect to homepage!",
                        }).then((result) => {
                            window.location.href = "/home";
                    });
                    }
                    else {
                    console.error('Failed to quit group', response.message);
                    Swal.fire({
                        icon: "error",
                        title: "Quit failed!",
                        text: "Something went wrong, please try again later",
                    });
                    }
                }
            )    
        } catch (error) {
            console.error('An error occurred while quiting group', error);
        }   
    }

    return (
        <Modal show={showModal}>      
        <Modal.Dialog className="modal-dialog-centered">       
            <Modal.Body className="modal-body p-4 px-5 d-flex flex-column">
            <Button className="close-btn" variant="none" aria-label="Close" onClick={handleClose} style={{ position: 'absolute', top: '0', marginRight: '-5%' }}>
              <span aria-hidden="true"><AiOutlineClose style={{ fontSize: '1.5rem' }} /></span>
            </Button>
              <div className="main-content text-center">
                <form action="#">
                  <label htmlFor="">group quitting</label>
                  <p className="mb-4">Only quit when you've made up your mind!</p>
    
                  <div className="d-flex">
                    <div className="mx-auto d-flex align-items-center justify-content-center">
                      <Button type="button" className="custom-btn btn-primary d-flex align-items-center justify-content-center" 
                              style={{backgroundColor: 'red', borderColor: 'white'}}
                              onClick={() => Quit()}
                            onMouseEnter={(e) => {
                              e.target.style.backgroundColor = 'red';
                              e.target.style.color = 'white';  // Change font color on hover
                            }}
                            onMouseLeave={(e) => {
                              e.target.style.backgroundColor = 'red';
                              e.target.style.color = 'white';  // Revert font color when mouse leaves
                            }}>
                        Quit
                      </Button>                    
                    </div>
                  </div>
                </form>
    
              </div>
    
            </Modal.Body>
        </Modal.Dialog>
        </Modal>
    );
};

export default QuitGroup;