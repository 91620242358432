import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Icon from "@mdi/react";
import { RiLockPasswordFill } from "react-icons/ri";
import { mdiBriefcase, mdiGoogleMaps} from "@mdi/js";
import { AiOutlineClose } from "react-icons/ai";
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';

import "../../../assets/css/Request.css";

import APICall from '../../../api';

const UpdateProfile = ({ showModal, handleClose, settings  }) => {

  // ---------------------------------------------
  // State hooks
  const [localShowModal, setLocalShowModal] = useState(showModal);
  const [newSetting, setNewSetting] = useState('');
  const [oldPassword, setOldPassword] = useState('');


  // ---------------------------------------------
  // Event functions
  const handleSubmit  = async () => {
    if (newSetting === '') {
            Swal.fire({
                icon: "error",
                title: "Empty Value",
                text: "Please make sure type in value",
            });     
            return;       
    } 

    setLocalShowModal(false);
    handleClose(); // Call the handleClose function from props to update the parent component's state
    if (settings === 'Password') {
        const match = await validatePassword(oldPassword);
        
        if (!match) {
            Swal.fire({
                icon: "error",
                title: "Old Password Does'nt Match!",
                text: "Please make sure type in correct old password",
            });     
            return;       
        }
    }
    
    updateProfile();
  }

  const handleNewSettingChange = (e) => {
    setNewSetting(e.target.value);
  };

  const handleOldPassword = (e) => {
    setOldPassword(e.target.value);
  };  

  // ---------------------------------------------
  // Styling
  const iconStyle = {
    fontSize: '4rem',
    fontWeight: 700,
  }


  // --------------------------------------------
  // API Calling
  const validatePassword = async (password) => {
    try {
      const contentType = "application/json";
      const endPoint = "User/checkPassword";
      const bodyContent = {
        userId : Cookies.get('userId'),
        password : password
      }      

      return APICall("POST", endPoint, bodyContent, contentType).then(
        response => {
          if (!response.success && response.message === 'SessionExpired') {
            localStorage.clear();
            window.location.href = '/login?sessionExpired=true';
          }

          if (response.success) {
            const data = response.data;
            if (data) {
                return true;
            } else {
                return false;
            }
          }
          else {
            console.error('Failed to validate password', response.message);
            Swal.fire({
              icon: "error",
              title: "Validate password failed!",
              text: "Something went wrong, please try again later",
            });
          }
        }
      )         

    } catch (error) {
      console.error('An error occurred while validating password', error);
    }
  }

  const updateProfile = async () => {
    const contentType = "application/json";
    const endPoint = "User/updateProfile";
    const bodyContent = {
      userId : Cookies.get('userId'),
      attribute : settings,
      newSetting : newSetting
    }

    APICall("POST", endPoint, bodyContent, contentType).then(
        response => {
          if (!response.success && response.message === 'SessionExpired') {
            localStorage.clear();
            window.location.href = '/login?sessionExpired=true';
          }

          if (response.success) {
            Swal.fire({
                icon: "success",
                title: "SUCCESS",
                text: "Profile Updated Successfully",
              }).then((result) => {
                if (result.isConfirmed || result.dismiss === Swal.DismissReason.timer) {
                  window.location.reload();
                }
              });            
          }
          else {
            console.error('Failed to update profile', response.message);
            Swal.fire({
              icon: "error",
              title: "Update propfile failed!",
              text: "Something went wrong, please try again later",
            });
          }
        }
      )   

  }

  return (
    <>
    <Modal show={localShowModal}>      
      <Modal.Dialog className="modal-dialog-centered">       
          <Modal.Body className="modal-body p-4 px-5 d-flex flex-column">
          <Button className="close-btn" variant="none" aria-label="Close" onClick={handleClose} style={{ position: 'absolute', top: '0', marginRight: '-5%' }}>
            <span aria-hidden="true"><AiOutlineClose style={{ fontSize: '1.5rem' }} /></span>
          </Button>
            <div className="main-content text-center">
              <div className="warp-icon mb-4 d-flex align-items-center justify-content-center">
              {settings === 'Location' ? <Icon path={mdiGoogleMaps} size={3} color="gray"/> : 
                (settings === 'Work' ? <Icon path={mdiBriefcase} size={3} color="gray"/> : 
                  (settings === 'Password' ? <RiLockPasswordFill style={iconStyle}/> : null ))}
              </div>
              <form action="#">
                <label htmlFor="">This form is for changing {settings}</label>
                <p className="mb-4">No APPROVAL required to perform such action as you won't be sending the request to Admin.</p>
                <div className="form-group mb-4">
                    <form>
                        {
                        settings === 'Password' ? (
                        <>
                        <input type="text" className="form-control text-center" placeholder={`Enter Old Password`}
                                value={oldPassword}
                                onChange={handleOldPassword}                      
                        />
                        <br></br>
                        <input type="text" className="form-control text-center" placeholder={`Enter New Password`}
                                value={newSetting}
                                onChange={handleNewSettingChange}              
                        />
                        </>
                        ) : (
                        <>
                        <input type="text" className="form-control text-center" placeholder={`Enter New ${settings}`}
                                value={newSetting}
                                onChange={handleNewSettingChange}
                        />
                        </>
                        )
                        }
                    </form>
                </div>

                <div className="d-flex">
                  <div className="mx-auto">
                    <Button type="button" className="custom-btn btn-primary d-flex justify-content-center" 
                            onClick={handleSubmit} style={{backgroundColor: 'gray', borderColor: 'white'}}
                          onMouseEnter={(e) => {
                            e.target.style.backgroundColor = 'darkgray';
                            e.target.style.color = 'black';  // Change font color on hover
                          }}
                          onMouseLeave={(e) => {
                            e.target.style.backgroundColor = 'gray';
                            e.target.style.color = 'white';  // Revert font color when mouse leaves
                          }}>
                      Confirm
                    </Button>
                  </div>
                </div>
              </form>

            </div>

          </Modal.Body>
      </Modal.Dialog>
    </Modal>
    </>
  );
};

export default UpdateProfile;
