import React from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import Login from './components/Login/login';
import Register from './components/Login/register';
import Dashboard from './components/Dashboard/Dashboard';
import PostPanel from './components/Dashboard/Post/Post-new';
import Profile from './components/Dashboard/User/Profile';
import Client from './components/Dashboard/Admin/Client';
import Group from './components/Dashboard/Group/Group';
import AdminTicket from './components/Dashboard/Admin/AdminTicket';
import UserTicket from './components/Dashboard/User/UserTicket';
import GroupShow from './components/Dashboard/Group/Group-show';
import NotificationPage from './components/Dashboard/NotificationPage';
import UserIssues from './components/Dashboard/User/UserIssues';
import AdminIssues from './components/Dashboard/Admin/AdminIssues';

const AppRouter = () => {
  return (
      <Router>
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/home" element={<DashboardWithHelmet />}>
            <Route path=":tab" element={<Group />} />
          </Route>
          <Route path="/home/createPost" element={<PostPanel />} />
          <Route path="/home/profile" element={<ProfileWithHelmet />}>
            <Route path=":viewUid" element={<Profile />} />
          </Route>
          <Route path="/home/client" element={<ClientWithHelmet />} />
          <Route path="/home/group" element={<GroupWithHelmet />} >
            <Route path=":viewGid" element={<Group />} />
          </Route>
          <Route path="/home/admin/ticket" element={<AdminTicketWithHelmet />}/>
          <Route path="/home/admin/issue" element={<AdminIssuesWithHelmet />}/>
          <Route path="/home/user/ticket" element={<UserWithTicketHelmet />}/>
          <Route path="/home/user/issue" element={<UserIssuesWithHelmet />}/>
          <Route path="/home/group/all" element={<AllGroupWithHelmet />} />
          <Route path="/home/notification" element={<AllNotificationWithHelmet />} />
        </Routes>
        <Helmet>
          <link rel="icon" href="/kjackProfilePic.png" />
        </Helmet>        
      </Router>
  );
};

const AdminIssuesWithHelmet = () => (
  <>
    <Helmet>
      <title>Jungle - Admin Issue</title>
    </Helmet>
    <AdminIssues />
  </>
);

const AllNotificationWithHelmet = () => (
  <>
    <Helmet>
      <title>Jungle - All Notification</title>
    </Helmet>
    <NotificationPage />
  </>
);

const AllGroupWithHelmet = () => (
  <>
    <Helmet>
      <title>Jungle - All Group Show</title>
    </Helmet>
    <GroupShow />
  </>
);

const UserIssuesWithHelmet = () => (
  <>
    <Helmet>
      <title>Jungle - User Issues</title>
    </Helmet>
    <UserIssues />
  </>
);

const UserWithTicketHelmet = () => (
  <>
    <Helmet>
      <title>Jungle - User Ticket</title>
    </Helmet>
    <UserTicket />
  </>
);

const AdminTicketWithHelmet = () => (
  <>
    <Helmet>
      <title>Jungle - Admin Ticket</title>
    </Helmet>
    <AdminTicket />
  </>
);

const ClientWithHelmet = () => (
  <>
    <Helmet>
      <title>Jungle - Profile Page</title>
    </Helmet>
    <Client />
  </>
);

const GroupWithHelmet = () => (
  <>
    <Helmet>
      <title>Jungle - Group</title>
    </Helmet>
    <Group />
  </>
);

const DashboardWithHelmet = () => (
  <>
    <Helmet>
      <title>Jungle - DashBoard</title>
    </Helmet>
    <Dashboard />
  </>
);

const ProfileWithHelmet = () => (
  <>
    <Helmet>
      <title>Jungle - Profile Page</title>
    </Helmet>
    <Profile />
  </>
);

export default AppRouter;
