
import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import logo from '../../assets/OW-logo.jpg'
import sponsor1 from '../../assets/sponsor_1.png';
import PostNew from './Post/Post-new';
import MoonLoader from "react-spinners/MoonLoader";
import PlaceholderLoading from 'react-placeholder-loading';

import { FcNews, FcComments, FcOpenedFolder, FcBullish } from "react-icons/fc";
import { CiCamera } from "react-icons/ci";
import { HiPencil } from "react-icons/hi2";
import { BiPhotoAlbum } from "react-icons/bi";
import { CgWebsite  } from "react-icons/cg";
import { Button } from 'react-bootstrap';
import Swal from "sweetalert2";
import { Dropdown } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

import 'react-loading-skeleton/dist/skeleton.css';
import "sweetalert2/src/sweetalert2.scss";
import '../../assets/css/DropDown.css'

import HeaderLazyLoad from './HeaderLazyLoad';
import PostLazyLoader from './Post/PostLazyLoad';
import APICall from "../../api";
import { getImageDataFromLocalStorage } from '../Login/login';
import Header from './Header';


const Dashboard = () => {
  // ---------------------------------------------------------------------
  // State hooks
  const [postsData, setPosts] = useState(null);
  const [mediaType, setMediaType] = useState('knowledge');
  const [activeTab, setActiveTab] = useState('description');
  const [postNew, setPostNew] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [postMediaType, setPostMediaType] = useState('');
  const [textareaValue, setTextAreaValue] = useState('');
  const [showAmount, setShowAmount] = useState(5);
  const [loading, setLoading] = useState(false);
  
  // ---------------------------------------------------------------------
  // API Calling
  const retrievePostDB = async (type) => {
    try {
      if (type === undefined) {
        type = 'knowledge';
      }
      
      const contentType = "application/json";
      const endPoint = "Post/getPost";
      const bodyContent = {
        userId : Cookies.get('userId'),
        mediaType : type,
        showAmount: showAmount
      }

      APICall("POST", endPoint, bodyContent, contentType).then(
        response => {
          if (!response.success && response.message === 'SessionExpired') {
            localStorage.clear();
            window.location.href = '/login?sessionExpired=true';
          }

          if (response.success) {
            const data = response.data;
            setMediaType(type);
            setPosts(data);
          }
          else {
            console.error('Failed to get post', response.message);
            Swal.fire({
              icon: "error",
              title: "Get post failed!",
              text: "Something went wrong, please try again later"
            });
          }
        }
      )    
    } catch (error) {
      console.error('An error occurred while fetching posts', error);
    }      
  };

  const publishPost = async () => {
    try {
      if (textareaValue.length > 1000) {
            // Fire alert for success
            Swal.fire({
              icon: "warning",
              title: "Words exceed limit!",
              text: "Only maximum 1000 character allowed",
            });
            return;       
      }

      const contentType = "application/json";
      const endPoint = "Post/createPost";
      const bodyContent = {
        "userId" : Cookies.get('userId'),
        "images" : [],
        "mediaType" : mediaType,
        "content" : textareaValue
      }

      APICall("POST", endPoint, bodyContent, contentType).then(
        response => {
          if (!response.success && response.message === 'SessionExpired') {
            localStorage.clear();
            window.location.href = '/login?sessionExpired=true';
          }

          if (response.success) {
            // Fire alert for success
            Swal.fire({
              icon: "success",
              title: "SUCCESS",
              text: "Post Published!",
            }).then((result) => {
              if (result.isConfirmed || result.dismiss === Swal.DismissReason.timer) {
                window.location.reload();
              }
            });
          }
          else {
          // Fire alert for fail
            Swal.fire({
              icon: "error",
              title: "Post publishes failed!",
              text: "Something went wrong, please try again later"
            });
          }
        }
      )      

    } catch (error) {
      console.log("An error occured while publishing post", error);
    }
  };


  // ---------------------------------------------------------------------
  // Event functions
  const handleTabClick = (tab, event) => {
    event.preventDefault();
    setActiveTab(tab);
  };  

  const handleToggle = () => {
    setShowDropdown(!showDropdown);
  };

  const handlePublish = () => {
    if (textareaValue.length <= 0) {
      Swal.fire({
        icon: "warning",
        title: "Post content empty!",
        text: "Please input something!",
      });
      return;
    }  

    publishPost();
  }

  const handleExternalPage = (externalURL) => {
    // Navigate to the external URL
    window.open(externalURL, '_blank');
  };  

  const handleClickLoading = () => {
    setShowAmount(prevAmount => prevAmount + 5);
    setLoading(true);
  }

  useEffect(() => {
    let checkCount = 0;
    let key = null;
    let elementFound = false;

    if (postsData && postsData.length > 0){
      key = postsData[postsData.length-1].postID + '-' + (postsData.length-1) + '-' + postsData[postsData.length-1].userID  
    }
    const checkPost = () => {
      const element = document.getElementById(key);
      if (!elementFound) {
        if (!element && checkCount < 20) {
          setTimeout(() => {
            checkCount++;
            checkPost();
          }, 1000); 
        } else if (element) {
          elementFound = true;
          setLoading(false);
        }
      }
    };
    checkPost();
  }, [postsData]);

  // ---------------------------------------------------------------------
  // Effect hooks 
  useEffect(() => {
    retrievePostDB('knowledge');
  }, []);

  useEffect(() => {
    if (showAmount > 5) {
        retrievePostDB(mediaType);
    }
  }, [showAmount]);

  // ---------------------------------------------------------------------
  // Styling section
  const mainbody = {
    paddingTop: '5rem',
    marginBottom: '160px',
    backgroundColor: '#F8F8F8',
  };

  const cardIcon = {
    marginLeft: "auto", 
    marginTop: "-30px",
    fontSize: '150%'
  };

  const cardList = {
    height: "3.7rem",
    cursor: 'pointer'
  };  

  // left-panel cardtext
  const cardText = {
    width: '18rem',
    marginBottom: '2rem',
  }

  // left panel - profilepic
  const profilePicture = {
    marginTop: '-75px',
    width: '100px',
    height: '100px'
  }

  //left panel - about
  const aboutLink = {
    textDecoration: 'none',
  }

  //middle panel - feed
  const cardWidth = {
    width: '39rem',
    backgroundColor: '#F8F8F8'
  }

  // ---------------------------------------------------------------------
  // Cookies data
  const is_admin = Cookies.get('is_admin');
  const imageData = getImageDataFromLocalStorage();

  return (
    <>
    <HeaderLazyLoad />
      <div style={mainbody}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-6 col-md-4 col-lg-3" style={{marginRight: '-20px'}}>
              {/* left panel - profile*/}
              <div className="card" style={cardText}>
                <img src={logo} className="card-img-top" alt="logo" />
                <div className="card-body text-center">
                  <img src="/kjackProfilePic.png" className="rounded-circle mt-n5" alt="coder" style={profilePicture} />
                  <h5 className="card-title">One World Organization</h5>
                  <p className="card-text" style={{marginTop: '-8px'}}>Financial Education</p>
                  {/* <a href="" className="btn btn-primary">Follow Me</a> */}
                  {/* <PostNew is_admin = {is_admin}/> */}
                </div>
              </div>

              {/* left panel - pages*/}
              <div className="col-lg-4" style={{width: "290px" }}>
                <div className="card mb-2 rounded-3">
                  <div className="card-body">
                    <h5 className="card-text fst-italic text-black-50 fs-6">All available pages/tabs</h5>
                  </div>
                </div>
                <div className="card mb-2" style={{borderRadius: '17px'}}>
                  <ul className="list-group list-group-flush rounded-4">
                    <li className="list-group-item d-flex flex-column align-items-start" style={cardList} onClick={() => {setPosts(null); 
                                                                                                                          setShowAmount(5);
                                                                                                                          retrievePostDB('knowledge');}}>
                      <span className="fw-semibold " style={{ marginBottom: "-1%", fontSize: '.9rem' }}> Knowledge Sharing </span>
                      <span className="text-black-50" style={{ fontSize: '.8rem' }}> Knowledge tab</span>
                      <FcNews style={cardIcon}/>
                    </li>
                    <li className="list-group-item d-flex flex-column align-items-start" style={cardList} onClick={() => {setPosts(null);
                                                                                                                          setShowAmount(5);
                                                                                                                          retrievePostDB('education')}}>
                      <span className="fw-semibold" style={{ marginBottom: "-1%", fontSize: '.9rem' }}> Education</span>
                      <span className="text-black-50" style={{ fontSize: '.8rem'}}>Education tab</span>
                      <FcOpenedFolder style={cardIcon}/>
                    </li>
                    <li className="list-group-item d-flex flex-column align-items-start" style={cardList} onClick={() => {setPosts(null); 
                                                                                                                          setShowAmount(5);
                                                                                                                          retrievePostDB('trading')}}>
                      <span className="fw-semibold" style={{ marginBottom: "-1%", fontSize: '.9rem' }}> Trading Sharing </span>
                      <span className="text-black-50" style={{ fontSize: '.8rem'}}>Trading tab</span>
                      <FcBullish style={cardIcon}/>
                    </li>
                    <li className="list-group-item d-flex flex-column align-items-start" style={cardList} onClick={() => {setPosts(null); 
                                                                                                                          setShowAmount(5);
                                                                                                                          retrievePostDB('announcement')}}>
                      <span className="fw-semibold" style={{ marginBottom: "-1%", fontSize: '.9rem' }}> Admin Announcement </span>
                      <span className="text-black-50" style={{ fontSize: '.8rem'}}>Trading tab</span>
                      <FcComments style={cardIcon}/>
                    </li>                    
                  </ul>
                </div>
              </div>
              {/* Left Panel Pages Ends*/}
            </div>

            {/* middle panel */}
            <div className="col-12 col-lg-6" style={{minWidth: '500px'}}>
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-8 col-md-6 col-lg-12 mb-4">
                  <div className="card" style={{ marginLeft: '-10px', width: '620px' }}>
                    <div className="card-header" style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: '8px', paddingRight: '8px' }}>
                      <ul className="nav nav-tabs card-header-tabs" id="bologna-list" role="tablist">
                        <li className="nav-item" style={{width: '50%'}}>
                          <a className={`nav-link ${activeTab === 'description' ? 'active' : ''}  d-flex justify-content-center align-items-center`} 
                              role="tab" style={{ cursor: 'pointer', fontWeight: '500', color:'gray' }} onClick={(e) => handleTabClick('description', e)}>
                            <HiPencil style={{marginRight: '5px'}}/>
                            Publish
                          </a>
                        </li>
                        <li className="nav-item" style={{width: '50%'}}>
                          <a className={`nav-link ${activeTab === 'history' ? 'active' : ''}  d-flex justify-content-center align-items-center`} 
                              role="tab" style={{ width: '310px', cursor: 'pointer', fontWeight: '500', color:'gray' }} 
                              onClick={(e) => {
                                handleTabClick('history', e);
                                setPostNew(true);
                              }}>
                            <BiPhotoAlbum style={{marginRight: '5px'}}/>
                            Photo
                          </a>
                        </li>
                      </ul>
                    </div>

                    {/* Create New Post Component */}
                    <div className="card-body" style={{margin: 0, padding: 0, width: '620px'}}>
                      <div className="tab-content">
                        <div className={`tab-pane ${activeTab === 'description' ? 'active' : ''}`} role="tabpanel">
                          <div className="card-body d-flex align-items-center" style={{marginLeft: '-2px', transform: 'translateY(1%)', marginTop: '-1px'}}>
                            <img
                              className="mr-3 mb-2 rounded-circle"
                              alt="Bootstrap Media Preview"
                              style={{ width: '40px', height: '40px', marginTop: '-20px'}}
                              src={
                                imageData === 'null'
                                  ? "/anon.jpg"
                                  : `data:image/jpeg;base64,${imageData}`
                              }
                            />
                            <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" type="text" value={textareaValue}
                                      placeholder= "Type something...." style={{marginLeft: '1px', border: 'None'}} 
                                      onChange={e => {
                                        // Check if Shift+Enter is pressed
                                        if (e.shiftKey && e.key === 'Enter') {
                                          e.preventDefault();
                                          // Insert a newline character at the current cursor position
                                          const cursorPosition = e.target.selectionStart;
                                          const newText = textareaValue.substring(0, cursorPosition) + '\n' + textareaValue.substring(cursorPosition);
                                          setTextAreaValue(newText);
                                        } else {
                                          // Otherwise, update the textarea value as usual
                                          setTextAreaValue(e.target.value);
                                        }
                                      }}
                                      >
                            </textarea>              
                          </div>
                          <div style={{ borderTop: '1px solid #ccc', width: '100%' }}></div>
                          <div style={{marginTop: '5px', marginBottom: '5px', marginLeft: '8px', display: 'flex'}}>
                            <Button style={{height: '34px', width: '90px', backgroundColor: '#F0F0F0', border: 'None', color: 'black', position: 'relative', zIndex: '1', cursor: 'default'}}>
                              <CiCamera className="fw-semibold" style={{marginBottom: '4px', marginRight: '5px', fontSize: '1.2rem', fontFamily: 'monospace'}}/>
                              Media
                              <Dropdown className="form-group mb-2 btn-dropdown justify-content-between align-items-center" style={{border: '0px'}}      
                                show={showDropdown}
                                onToggle={handleToggle}>

                                <Dropdown.Menu style={{marginTop: '10px', width: '300px !important'}}> 
                                <Dropdown.Item className="d-flex align-items-start" style={{height: '80px'}} onClick={() => {setPostMediaType('knowledge'); setPosts(null);}}>
                                    <div className="cart" style={{ fontSize: '2em' }}>
                                      <FcNews/>
                                    </div>
                                    <div className="text pl-3">
                                    <h4>Knowledge Sharing Page</h4>
                                    <p className="mb-0"><span className="quantity ml-3">Knowledge Tab</span></p>
                                    </div>
                                </Dropdown.Item>
                                <Dropdown.Item className="d-flex align-items-start" style={{height: '80px'}} onClick={() => {setPostMediaType('education'); setPosts(null);}}>
                                    <div className="cart" style={{ fontSize: '2em' }}>
                                      <FcOpenedFolder/>
                                    </div>
                                    <div className="text pl-3">
                                    <h4>Education Page</h4>
                                    <p><span className="quantity ml-3">Education Tab</span></p>
                                    </div>
                                </Dropdown.Item>
                                <Dropdown.Item className="d-flex align-items-start" style={{height: '80px'}} onClick={() => {setPostMediaType('trading'); setPosts(null);}}>
                                    <div className="cart" style={{ fontSize: '2em' }}>
                                      <FcBullish/>
                                    </div>
                                    <div className="text pl-3">
                                    <h4>Trading Sharing</h4>
                                    <p><span className="quantity ml-3">Trading Tab</span></p>
                                    </div>
                                </Dropdown.Item>
                                {is_admin === '1' ? (
                                  <Dropdown.Item className="d-flex align-items-start" onClick={() => {setPostMediaType('announcement'); setPosts(null);}}>
                                    <div className="cart" style={{ fontSize: '2em' }}>
                                      <FcComments/>
                                      </div>
                                      <div className="text pl-3">
                                      <h4>Admin Announcement</h4>
                                      <p><span className="quantity ml-3">Announcement Tab</span></p>
                                    </div>
                                  </Dropdown.Item>                        
                                ) : null }
                                </Dropdown.Menu>
                              </Dropdown>
                            </Button>
                            {
                              mediaType === 'knowledge' ? (
                                <>
                                <Button style={{height: '34px', width: '120px', backgroundColor: '#F0F0F0', border: 'None', color: 'black', display: 'flex', justifyContent: 'flex-start'}}>
                                  <div className="d-flex" style={{ fontSize: '1.5em', marginRight: '5px'}}>
                                    <FcNews/>
                                  </div>
                                  Knowledge
                                </Button>
                                </>
                              ) : mediaType === 'education' ? (
                                <>
                                <Button style={{height: '34px', width: '120px', backgroundColor: '#F0F0F0', border: 'None', color: 'black', display: 'flex', justifyContent: 'flex-start'}}>
                                  <div className="d-flex" style={{ fontSize: '1.5em', marginRight: '5px'}}>
                                    <FcOpenedFolder/>
                                  </div>
                                  Education
                                </Button>
                                </>
                              ) : mediaType === 'trading' ? (
                                <>
                                <Button style={{height: '34px', width: '110px', backgroundColor: '#F0F0F0', border: 'None', color: 'black', display: 'flex', justifyContent: 'flex-start'}}>
                                  <div className="d-flex" style={{ fontSize: '1.5em', marginRight: '5px'}}>
                                    <FcBullish/>
                                  </div>
                                  Trading
                                </Button>
                                </>
                              ) : mediaType === 'announcement' ? (
                                <>
                                <Button style={{height: '34px', width: '140px', backgroundColor: '#F0F0F0', border: 'None', color: 'black', display: 'flex', justifyContent: 'flex-start'}}>
                                  <div className="d-flex" style={{ fontSize: '1.5em', marginRight: '5px'}}>
                                    <FcComments/>
                                  </div>
                                  Announcement
                                </Button>
                                </>
                              ) : null
                            }

                            <button className="d-flex align-items-center justify-content-center" variant="outline-primary" 
                                    style={{marginLeft: 'auto', marginRight: '15px', borderRadius: '20px', border: 'None', backgroundColor: '#F0F0F0',
                                            fontSize: '13px', textTransform: 'uppercase', fontWeight: '700', width: '35px'}}
                                    onClick={() => handlePublish()}>
                              <HiPencil/>
                            </button>

                          </div>
                        </div>
                        
                        <div className={`tab-pane ${activeTab === 'history' ? 'active' : ''}`} role="tabpanel">
                          <PostNew is_admin = {is_admin} click = {postNew} mediaType = {mediaType} style={{ position: 'relative', zIndex: 0 }}/>
                        </div>
                      </div>
                    </div>
                    {/* Create New Post Component Ends */}
                  </div>
                </div>
              </div>
            </div>

              {/* middle panel - content */}
              <div className="card border-0" style={cardWidth}>
                {/* call post api  */}                
                {postsData !== null ? (
                  <>
                  {postsData.slice(0,showAmount).map((post, index) => {        
                    post.mediaType = mediaType;
                    if (post.sharePostID !== null) {
                      let sharedPost = postsData.find(sharedPost => sharedPost.postID === post.sharePostID);
                      if (sharedPost) {
                        if (sharedPost.sharePostID !== null) {
                          sharedPost = postsData.find((nestSharePost) => sharedPost.sharePostID === nestSharePost.postID);
                        }
                        post.oriPostUserId = sharedPost.userID;
                        post.oriMediaType = mediaType;
                        post.oriPostUserfn = sharedPost.first_name;
                        post.oriPostUserln = sharedPost.last_name;
                        post.oriPostUserPf = sharedPost.profilePic;
                        post.oriPostProfilePic = sharedPost.profilePic;
                        post.oriPostContent = sharedPost.content;
                        post.oriPostImageData = sharedPost.image_data;
                        post.oriPostPostTime = sharedPost.postTime;
                        post.oriIsAdmin = sharedPost.is_admin;
                        post.oriMediaType = mediaType;
                        return (
                          <div key={post.postID + '-' + index + '-' + post.userID + post.oriPostUserId + "share"}>
                            <PostLazyLoader post={post} key={post.postID + '-' + index + '-' + post.userID + '-' + post.oriPostUserId + "share"}/>
                            {/* <PostContent {...post} key={post.postID + '-' + index + '-' + post.userID + '-' + post.oriPostUserId + "share"}/> */}
                          </div>
                        );
                      }
                      // if the shared post is not found
                      else {
                        return (
                          <div key={post.postID + '-' + index + '-' + post.userID + '-' + "share"}>
                            <PostLazyLoader post={post} key={post.postID + '-' + index + '-' + post.userID + '-' + post.oriPostUserId + "share"}/>
                            {/* <PostContent {...post} key={post.postID + '-' + index + '-' + post.userID + "share"}/> */}
                          </div>
                        );
                      }                    
                    }
                    else {
                      return (
                        <div key={post.postID + '-' + index + '-' + post.userID} id={post.postID + '-' + index + '-' + post.userID}>
                          <PostLazyLoader post={post} key={post.postID + '-' + index + '-' + post.userID + '-' + post.userID}/>
                          {/* <PostContent {...post} key={post.postID + '-' + index + '-' + post.userID}/> */}
                        </div>
                      )
                    }
                  })}
                  {
                    loading === false ? (
                      <div className="d-flex align-items-center justify-content-center">
                        <Button className="mt-2 rounded-1"
                            style={{backgroundColor: 'gray', border: 'gray solid 2px', width:'130px'}}
                            onClick={() => handleClickLoading()}
                            onMouseEnter={(e) => {
                            e.target.style.color = 'black';  // Change font color on hover
                            }}
                            onMouseLeave={(e) => {
                            e.target.style.color = 'white';  // Revert font color when mouse leaves
                            }}>
                            {postsData && postsData.length < showAmount ? "No More Post!" : (
                              "Load More"
                            )}
                        </Button>   
                      </div>
                    ) : (
                      <div className="d-flex align-items-center justify-content-center">
                        <MoonLoader size={30}/>
                      </div>
                    )
                  }
                  </>
                ) : (
                  <>
                  <div className='mb-4'>
                    <div className='d-flex align-items-center mb-3'>
                      <PlaceholderLoading shape="circle" width={40} height={40} />
                      <div className='flex-column' style={{marginLeft: '10px'}}>
                        <PlaceholderLoading shape="rect" width={340} height={15} />
                        <PlaceholderLoading shape="rect" width={250} height={15} />
                      </div>
                    </div>
                    <Skeleton count={4} height={15}/>      
                  </div>

                  <div className='mb-4'>
                    <div className='d-flex align-items-center mb-3'>
                      <PlaceholderLoading shape="circle" width={40} height={40} />
                      <div className='flex-column' style={{marginLeft: '10px'}}>
                        <PlaceholderLoading shape="rect" width={340} height={15} />
                        <PlaceholderLoading shape="rect" width={250} height={15} />
                      </div>
                    </div>
                    <Skeleton count={4} height={15}/>      
                  </div>    

                  <div className='mb-4'>
                    <div className='d-flex align-items-center mb-3'>
                      <PlaceholderLoading shape="circle" width={40} height={40} />
                      <div className='flex-column' style={{marginLeft: '10px'}}>
                        <PlaceholderLoading shape="rect" width={340} height={15} />
                        <PlaceholderLoading shape="rect" width={250} height={15} />
                      </div>
                    </div>
                    <Skeleton count={4} height={15}/>      
                  </div>   
                  </>
                )
              }
              </div>
              {/* middle panel - content Ends*/}
            </div>
            {/* middle panel Ends*/}

            {/* right panel */}
            <div className="col-12 col-lg-3" style={{marginLeft: '-1.4%'}}>

              {/* right panel - Sponsored */}
              <div className="card" style={cardText}>
                <div className="card-body">
                  <h5 className="card-title">Sponsored</h5>
                  <img src={sponsor1} alt="" className="img-fluid mb-2" />
                  <p className="card-text">Visit our website to connect with others!</p>
                  <p className="card-text">Website: <a href="https://omi.sg/" target="_blank" style={aboutLink}>OMI</a></p>
                </div>
              </div>

              {/* right panel - pages*/}
              <div className="col-lg-4" style={{width: "290px" }}>
                <div className="card border-bottom-0" style={{ borderTopLeftRadius: '1rem', borderTopRightRadius: '1rem', borderBottomLeftRadius: '0rem', borderBottomRightRadius: '0rem'}}>
                  <div className="card-body">
                    <h5 className="card-text fst-italic text-black-50 fs-6">Suggested Website For You!</h5>
                  </div>
                </div>
                <div className="card mb-2">
                  <ul className="list-group list-group-flush" style={{ borderTopLeftRadius: '0rem', borderTopRightRadius: '0rem'}}>
                    <li className="list-group-item d-flex flex-column align-items-start" style={cardList}  onClick={() => handleExternalPage('https://kj-glovicevc.com/')}>
                      <span className="fw-semibold " style={{ marginBottom: "-1%", fontSize: '.9rem' }}> Glovice VC </span>
                      <span className="text-black-50" style={{ fontSize: '.8rem' }}> Trading Bot</span>
                      <CgWebsite  style={cardIcon}/>
                    </li>
                    <li className="list-group-item d-flex flex-column align-items-start" style={cardList} onClick={() => handleExternalPage('https://jonegroup.iptime.org:12600/auth/Login')}>
                      <span className="fw-semibold" style={{ marginBottom: "-1%", fontSize: '.9rem' }} > Kizumi Wallet</span>
                      <span className="text-black-50" style={{ fontSize: '.8rem'}}>E-Wallet</span>
                      <CgWebsite  style={cardIcon}/>
                    </li>
                    <li className="list-group-item d-flex flex-column align-items-start" style={cardList} onClick={() => handleExternalPage('https://www.metatrader4.com/ja/trading-platform/web-trading')}>
                      <span className="fw-semibold" style={{ marginBottom: "-1%", fontSize: '.9rem' }} > MT4 Trading </span>
                      <span className="text-black-50" style={{ fontSize: '.8rem'}}>Web Trader</span>
                      <CgWebsite  style={cardIcon}/>
                    </li>
                  </ul>
                </div>
                {/* Left Panel Pages Ends*/}
              </div>                  

            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
