import { Modal, Button } from 'react-bootstrap';
import { AiOutlineClose } from "react-icons/ai";
import Carousel from "react-bootstrap/Carousel";
import { mdiHeartOutline, mdiHeart } from "@mdi/js";
import { mdiCommentTextOutline } from "@mdi/js";
import React, { useState, useEffect } from 'react';
import Icon from "@mdi/react";
import Swal from 'sweetalert2'
import Cookies from 'js-cookie';

import FullPostComment from './FullPost-comment';
import APICall from '../../../api';

const FullPost = ({ handleClose, showModal, postProps }) => {
  // --------------------------------------------------------------------
  // State hooks
  const [togglelikes, setToggleLikes] = useState(postProps.togglelikes);
  const [postLLikes, setPostLikes] = useState(postProps.postLLikes);
  const [commentCounts, setCommentCounts] = useState(null);

  // ---------------------------------------------------------------------
  // API Calling
  const handleLike = async () => {
    try {
      const contentType = "application/json";
      const endPoint = "Post/likePost";
      const bodyContent = {
        userId: Cookies.get("userId"),
        postId: postProps.postID,
        likeHit: togglelikes,
        recipientId: postProps.userID
      }

      APICall("POST", endPoint, bodyContent, contentType).then(
        response => {
          if (!response.success && response.message === 'SessionExpired') {
            localStorage.clear();
            window.location.href = '/login?sessionExpired=true';
          }

          if (response.success) {
            const newPostLikes = response.data;
            setPostLikes(newPostLikes["newPostLikes"]);
            setToggleLikes(!togglelikes); 
          }
          else {
            console.error('Failed to get likes', response.message);
            Swal.fire({
              icon: "error",
              title: "Get likes failed!",
              text: "Something went wrong, please try again later",
            });
          }
        }
      )       
    } catch (error) {
      console.error("An error occurred while liking post", error);
    }
  }; 

  // ----------------------------------------------------
  // Event functions
  const isPortrait = (base64Data) => {
    const image = new Image();
    image.src = `data:image/jpeg;base64,${base64Data}`; // Adjust this based on the image format
    return image.height >= image.width;
  };

    return (
        <Modal show={showModal} fullscreen={true}>      
            {/* <Modal.Body className="modal-body" style={{height: '100%'}}> */}
            <Button className="close-btn" variant="none" aria-label="Close" onClick={handleClose} style={{ position: 'absolute', top: '0', right: 0, zIndex: '1000' }}>
              <span aria-hidden="true"><AiOutlineClose style={{ fontSize: '1.5rem' }} /></span>
            </Button>
            <div className="container-fluid h-100">
                <div className='row h-100'>
                    <div className="col-lg-8" style={{marginRight: '-1.5%', background: 'black'}}>
                        <div className='card d-flex justify-content-center h-100' style={{border: 'None', background: 'transparent'}}>
                            <Carousel indicators={false}>
                            {
                                postProps.oriPostImageData !== null && postProps.oriPostImageData !== undefined ? (
                                    postProps.oriPostImageData.map((image, index) => (
                                            <Carousel.Item key={index}>
                                                <img
                                                    key={image + index}
                                                    src={`data:image/jpeg;base64,${image}`}
                                                    // style = {{width: '600px', height: '600px'}}

                                                    onLoad={(e) => {
                                                        const img = e.target;
                                                        if (img.naturalWidth > 1000 || img.naturalHeight > 1000) {
                                                            img.style.width = `1000px`;
                                                            img.style.height = `700px`;
                                                        }
                                                    }}                                      
                                                />
                                            </Carousel.Item>
                                    )
                                )) : (
                                    postProps.image_data.map((image, index) => (
                                        <Carousel.Item key={index}>
                                            <img
                                                key={image + index}
                                                className='d-block mx-auto'
                                                src={`data:image/jpeg;base64,${image}`}
                                                style={{ height: '550px' }}
                                                onLoad={(e) => {
                                                    const img = e.target;    
                                                    if (isPortrait(image)) {
                                                        let tempH = img.naturalHeight;
                                                        let tempW = img.naturalWidth;
                                                        while (tempH > 900) {
                                                            tempH = tempH / 2;
                                                            tempW = tempW / 2;
                                                            // img.style.height = `${tempH}px`;
                                                            img.style.width = `${tempW}px`;
                                                        }
                                                    }
                                                    else {
                                                        let tempH = img.naturalHeight;
                                                        let tempW = img.naturalWidth;
                                                        while (tempW >= 1300 ) {
                                                            tempH = tempH / 2;
                                                            tempW = tempW / 2;
                                                            img.style.height = `${tempH}px`;
                                                            img.style.width = `${tempW}px`;
                                                        }
                                                        if (tempH > 900) {
                                                            img.style.width = `1280px`;
                                                            img.style.height = `900px`;
                                                        }
                                                        
                                                    }
                                                }}                                                      
                                            />
                                        </Carousel.Item>)
                                ))
                            }
                            </Carousel> 
                        </div>
                    </div>
                    <div className="col-lg-4 d-flex flex-column h-100" style={{marginLeft: '20px'}}>
                        {/* User Post Information */}
                        <div className='' style={{marginTop: '10px', marginLeft: '15px'}}>
                            <div className='d-flex'>
                                <img 
                                    className='rounded-5'
                                    src={
                                        postProps.profilePic !== null ? 
                                        `data:image/jpeg;base64,${postProps.profilePic}`
                                        : "/anon.jpg"
                                    }
                                    style={{width: '50px', height: '50px'}}
                                />
                                <div className='flex-column'  style={{marginLeft: '10px'}}>
                                    <div className='d-flex' style={{marginBottom: '-15px'}}>
                                        <p className='text-black fw-semibold' style={{marginTop: '5px', marginBottom: '-5px'}}>{postProps.first_name + postProps.last_name}</p>
                                        <p style={{fontSize: '.8rem', marginTop: '7px'}}> - {postProps.postTime}</p>
                                    </div>
                                    <p className='text-black-50' style={{fontSize: '.75rem'}}>
                                        {`${Cookies.get("is_admin") === "1" ? 'Admin' : 'User'} | ${postProps.sharePostID !== null ? 'Shared publicly' : 'Post publicly'}`}
                                    </p>
                                </div>
                            </div>
                            <div className='mt-2'>
                                <p style={{lineHeight: 1.2, whiteSpace: "pre-line"}}>{postProps.content}</p>
                            </div>
                            {/* User Post Information Ends Here*/}
                        </div>
                        {/* Like, Post Section */}
                        <hr style={{marginBottom: '5px'}}></hr>
                        <div className="d-flex">
                            <p
                                className="small-text text-black-50 text-xs"
                                onClick={handleLike}
                                style={{ cursor: "pointer" }}
                            >
                                <Icon
                                path={togglelikes ? mdiHeart : mdiHeartOutline}
                                size={1}
                                style={{ margin: "0 5px", color:"gray" }}
                                />
                                {postLLikes}
                            </p>
                            <p>
                            <Icon
                                path={mdiCommentTextOutline}
                                size={1}
                                style={{ margin: "0 5px", color:"gray", marginLeft: '10px' }}
                            />
                            {commentCounts !== null ? commentCounts : null}
                            </p>
                            <p style={{marginLeft: 'auto', marginRight: '15px'}}>{commentCounts !== null ? commentCounts : null} comments</p>
                        </div>
                        {/* Like, Post Ends Here*/}

                        {/* Comments Secion */}
                        <div className='flex-grow-1' style={{background:'#F0F0F0', overflowY: 'auto', marginTop: '-10px'}}>
                            <FullPostComment postID={postProps.postID} postUserID={postProps.userID} scivId={postProps.scivId} msgCode={postProps.msgCode} setCommentCounts={setCommentCounts}/>
                        </div>
                    </div>
                </div>
            </div>
    
            {/* </Modal.Body> */}
        </Modal>
    );
}   

export default FullPost;
