import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { Modal } from "react-bootstrap";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import 'sweetalert2/src/sweetalert2.scss';
import PulseLoader from "react-spinners/PulseLoader";

export const saveImageDataToLocalStorage = (imageData) => {
	localStorage.setItem('profilePic', imageData);
};

export const getImageDataFromLocalStorage = () => {
	return localStorage.getItem('profilePic');
};

const Login = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const [pop, setPop] = useState(false);
	const [text, setText] = useState('');
	const [emailSent, setEmailSent] = useState(false);
	const [match, setMatch] = useState(null);
	const [code, setCode] = useState('');
	const [loading, setLoading] = useState(false);

	const closePop = () => {
		setPop(false);
		setEmailSent(false);
		setText('');
		setMatch(null);
		setCode('');
	}

	const handleInputChange = (event) => {
        setText(event.target.value);
    };

	const handleSendEmail = () => {
		if (text.length <= 0) {
			Swal.fire({
				icon: 'warning',
                title: 'Empty Input!',
                text: 'Please fill in your email correctly!',
			})
			return;
		}

		const validEmailEndings = /(@outlook\.com|@gmail\.com|@kj-planning\.co\.jp)$/;
		if (!validEmailEndings.test(text)) {
			Swal.fire({
				icon: 'warning',
                title: 'Invalid Email!',
                text: 'Please fill in a proper email!',
			})
			return;
		}
		setLoading(true);
		sendEmailRecovery();
	}

	const checkCode = () => {
		if (text === code) {
			setMatch(true);
		}
		else {
			Swal.fire({
				icon: 'error',
                title: 'Wrong Recovery Code!',
                text: 'Please type in the correct code!',				
			})
		}
	}

	const handleLogin = (e) => {
		e.preventDefault();

		const email = e.target.elements.Email.value;
		const password = e.target.elements.Password.value;

		// API Calling
		const xhr = new XMLHttpRequest();
		xhr.open('POST', 'https://www.jungp.online/api/v1/login', true)
		xhr.setRequestHeader('Content-Type', 'application/json')

		xhr.onreadystatechange = function () {
			if (xhr.readyState === 4) {
				if (xhr.status === 200) {
				const response = JSON.parse(xhr.responseText);
				// Fire alert for success
				Swal.fire({
					icon: 'success',
					title: 'Login successful!',
					text: 'You have successfully Logged-in!',
				});

				Cookies.set('userId', response.data.userId);
				Cookies.set('is_admin', response.data.is_admin);
				Cookies.set('groupId', response.data.groupId);
				Cookies.set('accessToken', response.data.access_token);
				Cookies.set('refreshToken', response.data.refresh_token);
				saveImageDataToLocalStorage(response.data.profilePic);
				localStorage.setItem('userTicketSeen', true);

				navigate('/home');
				} else if (xhr.status === 400) {
					// Fire alert for fail
					Swal.fire({
						icon: 'error',
						title: 'Login failed!',
						text: "Email/Password is incorrect!"
					});  
				} else if (xhr.status === 401) {
					Swal.fire({
						icon: 'error',
						title: 'Login failed!',
						text: "Email not found!"						
					})
				} else {
				const response = JSON.parse(xhr.responseText);
					// Fire alert for fail
					Swal.fire({
						icon: 'error',
						title: 'Login failed!',
						text: "Something went wrong, please try again later"
					});  
					}
			}
		};

		xhr.send(JSON.stringify({
			email: email,
			password: password,
		}));
	}

	const sendEmailRecovery = () => {
		// API Calling
		const xhr = new XMLHttpRequest();
		xhr.open('POST', 'https://www.jungp.online/api/v1/pw/recovery/email', true)
		xhr.setRequestHeader('Content-Type', 'application/json')

		xhr.onreadystatechange = function () {
			if (xhr.readyState === 4) {
				if (xhr.status === 200) {
				const response = JSON.parse(xhr.responseText);
				setEmailSent(true);
				setLoading(false);
				setCode(response.data);
				} else {
				const response = JSON.parse(xhr.responseText);
					// Fire alert for fail
					Swal.fire({
						icon: 'error',
						title: ' failed!',
						text: "Something went wrong, please try again later"
					});  
					}
			}
		};

		xhr.send(JSON.stringify({
			email: text,
		}));		
	}

	const handlePwRecovery = (e) => {
        e.preventDefault();
		const email = e.target.elements.Email.value;
		const password = e.target.elements.Password.value;

		// API Calling
		const xhr = new XMLHttpRequest();
		xhr.open('POST', 'https://www.jungp.online/api/v1/pw/recovery', true)
		xhr.setRequestHeader('Content-Type', 'application/json')

		xhr.onreadystatechange = function () {
			if (xhr.readyState === 4) {
				if (xhr.status === 200) {
				const response = JSON.parse(xhr.responseText);
				// Fire alert for success
				Swal.fire({
					icon: 'success',
					title: 'Password Reset Successfully!',
					text: 'You may head to login session now!',
				}).then(() => {
                    closePop();
                });

				} else if (xhr.status === 400) {
					// Fire alert for fail
					Swal.fire({
						icon: 'error',
						title: 'Email is not found!',
						text: "Please double check your email!"
					});  
				} else {
				const response = JSON.parse(xhr.responseText);
					// Fire alert for fail
					Swal.fire({
						icon: 'error',
						title: 'Recovery failed!',
						text: "Something went wrong, please try again later"
					});  
                }
			}
		};

		xhr.send(JSON.stringify({
			email: email,
			password: password,
		}));
	}

	useEffect(() => {
		// Check for sessionExpired query parameter
		const params = new URLSearchParams(location.search);
		const sessionExpired = params.get('sessionExpired');
	
		if (sessionExpired === 'true') {
		  Swal.fire({
			icon: 'warning',
			title: 'Session Expired',
			text: 'Your session has expired. Please log in again.',
		  });
		}
	  }, [location.search]);

    return (
		<div style={{  }}>
			<head>
				<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.7.2/font/bootstrap-icons.css"/>
				{/* <link rel="stylesheet" type="text/css" href="./css/style.css" /> */}
			</head>
			<div class="container-fluid vh-100 overflow-auto">
				<div class="row vh-100 ">
					<div class="col-lg-6 bg-gray p-5 text-center" style={{marginTop: '90px', marginLeft: '40px'}}>
						<h4 class="text-center fw-bolder fs-2">Register</h4>
						<p class="mb-3 fs-7">Register Now and Fell the New Trading Community!</p>
						<Link to="/register">
							<a href="register.html">
								<button class="btn fw-bold mb-5 btn-outline-success px-4 rounded-pill">Sign Up</button>
							</a>						
						</Link>

						<div class="img-cover p-4">
							<img src="/K-JACK2.png" style={{ marginLeft: '40px', width: "80%"}}/>
						</div>
					</div>
					<div class="col-lg-6 p  vh-100" style={{marginLeft: '-80px'}}>
						<form onSubmit={handleLogin}>
							<div class="row d-flex vh-100">
								<div class="col-md-8 p-4 ikigui m-auto text-center align-items-center">
									<div class='flex-column'>
										<p class="text-center fw-normal mb-0 fs-3" style={{ color: 'black' }}>Welcome To Jungle Platform! 👋🏼</p>
										<div class='flex-column' style={{ fontSize: '.8rem' }}>
											<p>Please Sign In To Your Account And Start Exploring!</p>
										</div>
									</div>
									<div class="input-group mb-4">
										<span class="input-group-text border-end-0 inbg" id="basic-addon1"><i class="bi bi-person"></i></span>
										<input required type="text" 
												class="form-control ps-2 border-start-0 fs-7 inbg form-control-lg mb-0" 
												placeholder="Enter Email" 
												name="Email"/>
									</div>
									<div class="input-group mb-4">
										<span class="input-group-text border-end-0 inbg" id="basic-addon1"><i class="bi bi-lock"></i></span>
										<input required type="password" 
												class="form-control ps-2 fs-7 border-start-0 form-control-lg inbg mb-0" 
												placeholder="Enter Password" 
												name="Password"/>
									</div>
									<div className='d-flex justify-content-center'>
										<button class="btn btn-lg fw-bold fs-8 btn-success  w-100">Login</button>
										<button type='button' class="btn btn-lg fw-bold fs-8 btn-success  w-100" onClick={() => setPop(true)}>Forgot Password</button>
									</div>
								</div>
							</div>
						</form>
					</div>
					{
						pop ? (
							<Modal show={pop} onHide={() => closePop()} className="modal-with-background">
							<div className="modal-content">
								<img src='/bg-1.jpg' className='img-fluid fixed-background' alt="Background Image" style={{ height: '200px' }} />
								<Modal.Body className="pt-md-0 pb-5 px-4 px-md-5 text-center" style={{ marginTop: '-120px' }}>
									<h2 style={{ color: 'black' }}>Password Recovery</h2>
									<div className="icon d-flex align-items-center justify-content-center">
										<img src="/email.svg" alt="" className="img-fluid" style={{ height: '30%', width: '30%' }} />
									</div>
									<div className='mt-3'>
										{ match && emailSent ? (
											<>
											<h5 className="mb-1">Code Matched!</h5>
											<p className="mb-4" style={{lineHeight: 1, fontSize: '.85rem'}}>
												Please key in the email again along with your new password
											</p>

											<div className="recovery-form">
											<form onSubmit={handlePwRecovery}>
												<div class="input-group mb-4">
													<span class="input-group-text border-end-0 inbg" id="basic-addon1"><i class="bi bi-person"></i></span>
													<input required type="text" 
															class="form-control ps-2 border-start-0 fs-7 inbg form-control-lg mb-0" 
															placeholder="Enter Email" 
															name="Email"/>
												</div>
												<div class="input-group mb-4">
													<span class="input-group-text border-end-0 inbg" id="basic-addon1"><i class="bi bi-lock"></i></span>
													<input required type="password" 
															class="form-control ps-2 fs-7 border-start-0 form-control-lg inbg mb-0" 
															placeholder="Enter New Password" 
															name="Password"/>
												</div>
												<div className='d-flex justify-content-center'>
														<button class="btn btn-lg fw-bold fs-8 btn-success w-100" style={{ border: 'none' }}>Submit</button>
												</div>                                
											</form>
											</div>
											</>
										) : emailSent ? (
											<div>
												<h5 className="mb-1">Email Sent!</h5>
												<p className="mb-4" style={{lineHeight: 1, fontSize: '.85rem'}}>
													If your email is found in our system, <br></br>you will receive an email for recovery code
												</p>
												<div className="d-flex">
													<input name="code" style={{ width: "70%" }} type="text" 
															className="form-control" placeholder="Enter the code"
															onChange={handleInputChange}/>
													<button style={{ width: "30%", backgroundColor: '#3E64FF', color: 'white', border: 'none', cursor: 'pointer', 
															borderTopRightRadius: '5px', borderBottomRightRadius: '5px',
															borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px', }} type="button" 
															className="form-control submit fw-semibold"
															onClick={() => checkCode()}>Submit</button>
												</div>
											</div>
										) : (
											<>
											<h5 className="mb-2">Input Email For Recovery</h5>
											<div className="recovery-form">
												<div className="d-flex">
													<input name="email" style={{ width: "70%" }} type="text" 
															className="form-control" placeholder="Enter email address"
															onChange={handleInputChange}/>
													<button style={{ width: "30%", backgroundColor: '#3E64FF', color: 'white', border: 'none', cursor: 'pointer', 
															borderTopRightRadius: '5px', borderBottomRightRadius: '5px',
															borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px', }} type="button" 
															className="form-control submit fw-semibold"
															onClick={() => handleSendEmail()}>Send</button>
												</div>
												{
													loading ? (
														<div className='mt-3' style={{ marginBottom: '-30px' }}>
															<PulseLoader size={5} color='#36d7b7'/>
														</div>
													) : null
												}
											</div>
											</>
										)
										}
									</div>
								</Modal.Body>
							</div>
						</Modal>
						) : null
					}
				</div>
			</div> 
		</div>
    );
}

export default Login;
