import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import { CiBellOn } from "react-icons/ci";
import { GrGroup } from "react-icons/gr";
import { FaSignsPost } from "react-icons/fa6";
import ClipLoader from "react-spinners/ClipLoader";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { FaShareSquare } from "react-icons/fa";
import { FaRegCommentDots } from "react-icons/fa";
import { AiFillLike } from "react-icons/ai";

import APICall from "../../api";
import Header from "./Header";
import NotificationModel from './NotificaionModel';
import PostNotificationModel from "./Post/Post-notification";

const NotificationPage = () => {
    // ---------------------------------------------------
    // State hooks
    const [notifications, setNotifications] = useState([]);
    const [showAmount, setShowAmount] = useState(5);
    const [loading, setLoading] = useState(false);
    const [showGroupModal, setShowGroupModal] = useState(false);
    const [msgCode, setMsgCode] = useState({});
    const [messageCode, setMessageCode] = useState(null);
    const [correspondId, setCorrespondId] = useState(null);
    const [showPostNotiModel, setShowPostNotiModel] = useState(false);    
    const navigate = useNavigate();

    // ---------------------------------------------------
    // API Calls
    const retrieveNotifications = async () => {
        try {
            const contentType = "application/json";
            const endPoint = "User/getNotifications";
            const bodyContent = {
                userId : Cookies.get('userId')
            }

            APICall("POST", endPoint, bodyContent, contentType).then(
            response => {
              if (!response.success && response.message === 'SessionExpired') {
                localStorage.clear();
                window.location.href = '/login?sessionExpired=true';
              }

                if (response.success) {
                const data = response.data;
                setNotifications(data);
                }
                else {
                console.error('Failed to get notifications', response.message);
                Swal.fire({
                    icon: "error",
                    title: "Get notifications failed!",
                    text: "Something went wrong, please try again later"
                });
                }
            }
            )    
        } catch (error) {
            console.error('An error occurred while fetching notifications', error);
        }      
    };

    const checkIsInGroup = async () => {
        try {
            const contentType = "application/json";
            const endPoint = "User/isInGroup";
            const bodyContent = {
                userId : Cookies.get('userId'),
            }
            
            return APICall("POST", endPoint, bodyContent, contentType).then(
                response => {
                  if (!response.success && response.message === 'SessionExpired') {
                    localStorage.clear();
                    window.location.href = '/login?sessionExpired=true';
                  }
    
                  if (response.success) {
                    const data = response.data;
                    return data;
                  }
                  else {
                    console.error('Failed to join group', response.message);
                    Swal.fire({
                      icon: "error",
                      title: "Join group failed!",
                      text: "Something went wrong, please try again later"
                    });
                  }
                }
              )
        } catch (error) {
            console.log("An error occured while validating group");
        }
      }
    
      const updateNotificationResponse = async (notification_id) => {
        try {
          const contentType = "application/json";
          const endPoint = "Notification/updateNotificationResponse";
          const bodyContent = {
            notification_id: notification_id,
            userId: Cookies.get("userId")
          }
          
          return APICall("POST", endPoint, bodyContent, contentType).then(
              response => {
                if (!response.success && response.message === 'SessionExpired') {
                  localStorage.clear();
                  window.location.href = '/login?sessionExpired=true';
                }                
                if (response.success) {
                  const data = response.data;
                  if (data) {
                    retrieveNotifications();
                  }
                }
                else {
                  console.error('Failed to update notification', response.message);
                  Swal.fire({
                    icon: "error",
                    title: "Update notification failed!",
                    text: "Something went wrong, please try again later"
                  });
                }
              }
            )
        } catch (error) {
            console.log("An error occured while updating notification");
        }    
      }

    // ---------------------------------------------------
    // Event functions
    const handleClickLoading = () => {
        setLoading(true);

        // Introduce a delay of 1000 milliseconds (1 second)
        setTimeout(() => {
            setShowAmount(prevAmount => prevAmount + 5);
            setLoading(false);
        }, 500);
    }

    const CloseNotificationPopupModal = () => {
        setShowGroupModal(false);
    }

    const closePostNotificationModel = () => {
      setShowPostNotiModel(false);
    }

    const handleNotificationClick = async (msgCode, correspond_id, notification_id, seen) => {
      if (seen) {
        return;
      }
      
      switch (msgCode) {
        case 'GROUP_INV':
        case 'GROUP_JOIN_REQ':
          handleNotificationPopupModal(msgCode, correspond_id, notification_id);
          break;
  
        case 'GROUP_JOIN_REQ_RE':
          redirectGroupPage();
          updateNotificationResponse(notification_id);
          break;
        
        case 'GROUP_INV_RE':
          redirectGroupPage();
          updateNotificationResponse(notification_id);
          break;
        
        case 'GROUP_REMOVE':
          redirectGroupPage();
          updateNotificationResponse(notification_id);
          break;
        
        case 'GROUP_LEADER_TRANS':
          redirectGroupPage();
          updateNotificationResponse(notification_id);
          break;
        
        case 'POST_COMMENT':
          setCorrespondId(correspond_id);
          setMessageCode(msgCode);
          setShowPostNotiModel(true);
          updateNotificationResponse(notification_id);
          break;
  
        case 'POST_COMMENT_TAG':
          setCorrespondId(correspond_id);
          setMessageCode(msgCode);
          setShowPostNotiModel(true);
          updateNotificationResponse(notification_id);
          break;
  
        case 'POST_LIKE':
          setCorrespondId(correspond_id);
          setShowPostNotiModel(true);
          updateNotificationResponse(notification_id);
          break;
        
        case 'POST_COMMENT_MENTION':
          setCorrespondId(correspond_id);
          setShowPostNotiModel(true);
          updateNotificationResponse(notification_id);
          break;

        case 'GROUP_CHAT_TAG':
          const gctData = await checkIsInGroup();
          if (gctData.inGroup) {
            const targetPath = `/home/group/${gctData.groupId}`;
            updateNotificationResponse(notification_id);
            const url = targetPath + "?openchat=true&correspondId="+correspond_id;
            window.location.href = url;   
          }
        break;
  
        case 'GROUP_CHAT_ALL':
          const gcData = await checkIsInGroup();
          if (gcData.inGroup) {
            const targetPath = `/home/group/${gcData.groupId}`;
            setCorrespondId(correspond_id);
            updateNotificationResponse(notification_id);
            const url = targetPath + "?openchat=true&correspondId="+correspond_id;
            window.location.href = url;
          }
        break;

        case 'ISSUES':
          updateNotificationResponse(notification_id);
          navigate(Cookies.get("is_admin") === '1' ? '/home/admin/issue' : '/home/user/issue', { state: { pop: 'true' }});
          break;
      }
    }

    const handleNotificationPopupModal = (msgCode, correspond_id, notification_id) => {
        setMsgCode({
          msgCode: msgCode,
          correspond_id: correspond_id,
          notification_id: notification_id
        });
        setShowGroupModal(true);
    }

    const redirectGroupPage = async () => {
        const data = await checkIsInGroup();
        if (data.inGroup) {
          navigate(`/home/group/${data.groupId}`);
        }
        else {
            Swal.fire({
              icon: "warning",
              title: "No Group Found",
              text: "You will be redirect to all group page as you're not in a group yet!",
            }).then((result) => {
              if (result.isConfirmed || result.dismiss === Swal.DismissReason.timer) {
                navigate(`/home/group/all`);
              }
            });
        }
      }


    // ---------------------------------------------------
    // Effect hooks
    useEffect(() => {
        retrieveNotifications();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
        <Header />
        <div className="" style={{paddingTop: '6rem'}}>
            <div className="d-flex align-items-center justify-content-center">
                <CiBellOn style={{fontSize: '1.9rem', marginBottom: '7px'}}/>
                <h5>Notification List</h5>
            </div>

            <div className="d-flex flex-column align-items-center justify-content-center">
                {
                    notifications.messages && notifications.messages.length > 0 ? 
                    notifications.messages.slice(0,showAmount).map((notification, index) => {
                    return (
                      <div className="card flex-column justify-content-center align-items-start mb-2" style={{width: '330px', height: '80px'}}>
                          <div className="d-flex justify-content-center align-items-center" 
                                  style={{ marginLeft: '15px', cursor: notification.seen ? 'default' : 'pointer'}}
                              onClick={notification.seen ? null : () => handleNotificationClick(notification.MESSAGE_CODE, notification.correspond_id, notification.notification_id, notification.seen)}>
                              <img
                                  src={notification.sender_profilePic[0] === null ?
                                  "/anon.jpg" :
                                  `data:image/jpeg;base64,${notification.sender_profilePic[0]}`}
                                  style={{width: '40px', height: '40px', borderRadius: '20px', marginBottom: '5px'}}
                              />
                              <div className="flex-column justify-content-center align-items-center" style={{width: '220px'}}>
                                  <span className="fw-semibold" 
                                          style={{fontSize: '.8rem', marginLeft: '8px', lineHeight: '1.1', marginTop: '-1px', display: 'block', color: 'black'}}>
                                      {notification.message}
                                  </span>
                                  <span className="text-black-50 fw-semibold" style={{display: 'block', fontSize: ".7rem", marginLeft: '7px', marginTop: '2px'}}>
                                      {notification.time}
                                  </span>
                              </div>
                              {
                                notification.MESSAGE_CODE.includes("GROUP") ? (
                                  <GrGroup style={{fontSize: '1.4rem', color: 'gray'}}/>
                                ) : notification.MESSAGE_CODE === "POST_COMMENT" ? (
                                  <FaSignsPost style={{fontSize: '1.4rem', color: 'gray'}}/>
                                ) : notification.MESSAGE_CODE === "POST_COMMENT_TAG" ? (
                                  <FaRegCommentDots style={{fontSize: '1.4rem', color: 'gray'}}/>
                                )  : notification.MESSAGE_CODE === "POST_COMMENT_MENTION" ? (
                                  <FaRegCommentDots style={{fontSize: '1.4rem', color: 'gray'}}/>
                                ) : notification.MESSAGE_CODE === "POST_SHARE" ? (
                                  <FaShareSquare style={{fontSize: '1.4rem', color: 'gray'}}/>
                                ) : notification.MESSAGE_CODE === "POST_LIKE" ? (
                                  <AiFillLike style={{fontSize: '1.4rem', color: 'gray'}}/>
                                ) : null
                              }
                              {(!notification.seen) && <div style={{ position: 'relative', top: '-15px', left: '5px', width: '8px', height: '8px', backgroundColor: 'green', borderRadius: '50%' }}></div>}
                          </div>
                      </div>
                )}) : null
                }
                {
                    showGroupModal && (
                    <NotificationModel showModal={showGroupModal} code={msgCode} handleClose={CloseNotificationPopupModal}/>
                    )
                }
                {
                  showPostNotiModel === true ? (
                      <div>
                        <PostNotificationModel showModal={showPostNotiModel} handleClose={closePostNotificationModel} 
                                                id={correspondId} msgCode={messageCode}/>
                      </div>
                    ) : null
                }                

                {
                    loading === false ? (
                        <Button className="mt-2 rounded-1" style={{backgroundColor: 'gray', border: 'gray solid 2px'}}
                            onClick={() => handleClickLoading()}
                            onMouseEnter={(e) => {
                            e.target.style.color = 'black';  // Change font color on hover
                            }}
                            onMouseLeave={(e) => {
                            e.target.style.color = 'white';  // Revert font color when mouse leaves
                            }}>
                            {notifications.messages && showAmount >= notifications.messages.length ? "No More!" : "Load More"}
                        </Button>   
                    ) : (
                        <ClipLoader></ClipLoader>
                    )
                }
                                                  
            </div>        
        </div>
        </>
    );
};

export default NotificationPage;