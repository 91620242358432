import React, { useState, useEffect, useRef } from "react";
import { MdGroups } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Swal from "sweetalert2";

import Header from "../Header";
import APICall from "../../../api";
import CreateGroup from "./Group-create";

const GroupShow = () => {
    // ---------------------------------------------
    // State hooks
    const [groupData, setGroupData] = useState([]); 
    const [joinGroupReq, setJoinGroupReq] = useState([]);
    const [showCreateGroup, setShowCreateGroup] = useState(false);
    const [inGroup, setInGroup] = useState();
    const navigate = useNavigate();

    // ---------------------------------------------
    // API Calling
    const fetchAllGroup = async () => {
        try {
            const contentType = "application/json";
            const endPoint = "Group/getAllGroup";
            const bodyContent = {}
    
            APICall("POST", endPoint, bodyContent, contentType).then(
                response => {
                  if (!response.success && response.message === 'SessionExpired') {
                    localStorage.clear();
                    window.location.href = '/login?sessionExpired=true';
                  }
    
                  if (response.success) {
                    const data = response.data;
                    setGroupData(data);
                  }
                  else {
                    console.error('Failed to get group', response.message);
                    Swal.fire({
                      icon: "error",
                      title: "Get group failed!",
                      text: "Something went wrong, please try again later",
                    });
                  }
                }
              )  
        } catch (error) {
            console.log("An error occured while getting groups");
        }  
    }

    const JoinRequest = async (id) => {
        try {
            const contentType = "application/json";
            const endPoint = "Group/joinGroupRequest";
            const bodyContent = {
                userId : Cookies.get('userId'),
                glid: id
            }
    
            APICall("POST", endPoint, bodyContent, contentType).then(
                response => {
                  if (response.success) {
                    if (!response.success && response.message === 'SessionExpired') {
                      localStorage.clear();
                      window.location.href = '/login?sessionExpired=true';
                    }
      
                    Swal.fire({
                        icon: "success",
                        title: "SUCCESS",
                        text: "Request sent Successfully!",
                      }).then((result) => {
                        if (result.isConfirmed || result.dismiss === Swal.DismissReason.timer) {
                          window.location.reload();
                        }
                      });                
                  }
                  else {
                    console.error('Failed to join group', response.message);
                    Swal.fire({
                      icon: "error",
                      title: "Sent request failed!",
                      text: "Something went wrong, please try again later",
                    });
                  }
                }
              )
        } catch (error) {
            console.log("An error occured while sending join request");
        }
    }

    const checkIsInGroup = async () => {
        try {
            const contentType = "application/json";
            const endPoint = "User/isInGroup";
            const bodyContent = {
                userId : Cookies.get('userId'),
            }
            
            return APICall("POST", endPoint, bodyContent, contentType).then(
                response => {
                  if (!response.success && response.message === 'SessionExpired') {
                    localStorage.clear();
                    window.location.href = '/login?sessionExpired=true';
                  }
    
                  if (response.success) {
                    const data = response.data;
                    setInGroup(data.inGroup);
                    return data.inGroup;
                  }
                  else {
                    console.error('Failed to join group', response.message);
                    Swal.fire({
                      icon: "error",
                      title: "Join group failed!",
                      text: "Something went wrong, please try again later",
                    });
                  }
                }
              )
        } catch (error) {
            console.log("An error occured while validating group");
        }
    }

    const getGroupReq = async () => {
        const contentType = "application/json";
        const endPoint = "User/getGroupReq";
        const bodyContent = {
            userId : Cookies.get('userId'),
        }

        APICall("POST", endPoint, bodyContent, contentType).then(
            response => {
              if (!response.success && response.message === 'SessionExpired') {
                localStorage.clear();
                window.location.href = '/login?sessionExpired=true';
              }

              if (response.success) {
                const data = response.data;
                console.log(data);
                setJoinGroupReq(data);
              }
              else {
                console.error('Failed to validate group request', response.message);
                Swal.fire({
                  icon: "error",
                  title: "Validate group request failed!",
                  text: "Something went wrong, please try again later",
                });
              }
            }
          )
    }

    // ---------------------------------------------
    // Event functions
    const handleJoinRequest = async (event) => {
        const id = event.currentTarget.id;
        const inGroup = await checkIsInGroup();
        if (inGroup) {
            Swal.fire({
                icon: "error",
                title: "Join group failed!",
                text: "You're already in a group!",
              });
              return; 
        }
        
        JoinRequest(id);
    }

    const handleViewGroup = (groupId) => {
        navigate(`/home/group/${groupId}`);
    };

    const handleCloseCreateGroup = () => {
      setShowCreateGroup(false);
    }


    // ---------------------------------------------
    // Styling
    const cardStyle = {
        width: '105%',
        border: 'None'
    }

    const cardImgStyle = {
        height: '220px',
        maxWidth: '100%',
        borderTopRightRadius: '3px',
        borderTopLeftRadius: '3px',
        cursor: 'pointer'
    }

    const colStyle = {
        marginRight: '10px',
        borderRadius: '5px',
        marginBottom: '10px'
    }


    // --------------------------------------------
    // Effect hooks
    useEffect(() => {
        fetchAllGroup().then(() => {
            getGroupReq().then(() => {
              checkIsInGroup();
            }); // This will be called after fetchProfileData completes
          });
      }, []);

    
    // --------------------------------------------
    // Variable
    const chunkArray = (array, chunkSize) => {
        const result = [];
        for (let i = 0; i < array.length; i += chunkSize) {
          result.push(array.slice(i, i + chunkSize));
        }
        return result;
      };
    
      const groupedData = groupData ? chunkArray(groupData, 3) : null;


    return (
    <>
        <Header />
        <body>
        <div className="container" style={{ paddingTop: '6rem', maxWidth: '1300px' }}>
            <div className="d-flex justify-content-start align-items-center">
              <h2>All Group <MdGroups style={{fontSize: "1.6rem", marginBottom: '5px'}}/></h2>
              {
                inGroup === false ? (
                  <button class="custom-btn btn-1 d-flex align-items-center justify-content-center fw-semibold text-black-50"
                    onClick={() => setShowCreateGroup(true)} 
                    style={{marginLeft:'auto' , fontSize: '.7rem', lineHeight: '1.1', border: '2px solid gray'}}>
                    CREATE GROUP
                  </button>
                ) : null
              }
              {
                showCreateGroup ? (
                  <CreateGroup  showModal={showCreateGroup} handleClose={handleCloseCreateGroup}/>
                ) : null
              }
            </div>
            {groupData!== null && joinGroupReq && groupedData.map((row, rowIndex) => (
                <div key={rowIndex} className="row d-flex align-items-center justify-content-center">
                  {row.map((cardData, index) => (
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3" style={colStyle}>
                        <div className="card" style={cardStyle}>
                            <img 
                                src={
                                    cardData.groupBgPicture === null ?
                                    "/default_background.png" :
                                    `data:image/jpeg;base64,${cardData.groupBgPicture}`
                                }
                                style={cardImgStyle}
                                onClick={() => handleViewGroup(cardData.groupId)}
                            />
                            <div className="d-flex align-items-center justify-content-center">
                                <div className="d-flex align-items-center justify-content-center" style={{marginRight: 'auto', marginLeft: '7px'}}>
                                    <img 
                                        className="rounded-5"
                                        src={
                                            cardData.leader_profilePic === null ?
                                            "/anon.jpg" :
                                            `data:image/jpeg;base64,${cardData.leader_profilePic}`                                        
                                        }
                                        style={{width: '40px', height: '40px'}}
                                    />
                                    <div className="d-flex flex-column justify-content-center" style={{marginTop: '10px', marginLeft: '5px'}}>
                                        <h5 style={{marginBottom: '-2px', fontSize: '.85rem'}}>{cardData.groupName}</h5>
                                        <p className="text-black-50 fw-semibold" style={{fontSize: '.75rem'}}>{cardData.leader_firstName + cardData.leader_lastName} (Leader)</p>
                                    </div>
                                </div>
                                {joinGroupReq.some(innerArray => innerArray.includes(cardData.groupId)) ?
                                    (
                                        <button className="rounded-3 text-black-50" id={cardData.groupId + "/" + cardData.leader_id}
                                            style={{height: '35px', background: 'None', border: '1px double gray', width: '90px', marginRight: '3px', fontSize: '.8rem', cursor: 'default'}}>
                                            Requested
                                        </button>
                                    ) : (
                                        <button className="rounded-3 text-black-50" id={cardData.groupId + "/" + cardData.leader_id} onClick={handleJoinRequest}
                                            style={{height: '35px', background: 'None', border: '1px double gray', width: '90px', marginRight: '3px', fontSize: '.8rem'}}>
                                            Request
                                        </button>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                  ))}
                </div>
            ))}
        </div>
        </body>
    </>
    );
};

export default GroupShow;
