import "../../../assets/css/searchBar.css";
import { SearchResult } from "./SearchResult";

export const SearchResultsList = ({ results }) => {
  return (
    <div className="results-list">
      {results && results.map((result, id) => {
        return <SearchResult name={result.first_name+result.last_name} profilePic={result.profilePic} userId={result.uid} />;
      })}
    </div>
  );
};