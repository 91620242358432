import "../../../assets/css/searchBar.css";
import { useNavigate } from "react-router-dom";

export const SearchResult = ({ name, profilePic, userId }) => {
  const navigate = useNavigate();

  const redirectViewProfile = (directUid) => {
    navigate(`/home/profile/${directUid}`);
  }

  return (
    <div className="search-result d-flex align-items-center justify-content-start" id={userId} onClick={(() => redirectViewProfile(userId))}
         style={{cursor: 'pointer'}}>
      <img 
      style={{width: '40px', height: '40px', borderRadius: '20px'}}
        src={profilePic === null ?
          "/anon.jpg" :
          `data:image/jpeg;base64,${profilePic}`}
      />
      <p className="text-black-50, fw-semibold" style={{fontSize: '.9rem', marginLeft: '10px', marginTop: '10px'}}>{name}</p> 
    </div>
  );
};
