import React, { useState, useEffect, useRef } from "react";
import { mdiEarth, mdiBriefcase, mdiGoogleMaps} from "@mdi/js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GoTriangleDown } from "react-icons/go";
import { useParams } from 'react-router-dom';
import { Dropdown } from "react-bootstrap";
import { HiOutlineMail } from "react-icons/hi";
import { FiPhone } from "react-icons/fi";
import { GiWorld } from "react-icons/gi";
import { MdOutlineDriveFileRenameOutline } from "react-icons/md";
import { RiLockPasswordFill } from "react-icons/ri";
import { faBoxOpen } from '@fortawesome/free-solid-svg-icons';
import { BsBoxSeamFill } from "react-icons/bs";
import { Button } from 'react-bootstrap';
import { Modal } from "react-bootstrap";
import { MdUnfoldMore } from "react-icons/md";
import Skeleton from 'react-loading-skeleton';
import PlaceholderLoading from 'react-placeholder-loading';

import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import Icon from "@mdi/react";

import ClipLoader from "react-spinners/ClipLoader";
import BeatLoader from "react-spinners/BeatLoader";
import UpdateProfile from "./UpdateProfile";
import Request from "./Request";
import PostLazyLoader from "../Post/PostLazyLoad";
import APICall from "../../../api";
import HeaderLazyLoad from "../HeaderLazyLoad";
import { saveImageDataToLocalStorage } from "../../Login/login";

import 'react-loading-skeleton/dist/skeleton.css';
import 'sweetalert2/src/sweetalert2.scss';


const Profile = () => {
  // -------------------------------------------------------
  // State hooks
  const { viewUid } = useParams();
  const [postsData, setPosts] = useState(null);
  const fileInputRef = useRef(null);
  let [profileChanged, setProfileChanged] = useState(false);
  const [profilePicSrc, setProfilePicSrc] = useState(null);
  const [backgroundPicSrc, setBackgroundPicSrc] = useState(null);
  const [profileSettings, setProfileSettings] = useState(null);
  const [profileType, setProfileType] = useState(null);
  const [showRequestDropdown, setshowRequestDropdown] = useState(false);
  const [showUpdateDropdown, setShowUpdateDropdown] = useState(false);
  const [showRequestModal, setShowRequestModal] = useState(false);
  const [showUpdateModal, setshowUpdateModal] = useState(false);
  const [requestType, setRequestType] = useState('');
  const [updateType, setUpdateType] = useState('');
  const [profilePhotos, setProfilePhotos] = useState(null);
  const [showAmount, setShowAmount] = useState(5);
  const [phoShowAmount, setPhoShowAmount] = useState(2);
  const [openImg, setOpenImg] = useState(false);
  const [selectedImg, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [phoLoading, setPhoLoading] = useState(false);
    // ---------------
    // variable
    const isView = viewUid !== undefined ? (Cookies.get("userId") === viewUid ? true : false) : true;

  // -------------------------------------------------------
  // Event functions  
  const handleButtonClick = (type) => {
    setProfileType(type);
    fileInputRef.current.click();
  };

  const openRequestModal = (req) => {
    setRequestType(req);
    setShowRequestModal(true);
  };

  const closeRequestModal = () => {
    setShowRequestModal(false);
  };

  const openUpdateProfileModal = (settings) => {
    setUpdateType(settings);
    setshowUpdateModal(true);
  }

  const closepdateProfileModal = () => {
    setshowUpdateModal(false);
  }

  const handleClickLoading = () => {
    setLoading(true);

    setShowAmount(prevAmount => prevAmount + 5);
    setTimeout(() => {
        setLoading(false);
    }, 500);
  }

  const chunkPhotoFunc = (posts) => {
    if (!posts) {
      return;
    }

    let checkId = viewUid !== undefined ? viewUid : Cookies.get('userId')

    let chunkPhotos = [];
    let chunkPhoto = [];
    for (let i = 0; i < posts.length; i++) {
      for (let j = 0; j < posts[i].image_data.length; j++) {
        if (chunkPhoto.length === 3) {
          chunkPhotos.push([...chunkPhoto]);
          chunkPhoto = [];
        }
        
        if (posts[i].image_data[j] !== null && parseInt(posts[i].userID) === parseInt(checkId)) {
          chunkPhoto.push([posts[i].image_data[j], posts[i].mediaType]);
        }
      }
    }
          
    if (chunkPhoto.length > 0) {
      chunkPhotos.push([...chunkPhoto]);
    }
    return chunkPhotos;
  }

  const openImgModal = (imageData) => {
    setSelectedImage(imageData);
    setOpenImg(true);
  };

  const closeImgModal = () => {
    setOpenImg(false);
    setSelectedImage('');
  };

  const handleClickLoadingPhotos = () => {
    setPhoShowAmount(prevAmount => prevAmount + 5);
    setPhoLoading(true);

    setTimeout(() => {
      setPhoLoading(false);
    }, 1000);
  }

  // -------------------------------------------------------
  // API Calls
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!/\.(jpg|jpeg|png)$/i.test(file.name)) {
      // Invalid image file extension
      Swal.fire({
        icon: "error",
        title: "Invalid File",
        text: "Please select only image files (JPEG, JPG, PNG).",
      });
      return;
    }


    const reader = new FileReader();
  
    reader.onload = (event) => {
      const imageData = event.target.result.split(',')[1];
      const imageSizeInBytes = imageData.length * 3 / 4; // 3/4 ratio for base64 encoding
      const maxSizeInBytes = 1024 * 1024; // 1 MB

      if (imageSizeInBytes > maxSizeInBytes) {
        Swal.fire({
          icon: 'warning',
          title: 'File too big!',
          text: 'Image upload size limit is 1MB!',
        }).then((result) => {
          if (result.isConfirmed || result.dismiss === Swal.DismissReason.timer) {
            return;
          }
        });
        return;
      }

      const xhr = new XMLHttpRequest();
      xhr.open('POST', 'https://www.jungp.online/api/v1/User/uploadProfilePic', true);
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('Access-Token', `Bearer ${Cookies.get("accessToken")}`);
      xhr.setRequestHeader('Refresh-Token', Cookies.get("refreshToken"));
  
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            Swal.fire({
              icon: 'success',
              title: 'Profile updated successful!',
              text: 'Success!',
            }).then(() => {
              if (profileType === 'pp') {
                saveImageDataToLocalStorage(imageData);
              }
              window.location.reload();
            });
  
          } else {
            const response = JSON.parse(xhr.responseText);
            // Fire alert for fail
            Swal.fire({
              icon: 'error',
              title: 'Update failed!',
              text: "Something went wrong, please try again later"
            });
          }
        }
      };
  
      xhr.send(JSON.stringify({
        image_data: imageData,
        userId: Cookies.get('userId'),
        attrib: profileType,
      }));
    };
  
    reader.readAsDataURL(file);
  };

  const fetchProfileData = async () => {
    try {
      const contentType = "application/json";
      const endPoint = "User/retrieveProfile";
      const bodyContent = {
        "userId" : viewUid !== undefined ? viewUid : Cookies.get('userId'),
      }

      APICall("POST", endPoint, bodyContent, contentType).then(
        response => {
          if (!response.success && response.message === 'SessionExpired') {
            localStorage.clear();
            window.location.href = '/login?sessionExpired=true';
          }

          if (response.success) {
            const profileData = response.data;
            const { profilePic, bgPic, ...restOfProfileData } = profileData;
            setProfilePicSrc(profilePic);
            setBackgroundPicSrc(bgPic);
            setProfileSettings({ ...profileSettings, ...restOfProfileData });
          }
          else {
          // Fire alert for fail
            Swal.fire({
              icon: "error",
              title: "Post publishes failed!",
              text: "Something went wrong, please try again later"
            });
          }
        }
      )      

    } catch (error) {
      console.log("An error occured while publishing post", error);
    }
  };
  
  const fetchPostData = async () => {
    try {
      const contentType = "application/json";
      const endPoint = "User/getUserPost";
      const bodyContent = {
        userId : viewUid !== undefined ? viewUid : Cookies.get('userId'),
        viewId : viewUid !== Cookies.get('userId') ? Cookies.get('userId') : viewUid,
        showAmount : showAmount
      }

      APICall("POST", endPoint, bodyContent, contentType).then(
        response => {
          if (!response.success && response.message === 'SessionExpired') {
            localStorage.clear();
            window.location.href = '/login?sessionExpired=true';
          }

          if (response.success) {
            console.log(response.data)
            const data = response.data;
            setProfilePhotos(chunkPhotoFunc(data));
            setPosts(data);
          }
          else {
            console.error('Failed to get post', response.message);
            Swal.fire({
              icon: "error",
              title: "Get post failed!",
              text: "Something went wrong, please try again later"
            });
          }
        }
      )    
    } catch (error) {
      console.error('An error occurred while fetching post data', error);
    }
  };
  

  // -------------------------------------------------------
  // Effect hooks
  useEffect(() => {
    fetchProfileData().then(() => {
      fetchPostData();
    });
  }, [viewUid]);

  useEffect(() => {
    if (showAmount > 5) {
      fetchPostData();
    }
  }, [showAmount]);
  
  // For profile changing
  useEffect(() => {
    let checkCount = 0;
    const checkProfileChanged = () => {
      if (profileChanged === false && checkCount < 20) {
        setTimeout(() => {
          checkCount++;
          checkProfileChanged();
        }, 1000); 
      } else if (profileChanged === true) {
        fetchProfileData();
        setProfileChanged(false);
      }
    };
    checkProfileChanged();
  }, [profileChanged]);


  // -------------------------------------------------------
  // Styling
  const buttonStyle = {
    marginRight: '10px',
    width: '12%',
    height: '40px',
    backgroundColor: 'white',
    fontSize: '.8rem',
    cursor: 'pointer',
    zIndex: 1000
  };

  const cardList = {
    height: "3.7rem",
  };

  const cardIcon = {
    marginLeft: "auto", 
    marginTop: "-35px"
  }; 

  return (
    <>
    <HeaderLazyLoad />
      <div style={{backgroundColor: '#F2F3F5', paddingTop: '3.9rem'}}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12 mb-3" style={{ marginLeft: "6%" }}>
              <div
                className="card text-center border-0"
                style={{ width: "90%" }}
              >
                <img
                  src={
                    backgroundPicSrc === null ?
                    "/default_background.png" :
                    `data:image/jpeg;base64,${backgroundPicSrc}`
                  }
                  className="img-fluid rounded"
                  style={{ width: "100%", height: "20rem" }}
                  alt="Banner"
                />
                <div className="card-body" style={{ background: '#F2F3F5', border: 'none' }}>
                  <img
                    src={
                      profilePicSrc === null ?
                      "/anon.jpg" :
                      `data:image/jpeg;base64,${profilePicSrc}`
                    }
                    style={{ width: "80px", height: "80px", marginTop: "-6%" }}
                    className="rounded-circle"
                    alt="Coder"
                  />
                  <div style={{ marginLeft: '10px', display: 'flex', flexDirection: 'column' }}>
                  {
                    isView && isView === true ? (
                      <>
                      <div className="mt-2" style={{ display: 'flex', alignItems: 'center', marginLeft: '-26px' }}>
                        <input
                          type="file"
                          ref={fileInputRef}
                          style={{ display: 'none' }}
                          onChange={handleFileChange}
                        />                      
                        <button className="rounded-4 border text-black-50 fw-bold" style={buttonStyle} onClick={() => handleButtonClick('pp')}>Profile Picture</button>
                        <button className="rounded-4 border text-black-50 fw-bold" style={buttonStyle} onClick={() => handleButtonClick('bg')}>Background</button>
                        <input
                          type="file"
                          ref={fileInputRef}
                          style={{ display: 'none' }}
                          onChange={handleFileChange}
                        />         
  
                        {/* Update Settings  */}
                        <div style={{ position: 'relative', marginRight: '2%'}}>
                          <button className="rounded-4 border text-black-50 fw-bold d-flex align-items-center justify-content-center" onClick={() => setShowUpdateDropdown(!showUpdateDropdown)}
                                  style={{...buttonStyle, width: '110%'}}>Update Settings <GoTriangleDown style={{fontSize: '1.3rem', marginLeft: '4px'}}/>
                          </button>
                          <Dropdown
                              className="form-group mb-2 justify-content-between align-items-center mt-2" 
                              style={{border: '0px', position: 'absolute', marginLeft: '-60px'}}
                              show={showUpdateDropdown}
                            >
                              <Dropdown.Menu style={{width:'135px', minWidth: '110px'}}>
                                <Dropdown.Item style={{height: '30px', paddingTop: '0px'}} onClick={() => openUpdateProfileModal("Work")}>
                                  <div className="d-flex align-items-center">
                                    <span className="quantity ml-3 text-black-50 flex-grow-1" style={{fontWeight: 500}}>Work At</span>
                                    <Icon path={mdiBriefcase} size={0.8} color="gray"/>
                                  </div>
                                </Dropdown.Item>
                                <Dropdown.Item style={{height: '35px', borderTop: '1px solid #ccc'}} onClick={() => openUpdateProfileModal("Location")}>
                                  <div className="d-flex align-items-center">
                                    <span className="quantity ml-3 text-black-50 flex-grow-1" style={{fontWeight: 500}}>Live in</span>
                                    <Icon path={mdiGoogleMaps} size={0.8} color="gray"/>
                                  </div>
                                </Dropdown.Item>                            
                                <Dropdown.Item style={{height: '35px', borderTop: '1px solid #ccc'}} onClick={() => openUpdateProfileModal("Password")}>
                                  <div className="d-flex align-items-center">
                                    <span className="quantity ml-3 text-black-50" style={{fontWeight: 500}}>Password</span>
                                    <div style={{marginLeft: '22px'}}>
                                      <RiLockPasswordFill style={{color: 'gray', fontSize: '1.05rem'}}/>
                                    </div>
                                  </div>
                                </Dropdown.Item>                                                                                     
                              </Dropdown.Menu>
                          </Dropdown>
                          {showUpdateModal && (
                            <UpdateProfile showModal={showUpdateModal} handleClose={() => closepdateProfileModal()} settings={updateType}/>
                          )}                        
                        </div>               
                        {/* Update Settings Ends */}      
                                            
                        {/* Request Modal */}
                        <div style={{ position: 'relative', marginLeft:'auto', marginRight: '2%'}}>
                          <button className="border text-black-50 fw-bold d-flex align-items-center justify-content-center" onClick={() => setshowRequestDropdown(!showRequestDropdown)}
                                  style={{...buttonStyle, width: '140%'}}>Request <GoTriangleDown style={{fontSize: '1.3rem', marginLeft: '4px'}}/>
                          </button>
                          <Dropdown
                              className="form-group mb-2 justify-content-between align-items-center mt-2" 
                              style={{border: '0px', position: 'absolute', marginLeft: '-48px'}}
                              show={showRequestDropdown}
                            >
                              <Dropdown.Menu style={{width:'135px', minWidth: '110px'}}>
                                <Dropdown.Item style={{height: '30px', paddingTop: '0px'}} onClick={() => openRequestModal("Email")}>
                                  <div className="d-flex align-items-center">
                                    <span className="quantity ml-3 text-black-50 flex-grow-1" style={{fontWeight: 500}}>Email</span>
                                    <HiOutlineMail className="ml-auto"/>
                                  </div>
                                </Dropdown.Item>
                                <Dropdown.Item style={{height: '35px', borderTop: '1px solid #ccc'}} onClick={() => openRequestModal("Phone")}>
                                  <div className="d-flex align-items-center">
                                    <span className="quantity ml-3 text-black-50 flex-grow-1" style={{fontWeight: 500}}>Phone</span>
                                    <FiPhone className="ml-auto"/>
                                  </div>
                                </Dropdown.Item>                            
                                <Dropdown.Item style={{height: '35px', borderTop: '1px solid #ccc'}} onClick={() => openRequestModal("Country")}>
                                  <div className="d-flex align-items-center">
                                    <span className="quantity ml-3 text-black-50 flex-grow-1" style={{fontWeight: 500}}>Country</span>
                                    <GiWorld className="ml-auto" />
                                  </div>
                                </Dropdown.Item>      
                                <Dropdown.Item style={{height: '30px', borderTop: '1px solid #ccc', paddingBottom: '0px'}} onClick={() => openRequestModal("Name")}>
                                  <div className="d-flex align-items-center">
                                    <span className="quantity ml-3 text-black-50 flex-grow-1" style={{fontWeight: 500}}>Name</span>
                                    <MdOutlineDriveFileRenameOutline className="ml-auto" />
                                  </div>
                                </Dropdown.Item>                                                                                  
                              </Dropdown.Menu>
                          </Dropdown>
                          {showRequestModal && (
                            <Request showModal={showRequestModal} handleClose={() => closeRequestModal()} reqType={requestType}/>
                          )}                        
                        </div>
                        {/* Request Modal Ends */}
                      </div>
                      <div style={{marginTop: '-35px'}}>
                        <h5 className="card-title" style={{ margin: 0 }}>
                          {profileSettings ? `${profileSettings.first_name} ${profileSettings.last_name}` : 'Loading...'}
                        </h5>
                        {/* <p className="card-text text-black-50" style={{fontSize: '.8rem'}}>{Cookies.get("is_admin") === '1' ? "Admin" : "User"}</p> */}
                      </div>
                      </>    
                    ) : (
                      <div className="mt-3">
                        <h5 className="card-title" style={{ margin: 0 }}>
                          {profileSettings ? `${profileSettings.first_name} ${profileSettings.last_name}` : 'Loading...'}
                        </h5>
                        {/* <p className="card-text text-black-50" style={{fontSize: '.8rem'}}>{Cookies.get("is_admin") === '1' ? "Admin" : "User"}</p> */}
                      </div>
                    )
                  }  
                  </div>
                </div>
              </div>
            </div>

            {/* Bottom part */}
            <div className="row">
              {/* Bottom part left panel*/}
              {/* Left Panel Basic Info*/}
              <div className="col-lg-4" style={{ marginLeft: "6%", width: "30%" }}>
                <div className="card mb-2 rounded-4">
                  <div className="card-body">
                    <h5 className="card-text">Basic Infos</h5>
                  </div>
                </div>
                <div className="card mb-2">
                  {
                    profileSettings ? (
                      <ul className="list-group list-group-flush rounded-4">
                      <li className="list-group-item d-flex flex-column align-items-start" style={cardList}>
                        <span className="fw-semibold" style={{ marginBottom: "-1%" }}> Live in </span>
                        <span className="text-black-50"> {profileSettings.location === null ? "No record yet" : profileSettings.location} </span>
                        <Icon path={mdiGoogleMaps} size={1} style={cardIcon} color="gray"/>
                      </li>
                      <li className="list-group-item d-flex flex-column align-items-start" style={cardList}>
                        <span className="fw-semibold" style={{ marginBottom: "-1%" }}> Work At</span>
                        <span className="text-black-50">{profileSettings.work === null ? "No record yet" : profileSettings.work}</span>
                        <Icon path={mdiBriefcase} size={1} style={{ marginLeft: "auto", marginTop: "-35px", width: "10%",}} color="gray"/>
                      </li>
                      <li className="list-group-item d-flex flex-column align-items-start" style={cardList}>
                        <span className="fw-semibold" style={{ marginBottom: "-1%" }}> Orginally From </span>
                        <span className="text-black-50">{profileSettings.country === null ? "No record yet" : profileSettings.country}</span>
                        <Icon path={mdiEarth} size={1} style={{ marginLeft: "auto", marginTop: "-35px", width: "10%",}} color="gray"/>
                      </li>
                      <li className="list-group-item d-flex flex-column align-items-start" style={cardList}>
                        <span className="fw-semibold" style={{ marginBottom: "-1%" }}> Phone Number </span>
                        <span className="text-black-50">{profileSettings.phone === null ? "No record yet" : profileSettings.phone}</span>
                        <FiPhone style={{ marginLeft: "auto", marginTop: "-35px", width: "10%", fontSize: '1.3rem'}} color="gray"/>
                      </li>                      
                    </ul>
                    ) : <span>Loading....</span>
                  }
                </div>
                {/* Left Panel Basic Info Ends*/}

                {/* Left Panel Photos*/}
                <div className="card mb-2 rounded-4" >
                  <div className="card-body">
                    <div className="d-flex">
                      <h5 className="card-text">Photos</h5>
                      <MdUnfoldMore style={{fontSize: '1.3rem', marginTop: '3px', cursor: 'pointer'}} onClick={handleClickLoadingPhotos}/>
                      {phoLoading ? (
                        <BeatLoader color="black" size={6} style={{marginTop: '1px'}}/>
                      ) : null }
                    </div>
                    {
                      profilePhotos && profilePhotos.length > 0 ? (
                        <div>
                          {profilePhotos.slice(0, phoShowAmount).map((photoRow, rowIndex) => (
                            <div className="row" style={{marginBottom: '-10px'}} key={"photo-row" + rowIndex}>
                              {photoRow.map((pho, photoIndex) => (
                                <div className="col-4 p-1" key={"inner-photo" + photoIndex}>
                                  <a href="#" onClick={() => openImgModal(`data:image/jpeg;base64,${pho[0]}`)}>
                                    <img src={`data:image/jpeg;base64,${pho[0]}`} style={{width: '130px', height: '90px'}} alt="img" className="img-fluid rounded-1 my-1"/>
                                  </a>  
                                  <Modal show={openImg} onHide={closeImgModal}>
                                    <Modal.Header>
                                      <Modal.Title style={{marginLeft: '10px', marginTop: '10px'}}>Image Preview <br></br> 
                                        <p className="fw-semibold" style={{fontSize: '.8rem'}}>Posted in page: {pho[1]}</p>
                                      </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                      {selectedImg && (
                                        <img className="rounded-3" src={selectedImg} style={{width: '100%'}} alt="img"/>
                                      )}
                                    </Modal.Body>
                                    <Modal.Footer>
                                      <button className="btn btn-secondary" onClick={closeImgModal}>Close</button>
                                    </Modal.Footer>
                                  </Modal>
                                </div>
                              ))}
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="d-flex align-items-center justify-content-center">
                          <BsBoxSeamFill style={{marginRight: '10px', fontSize: '30px', color: 'gray'}}/>
                          <span className="text-black-50" style={{fontSize: '.9rem'}}>No Picture(s) Yet!</span>
                        </div>
                      )
                    }
                  </div>
                </div>

                {/* Left Panel Photos Ends*/}

              </div>
              {/* Basic Info and Bottom part left panel Ends*/}

              {/* Bottom part right panel*/}
              {/* Post content */}
              <div className="col-lg-7" style={{ marginLeft: "1%", width: "61%" }}>
                <div className="card mb-2 rounded-4"  style={{width: '100%'}}>
                  <div className="card-body">
                    <h5 className="card-text" >Posts</h5>
                  </div>
                </div>

                <div className="row" style={{width: '90%', marginLeft: '2px'}}>
                {/* call post api  */}
                {postsData && postsData.length > 0 ? (
                 <>
                 {postsData.slice(0, showAmount).map((post, index) => {
                    return (
                      <React.Fragment key={post.postID + '-' + index + '-' + post.userID}>
                        {post.sharePostID !== null && post.userID === parseInt(viewUid !== undefined ? viewUid : Cookies.get('userId')) ? 
                          (
                          postsData
                            .filter(sharedPost => sharedPost.postID === post.sharePostID)
                            .map((sharedPost, index) => {
                              while (sharedPost.sharePostID !== null) {
                                sharedPost = postsData.find((nestSharePost) => sharedPost.sharePostID === nestSharePost.postID);
                              }
                              // Append or modify properties in sharedPost
                              post.oriPostUserId = sharedPost.userID;
                              post.oriPostUserfn = sharedPost.first_name;
                              post.oriPostUserln = sharedPost.last_name;
                              post.oriPostProfilePic = sharedPost.profilePic;
                              post.oriPostContent = sharedPost.content;
                              post.oriPostImageData = sharedPost.image_data;
                              post.oriPostPostTime = sharedPost.postTime;
                              post.oriMediaType = sharedPost.mediaType;
                              post.oriIsAdmin = sharedPost.is_admin;
                              post.oriMediaType = sharedPost.mediaType;

                              return (
                                <React.Fragment key={post.sharePostID + '-' + post.postID + '-' + index}>
                                  <PostLazyLoader key={post.sharePostID  + '-' +  post.postID  + '-' +  index} post={post}/>
                                  {/* <PostContent key={post.sharePostID  + '-' +  post.postID  + '-' +  index} {...post} /> */}
                                </React.Fragment>
                              );
                            })
                          ) : post.userID === parseInt(viewUid !== undefined ? viewUid : Cookies.get('userId')) ? (
                              <React.Fragment key={post.postID  + '-' + index + "aaaa"}>
                                <PostLazyLoader key={post.postID  + '-' +  index} post={post}/>
                                {/* <PostContent key={post.postID  + '-' +  index} {...post} /> */}
                              </React.Fragment>
                          ) : null
                        }                   
                      </React.Fragment>
                    );
                  })}
                  {
                    loading === false ? (
                      <div className="d-flex align-items-center justify-content-center mb-5">
                        <Button className="mt-2 rounded-1" 
                            style={{backgroundColor: 'gray', border: 'gray solid 2px', width:'130px'}}
                            onClick={() => handleClickLoading()}
                            onMouseEnter={(e) => {
                            e.target.style.color = 'black';  // Change font color on hover
                            }}
                            onMouseLeave={(e) => {
                            e.target.style.color = 'white';  // Revert font color when mouse leaves
                            }}>
                            {postsData && postsData.length < showAmount ? "No More Post!" : (
                                "Load More"
                            )}
                        </Button>   
                      </div>
                    ) : (
                      <div className="d-flex align-items-center justify-content-center">
                          <ClipLoader></ClipLoader>
                        </div>
                    )
                  }   
                  </>
                 ) : postsData && postsData.length <= 0 ? (
                  <div className="d-flex align-items-center justify-content-center mt-2">
                    <div className="flex-column align-items-center justify-content-center">
                      <span className="text-black-50 fw-semibold" style={{fontSize: '1.3rem', display: 'block'}}>No Post Yet!</span>
                      <FontAwesomeIcon icon={faBoxOpen} style={{fontSize: '5rem', display: 'block', color: 'gray', marginLeft: '8px'}}/>
                    </div>
                  </div>
                 ) : postsData === null && (
                  <>
                  <div className='mb-4'>
                    <div className='d-flex align-items-center mb-3'>
                      <PlaceholderLoading shape="circle" width={40} height={40} />
                      <div className='flex-column' style={{marginLeft: '10px'}}>
                        <PlaceholderLoading shape="rect" width={340} height={15} />
                        <PlaceholderLoading shape="rect" width={250} height={15} />
                      </div>
                    </div>
                    <Skeleton count={4} height={15}/>      
                  </div>

                  <div className='mb-4'>
                    <div className='d-flex align-items-center mb-3'>
                      <PlaceholderLoading shape="circle" width={40} height={40} />
                      <div className='flex-column' style={{marginLeft: '10px'}}>
                        <PlaceholderLoading shape="rect" width={340} height={15} />
                        <PlaceholderLoading shape="rect" width={250} height={15} />
                      </div>
                    </div>
                    <Skeleton count={4} height={15}/>      
                  </div>
                  </>
                 )
                }
                </div>

              </div>
              {/* Post content and right panel end here*/}
            </div>
            {/* Bottom part ends here */}

          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
