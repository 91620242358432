import React, { useState, useEffect, useRef  } from "react";
import Header from "../Header";
import Swal from "sweetalert2";
import { MdOutlineDateRange } from "react-icons/md";
import { IoTicketSharp } from "react-icons/io5";
import Cookies from "js-cookie";
import { Button, Modal } from "react-bootstrap";

import $ from "jquery";
import "datatables.net-bs4/css/dataTables.bootstrap4.css";
import "datatables.net";
import "datatables.net-bs4";

import "../../../assets/css/Client.css";
import "sweetalert2/src/sweetalert2.scss";

import APICall from "../../../api";

const AdminTicket = () => {
  // ---------------------------------------------------------------------
  // State hooks
  const [ticketList, setTicketList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState('');

  // ---------------------------------------------------------------------
  // API Calling
  const retrieveTicket = async () => {
    try {
      const contentType = "application/json";
      const endPoint = "Admin/getAllTickets";
      const bodyContent = {
        role : Cookies.get("is_admin") === '1' ? 'admin' : 'member',
        notify : false
      }

      APICall("POST", endPoint, bodyContent, contentType).then((response) => {
        if (!response.success && response.message === 'SessionExpired') {
          localStorage.clear();
          window.location.href = '/login?sessionExpired=true';
        }

        if (response.success) {
          const data = response.data;
          setTicketList(data);
        } else {
          console.error("Failed to get tickets", response.message);
          Swal.fire({
            icon: "error",
            title: "Get user list failed!",
            text: "Something went wrong, please try again later"
          });
        }
      });
    } catch (error) {
      console.log("An error occured while retrieving tickets", error);
    }
  };

  const updateTicket = async (approve, ticket) => {
    try {
        const contentType = "application/json";
        const endPoint = "Admin/setTicketApproval";
        const bodyContent = {
          approve: approve,
          ticket : ticket
        }
        
        APICall("POST", endPoint, bodyContent, contentType).then((response) => {
          if (!response.success && response.message === 'SessionExpired') {
            localStorage.clear();
            window.location.href = '/login?sessionExpired=true';
          }

            if (response.success) {
                Swal.fire({
                    icon: "success",
                    title: "SUCCESS",
                    text: "Update Successfully!",
                  }).then((result) => {
                    if (result.isConfirmed || result.dismiss === Swal.DismissReason.timer) {
                      window.location.reload();
                    }
                  });                
            } else {
              console.error("Failed to update tickets", response.message);
              Swal.fire({
                icon: "error",
                title: "Update ticket failed!",
                text: "Something went wrong, please try again later"
              });
            }
          });        
    } catch (error) {
        console.log("An error occured while updating tickets", error);
    }
  }

  // -------------------------------------------------------------------
  // Effect hooks
  useEffect(() => {
    retrieveTicket();
  }, []);


  // -------------------------------------------------------------------
  // Event functions
  const handleViewContent = (row) => {
    setSelectedRow(row);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleApproval = (approve, ticket) => {
    updateTicket(approve, ticket);
    setShowModal(false);
  }

  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  var currentItems = ticketList ? ticketList.slice(indexOfFirstItem, indexOfLastItem) : 0;
  
  
  // -------------------------------------------------------------------
  // Reference states
  const tableRef = useRef(null);

  useEffect(() => {
    // Initialize DataTable when the component mounts
    if ($.fn.DataTable.isDataTable($(tableRef.current))) {
      $(tableRef.current).DataTable().destroy();
    }

    $(tableRef.current).DataTable({
      data: currentItems,
      columns: [
        { data: 'reqId', title: 'Request Id' },
        { data: 'userId', title: 'User Id' },
        {
            data: null,
            title: 'Name',
            render: function(data, type, row) {
              // Combine first_name and last_name
              return row.first_name + ' ' + row.last_name;
            },
        },        
        { 
          data: 'created_at', 
          title: 'Request Made Date',
          render: function(data, type, row) {
            // Format the date if needed
            if (type === 'display') {
              return new Date(data).toUTCString();
            }
            return data;
          }
        },
        { data: 'reqType', title: 'Request Type' },        
        { 
            data: 'action',
            title: 'Status',
            render: function (data, type, row) {
              // Customize the displayed content based on the 'approved' value
              if (data === 0) {
                return '<span class="pending" ' +
                'style="background: #fdf5dd; color: #cfa00c; position: relative; border-radius: 30px; padding: 3px 10px 4px 25px; font-weigth: 500;" >' +
                'Pending<span class="waiting" style="background: #f2be1d; position: absolute; margin-top: 6px; margin-left: 5px; top: 0; left: 0; bottom: 0; width: 12px; height: 12px; border-radius: 30px;"></span></span>';
              } else if (data === 1) {
                return '<span class="complete" ' +
                'style="background: #cff6dd; color: #1fa750; position: relative; border-radius: 30px; padding: 3px 10px 4px 25px; font-weigth: 500;" >' +
                'Complete<span class="waiting" style="background: #23bd5a; position: absolute; margin-top: 6px; margin-left: 5px; top: 0; left: 0; bottom: 0; width: 12px; height: 12px; border-radius: 30px;"></span></span>';
              }
            }
        },
        { 
            data: 'approved',
            title: 'RESULT',
            render: function (data, type, row) {
              const action = row.action; // Access the 'action' property
              if (action === 0) {
                return '<span style="color: #cfa00c; font-weight: 700;">WAITING</span>';
              }
              else {
                if (data === 0) { 
                    return '<span style="color: red; font-weight: 700;">REJECTED</span>';
                } else if (data === 1) {
                    return '<span style="color: green; font-weight: 700;">APPROVED</span>';
                }
              } 
            }
        },
        {
            data: 'reqId',
            title: 'REQUEST CONTENT',
            render: function (data, type, row) {
                const status = row.action;
                if (status === 0) {
                    return `<button class="btn fw-semibold view-content-btn" data-reqid="${data}" style="padding-top: 0rem;">VIEW</button>`;
                } else {
                    return `<button class="btn fw-semibold" data-reqid="${data}" style="padding-top: 0rem;">FINISHED</button>`;
                }
              }            
        }
      ],
      order: [[0, 'desc'], [3, 'desc']],
      paging: false,
      info: false
    });

    // Add event delegation for the button click
    $(tableRef.current).on('click', '.view-content-btn', function () {
        const reqId = $(this).data('reqid');
        const selectedRow = currentItems.find(item => item.reqId === reqId);
        handleViewContent(selectedRow);
      });    
  }, [currentItems]);

  return (
    <>
      <Header />

      <div className="content">
        <div className="container">
          <h2 className="mb-4">
            ALL TICKET LIST{" "}
            <IoTicketSharp  style={{ marginTop: "-6px", fontSize: "1.6rem" }} />
          </h2>

          <div className="mb-n2 d-flex justify-content-end" style={{marginTop: '-20px'}}>
          </div>
          <div className="table-responsive" style={{ marginBottom: "-23px" }}>
            <table className="table table-striped custom-table table-sm" ref={tableRef}>
              <thead>
                <tr>
                  <th scope="col">Request Id</th>
                  <th scope="col">User Id</th>
                  <th scope="col">Name</th>
                  <th scope="col">Request Made Date</th>
                  <th scope="col">Request Type</th>
                  <th scope="col">Status</th>
                  <th scope="col">Result</th>
                  <th scope="col">Content</th>
                </tr>
              </thead>
              <tbody>
                {/* {currentItems
                  ?
                  currentItems.map((ticket, index) => (
                      <tr key={ticket.reqId}>
                        <td>{ticket.reqId}</td>
                        <td>{ticket.userId}</td>
                        <td>{ticket.first_name + ticket.last_name}</td>
                        <td>
                        {new Date(ticket.created_at).toLocaleString()}
                        <MdOutlineDateRange
                          style={{
                            marginLeft: "5px",
                            marginTop: "-3px",
                            fontSize: "1rem",
                          }}
                        />
                        </td>
                        <td>{ticket.reqType}</td>
                        <td>{ticket.action}</td>
                        <td>{ticket.approved}</td>
                        <td>                      
                        </td>
                      </tr>
                    ))
                  : (
                    <tr>
                      <td colSpan="8">Loading...</td>
                    </tr>
                )} */}
              </tbody>
            </table>
          </div>
          <div className="card-footer py-4">
            <nav aria-label="...">
              <ul className="pagination justify-content-end mb-0">
                {ticketList
                  ? Array.from(
                      { length: Math.ceil(ticketList.length / itemsPerPage) },
                      (_, i) => (
                        <li
                          key={i}
                          className={`page-item ${
                            currentPage === i + 1 ? "active" : ""
                          }`}
                        >
                          <a
                            className="page-link"
                            href="#"
                            onClick={() => setCurrentPage(i + 1)}
                          >
                            {i + 1}
                          </a>
                        </li>
                      )
                    )
                  : null}
              </ul>
            </nav>
          </div>
        <Modal show={showModal} onHide={handleCloseModal} centered>
            <Modal.Body style={{width: '100%'}}>
            <Modal.Header closeButton style={{marginRight: '10px'}}> </Modal.Header>
            <div className="d-flex flex-column justify-content-center align-items-center" style={{marginLeft:' 10px'}}>
                <h5 style={{color: 'black'}}>Request For Changing {selectedRow.reqType}</h5>
                {
                    selectedRow ? (
                        <div className="d-flex flex-column justify-content-center align-items-center">
                        <p style={{marginBottom: '-1px'}}> Reason: {selectedRow.reason}</p>
                        {
                            selectedRow.reqType === "Name" ? (
                                <>
                                <p style={{marginBottom: '-1px'}}> New First Name : {selectedRow.newContent.split('/')[0]}</p>
                                <p> New Last Name : {selectedRow.newContent.split('/')[1]}</p>
                                </>
                            ) : (<p> New {selectedRow.reqType} : {selectedRow.newContent}</p>)
                        }
                        </div>
                    ) : null
                }
                <div>
                    <Button className="btn btn-primary" style={{borderRadius: '4px', marginRight: '30px', background:'#23bd5a', border: 'None'}}
                            onClick = {() => handleApproval(true, selectedRow)}
                            onMouseEnter={(e) => {
                            e.target.style.color = 'black';  // Change font color on hover
                            }}
                            onMouseLeave={(e) => {
                            e.target.style.color = 'white';  // Revert font color when mouse leaves
                            }}>Approve</Button>
                    <Button className="btn btn-primary" style={{borderRadius: '4px', background: 'red', border: 'None'}}
                            onClick = {() => handleApproval(false, selectedRow)}
                            onMouseEnter={(e) => {
                            e.target.style.color = 'black';  // Change font color on hover
                            }}
                            onMouseLeave={(e) => {
                            e.target.style.color = 'white';  // Revert font color when mouse leaves
                            }}>Reject</Button>
                </div>                
            </div>
            </Modal.Body>
        </Modal>        
        </div>
      </div>
    </>
  );
};

export default AdminTicket;