import React, { useState, useEffect } from 'react';
import { Modal, Button, Dropdown } from 'react-bootstrap';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import { FcNews, FcOpenedFolder, FcBullish, FcComments } from "react-icons/fc";

import 'sweetalert2/src/sweetalert2.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../assets/css/PostNew.css'
import '../../../assets/css/DropDown.css'

import APICall from '../../../api';

const PostNew = ({is_admin, click, mediaType, groupID = null}) => {
  // ---------------------------------------------------------------------
  // State hooks  
  const [showModal, setShowModal] = useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [selectedImages, setSelectedImages] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const [selectedMediaType, setSelectedMediaType] = useState(mediaType);

  // ---------------------------------------------------------------------
  // Helper functions
  const handleMediaTypeSelect = (eventKey) => {
    // Handle the selected media type
    setSelectedMediaType(eventKey);
  };

  const removeFile = (index, event) => {
    event.preventDefault();

    // Remove the file at the specified index
    const updatedFileNames = [...fileNames];
    updatedFileNames.splice(index, 1);
    setFileNames(updatedFileNames);

    // Transfer the remaining file to the inputList
    const fileInput = document.getElementById('fileUpload');
    const dataTransfer = new DataTransfer();

    updatedFileNames.forEach((fileName) => {
      const blob = new Blob([''], { type: 'application/octet-stream' }); // Set the blob (file-like object)
      const file = new File([blob], fileName);
      dataTransfer.items.add(file);
    });
    
    // Reset the value of the input to clear the selected files
    fileInput.files = dataTransfer.files;

    // Remove the image data in selected images
    selectedImages.splice(index,1);
  };

  const handleImageChange = async (event) => {
    const files = event.target.files;
    const names = Array.from(files).map((file) => file.name);

    if (names && names.length > 5) {
      setSelectedImages([]);
      Swal.fire({
        icon: "warning",
        title: "Images exceed limit",
        text: "No more than 5 images!",
      });
      event.target.value = null;
      return;
    }

    // Helper function to convert a file to base64
    const MAX_FILE_SIZE_MB = 1;
    const fileToBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const base64Data = reader.result.split(',')[1];
          const fileSize = Math.floor((base64Data.length * 3) / 4 / (1024*1024));
          if (fileSize > MAX_FILE_SIZE_MB) {
            Swal.fire({
              icon: "warning",
              title: "Image size too big",
              text: "Size limit is 1MB! Please do check each of them properly!",
            });
            event.target.value = null; // Clear the input field
            return;
          } else {
            resolve(base64Data);
            setFileNames(names);
          }
        };
        reader.onerror = (error) => reject(error);
      });
    };
    
    // Sequentially convert each file to base64
    setSelectedImages([]);
    for (let i = files.length - 1; i >= 0; i--) {
      try {
        if (!/\.(jpg|jpeg|png)$/i.test(names[i])) {
          // Invalid image file extension
          Swal.fire({
            icon: "error",
            title: "Invalid File Found",
            text: "Please select only image files (JPEG, JPG, PNG) All Files Will Be Cleared",

          });
          event.target.value = null;
          setFileNames([]);
          setSelectedImages([]);
          return;
        } else {
          const base64Data = await fileToBase64(files[i]);
          setSelectedImages((prevImages) => [...prevImages, base64Data]);
        }
      } catch (error) {
        console.error('Error converting file to base64:', error);
      }
    }
  };


  // ---------------------------------------------------------------------
  // API Calling
  const addPost = () => {
    try {
      if (selectedImages && selectedImages.length > 5) {
        setSelectedImages([]);
        Swal.fire({
          icon: "warning",
          title: "Images exceed limit",
          text: "No more than 5 images!",
        });
        return;
      }

      if (content.length > 1000) {
        // Fire alert for success
        Swal.fire({
          icon: "warning",
          title: "Words exceed limit!",
          text: "Only maximum 1000 character allowed",
        });
        return;       
      }

      const contentType = "application/json";
      const endPoint = "Post/createPost";
      const bodyContent = {
        userId : Cookies.get('userId'),
        title : title,
        content : content,
        mediaType : mediaType,
        images: selectedImages,
        groupId: groupID
      }    
  
      APICall("POST", endPoint, bodyContent, contentType).then(
        response => {
          if (!response.success && response.message === 'SessionExpired') {
            localStorage.clear();
            window.location.href = '/login?sessionExpired=true';
          }

          if (response.success) {
            // Fire alert for success
            Swal.fire({
              icon: "success",
              title: "SUCCESS",
              text: "Post Published!",
            }).then((result) => {
              if (result.isConfirmed || result.dismiss === Swal.DismissReason.timer) {
                window.location.reload();
              }
            });
          }
          else {
            console.error('Failed to post', response.message);
            Swal.fire({
              icon: "error",
              title: "Add Comments failed!",
              text: "Something went wrong, please try again later",
            });
          }
        }
      )  
    } catch (error) {
      console.error('An error occurred while creating post', error);
    } 
  };

  // -------------------------------------------------------------------
  // Event functions
  const handleModalClose = () => {
    // Clear selected files when the modal is closed
    setSelectedImages([]);
    setFileNames([]);
    setTitle('');
    setContent('');
    setSelectedMediaType(null);
    setShowModal(false);
    window.location.reload();    
  }; 

  const openModal = () => {
    setShowModal(true);
  };

  const handlePost = () => {
    if (content.length <= 0) {
      Swal.fire({
        icon: "warning",
        title: "Post content empty!",
        text: "Please input something!",
      });
      return;
    }

    if (groupID === null && selectedMediaType === null) {
      Swal.fire({
        icon: "warning",
        title: "Media type empty!",
        text: "Please select the media type!",
      });
      return;
    }

    addPost();
  };


  // ------------------------------------------------------------------
  // Effect hooks
  useEffect(() => {
    if (click === true) {
      openModal();
    }
  }, [click]);

  return (
    <>
      <Modal show={showModal} dialogClassName="modal-90w">
        <Modal.Body>
          <div className="modal-body">
            <div className="row">
              <div className="modal-body p-4 py-5 p-md-5">
		      		  <h3 className="text-center mb-3">Create Your Post</h3>

                <form action="#" class="signup-form">
                  <div class="form-group mb-2">
                    <label for="email">Content</label>
                    <textarea name="content" className="form-control" rows="5" placeholder="Post Content" value={content} 
                                onChange={e => {
                                  // Check if Shift+Enter is pressed
                                  if (e.shiftKey && e.key === 'Enter') {
                                    e.preventDefault();
                                    // Insert a newline character at the current cursor position
                                    const cursorPosition = e.target.selectionStart;
                                    const newText = content.substring(0, cursorPosition) + '\n' + content.substring(cursorPosition);
                                    setContent(newText);
                                  } else {
                                    // Otherwise, update the textarea value as usual
                                    setContent(e.target.value);
                                  }
                                }}/>
                  </div>

                  {
                    groupID === null ?
                    <div className="col-md-12">
                    <label for="email">Media Type</label>
                    <Dropdown className="form-group mb-2 btn-dropdown justify-content-between align-items-center">
                        <div className="d-flex align-items-center" style={{width: '345px', height: '50px'}}>
                            <p className="text-left mb-0 heading">
                                <div className='d-flex align-items-center'>
                                  <span className="text-dark" style = {{marginLeft: '-5%', fontSize: '.8rem', fontWeight: '400'}}>
                                    Your current media type:
                                  </span>
                                {
                                  mediaType === 'knowledge' ? (
                                    <Button style={{height: '34px', width: '120px', backgroundColor: '#F0F0F0', border: 'None', marginLeft: '5px', color: 'black', display: 'flex', justifyContent: 'flex-start'}}>
                                      <div className="d-flex" style={{ fontSize: '1.5em', marginRight: '5px'}}>
                                        <FcNews/>
                                      </div>
                                      Knowledge
                                    </Button>
                                  ) : mediaType === 'education' ? (
                                    <Button style={{height: '34px', width: '120px', backgroundColor: '#F0F0F0', border: 'None', marginLeft: '5px', color: 'black', display: 'flex', justifyContent: 'flex-start'}}>
                                      <div className="d-flex" style={{ fontSize: '1.5em', marginRight: '5px'}}>
                                        <FcOpenedFolder/>
                                      </div>
                                      Education
                                    </Button>
                                  ) : mediaType === 'trading' ? (
                                    <Button style={{height: '34px', width: '110px', backgroundColor: '#F0F0F0', border: 'None', marginLeft: '5px', color: 'black', display: 'flex', justifyContent: 'flex-start'}}>
                                      <div className="d-flex" style={{ fontSize: '1.5em', marginRight: '5px'}}>
                                        <FcBullish/>
                                      </div>
                                      Trading
                                    </Button>                                    
                                  ) : mediaType === 'announcement' ? (
                                    <Button style={{height: '34px', width: '140px', backgroundColor: '#F0F0F0', border: 'None', marginLeft: '5px', color: 'black', display: 'flex', justifyContent: 'flex-start'}}>
                                      <div className="d-flex" style={{ fontSize: '1.5em', marginRight: '5px'}}>
                                        <FcComments/>
                                      </div>
                                      Announcement
                                    </Button>
                                  ) : null
                                }
                                </div>
                            </p>
                            {/* <Dropdown.Toggle
                                className="split ms-auto"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                            <span className=''><FcStackOfPhotos /></span>
                            </Dropdown.Toggle> */}
                        </div>
  
                        <Dropdown.Menu>
                        <Dropdown.Item className="d-flex align-items-start" onClick={() => handleMediaTypeSelect('knowledge')}>
                            <div className="cart" style={{ fontSize: '2em' }}>
                            <FcNews/>
                            </div>
                            <div className="text pl-3">
                            <h4>Knowledge Sharing Page</h4>
                            <p className="mb-0"><span className="quantity ml-3">Knowledge Tab</span></p>
                            </div>
                        </Dropdown.Item>
                        <Dropdown.Item className="d-flex align-items-start" onClick={() => handleMediaTypeSelect('education')}>
                            <div className="cart" style={{ fontSize: '2em' }}>
                            <FcOpenedFolder/>
                            </div>
                            <div className="text pl-3">
                            <h4>Education Page</h4>
                            <p><span className="quantity ml-3">Education Tab</span></p>
                            </div>
                        </Dropdown.Item>
                        <Dropdown.Item className="d-flex align-items-start" onClick={() => handleMediaTypeSelect('trading')}>
                            <div className="cart" style={{ fontSize: '2em' }}>
                            <FcBullish/>
                            </div>
                            <div className="text pl-3">
                            <h4>Trading Sharing</h4>
                            <p><span className="quantity ml-3">Trading Tab</span></p>
                            </div>
                        </Dropdown.Item>
                        {is_admin === '1' ? (
                          <Dropdown.Item className="d-flex align-items-start" onClick={() => handleMediaTypeSelect('announcement')}>
                            <div className="cart" style={{ fontSize: '2em' }}>
                              <FcComments/>
                              </div>
                              <div className="text pl-3">
                              <h4>Admin Announcement</h4>
                              <p><span className="quantity ml-3">Announcement Tab</span></p>
                            </div>
                          </Dropdown.Item>                        
                        ) : null }
                        </Dropdown.Menu>
                    </Dropdown>
                    </div> : null
                  }

                  <div class="form-group mb-2">
                    <label htmlFor="image">Image</label>
                    <input id="fileUpload" type="file" name="image" class="form-control" onChange={handleImageChange} multiple/>
                  </div>
                  {fileNames.length > 0 && (
                    <div className="form-group mb-4">
                      <strong>Already In-Selected Files:</strong>
                      <ul className="list-unstyled mt-2">
                        {fileNames.map((name, index) => (
                        <li key={index} className="d-flex justify-content-between align-items-center border-bottom py-2">
                          <span>{name}</span>
                          <div className="d-flex justify-content-between align-items-center border-bottom py-2">
                            <span className="badge bg-primary l-100" style={{marginRight: '10px'}}>File {index + 1}</span>
                            <button className="badge bg-danger l-100 border-0" style={{height: '22px'}} onClick={(e) => removeFile(index, e)}>Remove</button>
                          </div>
                        </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <a className="btn btn-primary" onClick={handlePost} style={{color: 'white'}}>
            Post <i className="far fa-gem ml-1 white-text"></i>
          </a>
          <Button variant="outline-primary" onClick={handleModalClose}>
            No, thanks
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PostNew;
