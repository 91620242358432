import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { MdGroups } from "react-icons/md";
import { AiOutlineClose } from "react-icons/ai";
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';

import "../../../assets/css/Request.css";

import APICall from '../../../api';

const CreateGroup = ({ showModal, handleClose  }) => {
  // ---------------------------------------------
  // State hooks
  const [localShowModal, setLocalShowModal] = useState(showModal);
  const [groupName, setGroupName] = useState('');
  const [groupIntro, setGroupIntro] = useState('');

  // ---------------------------------------------
  // Event functions
  const handleGroupName = (e) => {
    setGroupName(e.target.value);
  };

  const handleGroupIntro = (e) => {
    setGroupIntro(e.target.value);
  };    

  const checkWordsLimit = (type, words, limit) => {
    const length = words.replace(/\s/g, '').length;
    if (length > limit) {
        Swal.fire({
            icon: "warning",
            title: "Words Limit Exceeded",
            text: `Words exceed length limit(${limit}) for ${type}`,
        });
        return false;
    }

    if (length <= 0) {
        Swal.fire({
            icon: "warning",
            title: `Please type in ${type}`,
            text: `The ${type} is empty!`,
        });
        return false;
    }
    return true;
}

  // ---------------------------------------------
  // Styling
  const iconStyle = {
    fontSize: '3rem',
    fontWeight: 700,
  }


  // --------------------------------------------
  // API Calling
  const createGroup = async () => {
    if (!checkWordsLimit("group name", groupName, 15)) {
        return;
    }
    if (!checkWordsLimit("group introduction", groupIntro, 100)) {
        return;
    }

    try {
      const contentType = "application/json";
      const endPoint = "Group/CreateGroup";
      const bodyContent = {
        userId : Cookies.get('userId'),
        groupName : groupName,
        groupIntro : groupIntro
      }

      APICall("POST", endPoint, bodyContent, contentType).then(
        response => {
          if (!response.success && response.message === 'SessionExpired') {
            localStorage.clear();
            window.location.href = '/login?sessionExpired=true';
          }

          if (response.success) {
            const data = response.data;
            if (data) {
                Swal.fire({
                    icon: "success",
                    title: "SUCCESS",
                    text: "Group Created! You will be redirect to homepage and access group page from there!",
                  }).then((result) => {
                    window.location.href = "/home";
                  })
            }
          }
          else {
            console.error('Failed to create group', response.message);
            Swal.fire({
              icon: "error",
              title: "Group created",
              text: "Something went wrong, please try again later"
            });
          }
        }
      )         

    } catch (error) {
      console.error('An error occurred while creating group', error);
    }
  }

  return (
    <>
    <Modal show={localShowModal}>      
      <Modal.Dialog className="modal-dialog-centered request-modal">       
          <Modal.Body className="modal-body p-4 px-5 d-flex flex-column">
          <Button className="close-btn" variant="none" aria-label="Close" onClick={handleClose} style={{ position: 'absolute', marginTop: '-30px', marginRight: '-5%'}}>
            <span aria-hidden="true"><AiOutlineClose style={{ fontSize: '1.5rem' }} /></span>
          </Button>
            <div className="main-content text-center">
              <div className="warp-icon mb-4 d-flex align-items-center justify-content-center">
                <MdGroups style={iconStyle}/>
              </div>
              <form action="#">
                <label htmlFor="">This form is for creating a new group</label>
                <p className="mb-4">*Once you have created a group, you can't leave unless you have transfer your leader to other person</p>
                <div className="form-group mb-4">
                    <input type="text" className="form-request form-control text-center" placeholder={`Enter Group Name`}
                            value={groupName}
                            onChange={handleGroupName}/>
                    <br></br>
                    <input type="text" className="form-request form-control text-center" placeholder="Introduce your group" 
                            value={groupIntro}
                            onChange={handleGroupIntro}
                  />
                </div>

                <div className="d-flex">
                  <div className="mx-auto">
                    <Button type="button" className="custom-btn btn-primary" onClick={createGroup} style={{backgroundColor: 'gray', borderColor: 'white'}}
                          onMouseEnter={(e) => {
                            e.target.style.backgroundColor = 'darkgray';
                            e.target.style.color = 'black';  // Change font color on hover
                          }}
                          onMouseLeave={(e) => {
                            e.target.style.backgroundColor = 'gray';
                            e.target.style.color = 'white';  // Revert font color when mouse leaves
                          }}>
                      Submit
                    </Button>
                  </div>
                </div>
              </form>

            </div>

          </Modal.Body>
      </Modal.Dialog>
    </Modal>
    </>
  );
};

export default CreateGroup;
