import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { HiOutlineMail } from "react-icons/hi";
import { FiPhone } from "react-icons/fi";
import { GiWorld } from "react-icons/gi";
import { MdOutlineDriveFileRenameOutline } from "react-icons/md";
import { AiOutlineClose } from "react-icons/ai";
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';

import "../../../assets/css/Request.css";

import APICall from '../../../api';

const Request = ({ showModal, handleClose, reqType  }) => {
  // ---------------------------------------------
  // State hooks
  const [localShowModal, setLocalShowModal] = useState(showModal);
  const [newSetting, setNewSetting] = useState('');
  const [newFirstName, setNewFirstName] = useState('');
  const [newLastName, setNewLastName] = useState('');
  const [reason, setReason] = useState('');


  // ---------------------------------------------
  // Event functions
  const handleSubmit  = () => {
    var newData;
    if (newFirstName !== '') {
      newData = {
        'new_name' : newFirstName + '/' + newLastName,
        'reason' : reason
      }
      if (reason === '' || newFirstName === '' || newLastName === '') {
        Swal.fire({
          icon: "error",
          title: "Empty Required Value",
          text: "Please make sure type in value",
        });     
        return;
      }        
    }
    else {
      if (reason === '' || newSetting === '') {
        Swal.fire({
          icon: "error",
          title: "Empty Required Value",
          text: "Please make sure type in value",
        });     
        return;        
      }      
      const keyName = reqType === "Country" ? "new_country" :
                        reqType === "Phone" ? "new_phone" :
                          reqType === "Email" ? "new_email" :
                            null;
      newData = {
        [keyName] : newSetting,
        'reason' : reason
      }      
    }

    sendTicket(newData);    
    setLocalShowModal(false);
    handleClose(); // Call the handleClose function from props to update the parent component's state
  }

  const handleNewSettingChange = (e) => {
    setNewSetting(e.target.value);
  };

  const handleReasonChange = (e) => {
    setReason(e.target.value);
  };  

  const handleNewFirstNameChange = (e) => {
    setNewFirstName(e.target.value);
  };  
  
  const handleNewLastNameChange = (e) => {
    setNewLastName(e.target.value);
  };    

  // ---------------------------------------------
  // Styling
  const iconStyle = {
    fontSize: '4rem',
    fontWeight: 700,
  }


  // --------------------------------------------
  // API Calling
  const sendTicket = async (newData) => {
    try {
      const contentType = "application/json";
      const endPoint = "User/sendTicket";
      const bodyContent = {
        userId : Cookies.get('userId'),
        reqType : reqType,
        newData : newData
      }      

      APICall("POST", endPoint, bodyContent, contentType).then(
        response => {
          if (!response.success && response.message === 'SessionExpired') {
            localStorage.clear();
            window.location.href = '/login?sessionExpired=true';
          }

          if (response.success) {
            const data = response.data;
            Swal.fire({
              icon: "success",
              title: "SUCCESS",
              text: "Ticket Sent!",
            });
            localStorage.setItem('userSeenTicket', false);
          }
          else {
            console.error('Failed to send tickets', response.message);
            Swal.fire({
              icon: "error",
              title: "Send ticket failed!",
              text: "Something went wrong, please try again later",
            });
          }
        }
      )         

    } catch (error) {
      console.error('An error occurred while sending tickets', error);
    }
  }

  return (
    <>
    <Modal show={localShowModal}>      
      <Modal.Dialog className="modal-dialog-centered request-modal">       
          <Modal.Body className="modal-body p-4 px-5 d-flex flex-column">
          <Button className="close-btn" variant="none" aria-label="Close" onClick={handleClose} style={{ position: 'absolute', marginTop: '-30px', marginRight: '-5%'}}>
            <span aria-hidden="true"><AiOutlineClose style={{ fontSize: '1.5rem' }} /></span>
          </Button>
            <div className="main-content text-center">
              <div className="warp-icon mb-4 d-flex align-items-center justify-content-center">
              {reqType === 'Email' ? <HiOutlineMail style={iconStyle}/> : 
                (reqType === 'Phone' ? <FiPhone style={iconStyle}/> : 
                  (reqType === 'Country' ? <GiWorld style={iconStyle}/> :
                   (reqType === 'Name' && <MdOutlineDriveFileRenameOutline style={iconStyle}/>)))}
              </div>
              <form action="#">
                <label htmlFor="">This form is submitted to the Admin</label>
                <p className="mb-4">You will be sending a request for changing {reqType} settings to Admin for Approval</p>
                <div className="form-group mb-4">
                  {
                    reqType === 'Name' ? (
                      <>
                      <input type="text" className="form-request form-control text-center" placeholder={`Enter New First Name`}
                             value={newFirstName}
                             onChange={handleNewFirstNameChange}                      
                      />
                      <br></br>
                      <input type="text" className="form-request form-control text-center" placeholder={`Enter New Last Name`}
                             value={newLastName}
                             onChange={handleNewLastNameChange}                         
                      />
                      </>
                    ) : (
                      <>
                      <input type="text" className="form-request form-control text-center" placeholder={`Enter New ${reqType}`}
                             value={newSetting}
                             onChange={handleNewSettingChange}
                      />
                      </>
                    )
                  }
                  <br></br>
                  <input type="text" className="form-request form-control text-center" placeholder="Enter Reason" 
                         value={reason}
                         onChange={handleReasonChange}
                  />
                </div>

                <div className="d-flex">
                  <div className="mx-auto">
                    <Button type="button" className="custom-btn btn-primary" onClick={handleSubmit} style={{backgroundColor: 'gray', borderColor: 'white'}}
                          onMouseEnter={(e) => {
                            e.target.style.backgroundColor = 'darkgray';
                            e.target.style.color = 'black';  // Change font color on hover
                          }}
                          onMouseLeave={(e) => {
                            e.target.style.backgroundColor = 'gray';
                            e.target.style.color = 'white';  // Revert font color when mouse leaves
                          }}>
                      Submit
                    </Button>
                  </div>
                </div>
              </form>

            </div>

          </Modal.Body>
      </Modal.Dialog>
    </Modal>
    </>
  );
};

export default Request;
