import React, { useState, useEffect, useRef } from "react";
import Icon from "@mdi/react";
import { mdiHeartOutline, mdiHeart} from "@mdi/js";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { HiDotsVertical } from "react-icons/hi";
import { Carousel } from "react-bootstrap";
import { MdDeleteForever } from "react-icons/md";
import { Dropdown } from "react-bootstrap";

import SharePost from "../Post/Share-post";
import Comment from "../Post/Comment";
import GroupFullPost from "./Group-fullpost";

import APICall from "../../../api";

const GroupPostContent = ({post, groupId, scivId, msgCode}) => {
    // -------------------------------------------------------
    // State hooks
    const [userLList, setUserLList] = useState(post.userLikeList);
    const [togglelikes, setToggleLikes] = useState(post.currentUserHitLikes);
    const [postLLikes, setPostLikes] = useState(post.postLikes);
    const [showFullPost, setShowFullPost] = useState(false);
    const [showRemovePost, setShowRemovePost] = useState(false);
    const navigate = useNavigate();

    // -------------------------------------------------------
    // Event functions
    const handleToggleLike = () => {
        setToggleLikes(!togglelikes);
        handleLike();
    };

    const handleCloseFullPost = () => {
        setShowFullPost(false);
      }

    // -------------------------------------------------------
    // API Calling
    const handleLike = async () => {
        try {
          const contentType = "application/json";
          const endPoint = "Post/likePost";
          const bodyContent = {
            userId: Cookies.get("userId"),
            postId: post.postID,
            likeHit: togglelikes,
            groupId: groupId,
            recipientId: post.userID
          }
    
          APICall("POST", endPoint, bodyContent, contentType).then(
            response => {
                if (!response.success && response.message === 'SessionExpired') {
                    localStorage.clear();
                    window.location.href = '/login?sessionExpired=true';
                  }
    
              if (response.success) {
                const data = response.data;
                setPostLikes(data.newPostLikes);
                setUserLList(data.newPostULL);
                setToggleLikes(!togglelikes);            
              }
              else {
                console.error('Failed to get likes', response.message);
                Swal.fire({
                  icon: "error",
                  title: "Get likes failed!",
                  text: "Something went wrong, please try again later",
                });
              }
            }
          )       
        } catch (error) {
          console.error("An error occurred while liking post", error);
        }
    };

    const removePost = async (postId) => {
        try {
          const contentType = "application/json";
          const endPoint = "Post/deletePost";
          const bodyContent = {}
          const urlArgs = "?postId="+postId+"&groupId="+groupId
          
          APICall("DELETE", endPoint, bodyContent, contentType, urlArgs).then(
            response => {
              if (!response.success && response.message === 'SessionExpired') {
                localStorage.clear();
                window.location.href = '/login?sessionExpired=true';
              }
    
              if (response.success) {
                Swal.fire({
                  icon: "success",
                  title: "SUCCESS",
                  text: "Post Removed!",
                }).then((result) => {
                  if (result.isConfirmed || result.dismiss === Swal.DismissReason.timer) {
                    window.location.reload();
                  }
                });
              }
              else {
                console.error('Failed to get likes', response.message);
                Swal.fire({
                  icon: "error",
                  title: "Get likes failed!",
                  text: "Something went wrong, please try again later",
                });
              }
            }
          )       
        } catch (error) {
          console.error("An error occurred while liking post", error);
        }
      }

    const isPortrait = (base64Data) => {
        const image = new Image();
        image.src = `data:image/jpeg;base64,${base64Data}`; // Adjust this based on the image format
        return image.height >= image.width;
      };
    
      const getImageStyle = (imgSrc, width) => {
        const isPotrait = isPortrait(imgSrc);
        if (isPotrait) {
          return {
            width: width,
            marginLeft: 'auto',
            marginRight: 'auto',
          }
        }
        return {
          width: '100%'
        }
    }

    // ------------------------------------------------------
    // Effect hooks
    const col2Ref = useRef(null);
    const borderDivRef = useRef(null);

    useEffect(() => {
        if (scivId === undefined) {
            const col2Height = col2Ref.current.getBoundingClientRect().height / 2;
            borderDivRef.current.style.height = `${col2Height}px`;
        }
      }, [post]);

    
      // ----------------------------------------------------
      // Event functions
      const redirectViewProfile = (directUid) => {
        navigate(`/home/profile/${directUid}`);
      }

    return (
        <div className="d-flex">
        {
            scivId === undefined ? (
                <div className="col-sm-2" style={{marginRight: '-11px'}} ref={col2Ref}>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <img src={
                        post.profilePic === null ?
                        "/anon.jpg" 
                        : `data:image/jpeg;base64,${post.profilePic}`
                    } className="rounded-circle mt-n5" alt="coder" style={{width: '60px', height: '60px'}}/>
                    <div ref={borderDivRef} style={{borderRight: '1px solid #ccc', marginTop: '8px'}}></div>
                    </div>
                </div> 
            ) : null
        }

        {/* Post contents */}
        <div className="card col-lg-10 mb-3 rounded-3 pt-2" style={{border: scivId ? 'None' : '', paddingLeft: '10px', marginLeft: '10px', paddingRight: '10px' }}>
            <div className="mb-2">
                <div className="d-flex align-items-start mt-2">
                    <img src={                
                        post.profilePic === null ?
                        "/anon.jpg" 
                        : `data:image/jpeg;base64,${post.profilePic}`} 
                        className="rounded-circle mt-n5" alt="coder" 
                        style={{width: '50px', height: '50px', cursor:'pointer'}}
                        onClick={() => redirectViewProfile(post.userID)}
                   />
                    <div className="flex-column" style={{marginLeft: '8px', marginTop: '-5px'}}>
                        <div className="d-flex">
                            <h5 className="card-title mt-2 fw-semibold" style={{fontSize: '1rem', cursor:'pointer'}}
                                onClick={() => redirectViewProfile(post.userID)}>{post.first_name+post.last_name}</h5>
                            <h5 className="small-text text-black-50 fw-semibold" style={{fontSize: '0.75em', marginLeft: '4px', marginTop: '11.3px'}}> - {post.postTime}</h5>
                        </div>
                        <p className="small-text text-black-50 text-xs mb-2" style={{ marginTop: '-3%', marginBottom: '0', fontSize: '75%' }}>Member | Post</p>              
                    </div>
                    {
                        parseInt(post.userID) === parseInt(Cookies.get("userId")) ? (
                        <div style={{marginLeft: 'auto', marginRight: '10px', marginTop: '8px'}}>
                        <HiDotsVertical style={{fontSize: '1.3rem', color: 'gray', cursor: 'pointer'}} onClick={() => setShowRemovePost(!showRemovePost)}/>
                        {
                            showRemovePost ? (
                            <Dropdown className="form-group justify-content-between align-items-center" 
                                        style={{position: 'absolute', left: 450, zIndex: 1000, marginTop: '15px'}} show={showRemovePost}>
                            <Dropdown.Menu style={{minWidth: '1px', width: '160px', border: 'none'}}>
                                <div className="card d-flex align-items-start">
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item d-flex justify-content-start align-items-center" style={{height: '50px'}}>
                                    <div className="d-flex justify-content-start align-items-center" style={{marginLeft: '-8px', cursor: 'pointer'}}
                                        onClick={() => removePost(post.postID)}>
                                        <MdDeleteForever style={{fontSize: '1.6rem', color: 'gray', marginRight: '5px'}}/>
                                        <div className="flex-column">
                                        <p className="text-black heading fw-semibold" style={{fontSize: '.9rem', marginBottom: '-4px'}}>
                                            Delete
                                        </p>
                                        <p className="text-black-50 mb-0 fw-semibold" style={{fontSize: '.7rem'}}>
                                            Remove the post
                                        </p>
                                        </div>
                                    </div>
                                    </li>
                                </ul>
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>
                            ) : null
                        }
                        </div>
                        ) : null
                    }                                   
                </div>
                <p className="text-black-50 mt-2 mb-0" style={{whiteSpace: "pre-line", fontWeight: '400', fontSize: '.9rem'}}>{post.content}</p>
            </div>

            {/* ------------------------Share post content----------------------------- */}
            {post.sharePostID !== null ? (
                <div class="form-group mb-2 d-flex justify-content-center align-items-center">
                <div className="card col-lg-10 mb-4 rounded-4" style={{ width: "100%" }}>
                    <div className="mb-2" style={{ marginLeft: "25px"}}>
                        <div className="d-flex align-items-start mt-2">
                        <img
                            src={
                            post.oriPostProfilePic === null
                                ? "/anon.jpg"
                                : `data:image/jpeg;base64,${post.oriPostProfilePic}`
                            }
                            onClick={() => redirectViewProfile(post.oriPostUserId)}
                            className="rounded-circle mt-n5"
                            alt="coder"
                            style={{ width: "50px", height: "50px", marginTop: '5px', cursor:'pointer' }}
                        />
                        <div className="flex-column mt-1" style={{ marginLeft: "8px" }}>
                            <h5
                            className="card-title mt-2 fw-semibold"
                            style={{ fontSize: "1rem", cursor:'pointer'} }
                            onClick={() => redirectViewProfile(post.oriPostUserId)}
                            >
                            {post.oriPostUserfn + post.oriPostUserln}
                            </h5>
                            <p
                            className="small-text text-black-50 text-xs mb-2"
                            style={{ marginTop: "-3%", marginBottom: "0", fontSize: "75%" }}
                            >
                            {`${post.oriIsAdmin === 1 ? 'Admin' : 'User'} | Post`}
                            </p>
                        </div>
                        </div>
                        <p
                        className="text-black-50 mt-2 mb-0"
                        style={{ color: "#757a91", textAlign: "justify", maxWidth: "95%", fontSize: '.9rem', fontWeight: '400' }}
                        >
                        {post.oriPostContent}
                        </p>
                    </div>
                    <div className="text-center" onClick={() => setShowFullPost(!showFullPost)} style={{cursor: 'pointer'}}>
                    {post.oriPostImageData.length > 4 ? (
                        // 5 images
                        <div className="row" style={{height: '340px', padding: '5px'}}>
                            <div className="col-md-6" style={{ width: '60%', maxHeight: '100%', display: 'flex', flexDirection: 'column', marginRight: '-15px', marginLeft: '7px'}}>
                            <img src={`data:image/jpeg;base64,${post.oriPostImageData[0]}`} alt="Image 1" className="rounded-3" 
                                    style={{ height: '49%', marginBottom: '5px', ...getImageStyle(post.oriPostImageData[0], '49%') }}/>
                            <img src={`data:image/jpeg;base64,${post.oriPostImageData[1]}`} alt="Image 2" className="rounded-3" 
                                    style={{ height: '49%', marginBottom: '5px', ...getImageStyle(post.oriPostImageData[1], '49%') }} />
                            </div>
                            <div className="col-md-6" style={{ width: '40%', maxHeight: '100%', display: 'flex', flexDirection: 'column' }}>
                            <img src={`data:image/jpeg;base64,${post.oriPostImageData[2]}`} alt="Image 3" className="rounded-3 d-flex" 
                                    style={{ height: '32%', marginBottom: '5px', ...getImageStyle(post.oriPostImageData[2], '32%') }} />
                            <img src={`data:image/jpeg;base64,${post.oriPostImageData[3]}`} alt="Image 4" className="rounded-3" 
                                    style={{ height: '32%', marginBottom: '5px', ...getImageStyle(post.oriPostImageData[3], '32%') }} />
                            <img src={`data:image/jpeg;base64,${post.oriPostImageData[4]}`} alt="Image 5" className="rounded-3" 
                                    style={{ height: '32%', marginBottom: '5px', ...getImageStyle(post.oriPostImageData[4], '32%') }} />
                            </div>
                        </div>                  
                        ) : post.oriPostImageData.length > 3 ? (
                            // 4 images
                            <div className="row" style={{height: '340px', padding: '5px'}}>
                            <div className="col-md-6" style={{ width: '50%', maxHeight: '100%', display: 'flex', flexDirection: 'column', marginRight: '-15px', marginLeft: '7px'}}>
                                <img src={`data:image/jpeg;base64,${post.oriPostImageData[0]}`} alt="Image 1" className="rounded-3" 
                                    style={{ height: '49%', marginBottom: '5px', ...getImageStyle(post.oriPostImageData[0], '49%') }}/>
                                <img src={`data:image/jpeg;base64,${post.oriPostImageData[1]}`} alt="Image 2" className="rounded-3" 
                                    style={{ height: '49%', marginBottom: '5px', ...getImageStyle(post.oriPostImageData[1], '49%') }} />
                            </div>
                            <div className="col-md-6" style={{ width: '50%', maxHeight: '100%', display: 'flex', flexDirection: 'column' }}>
                                <img src={`data:image/jpeg;base64,${post.oriPostImageData[2]}`} alt="Image 4" className="rounded-3" 
                                    style={{ height: '49%', marginBottom: '5px', ...getImageStyle(post.oriPostImageData[2], '49%') }} />
                                <img src={`data:image/jpeg;base64,${post.oriPostImageData[3]}`} alt="Image 5" className="rounded-3" 
                                    style={{ height: '49%', marginBottom: '5px', ...getImageStyle(post.oriPostImageData[3], '49%') }} />
                            </div>
                            </div>
                        ) : post.oriPostImageData.length > 1 ? (
                        <Carousel>
                        {post.oriPostImageData.map((image, index) => (
                            <Carousel.Item key={index}>
                            <img
                                className="d-block rounded-3 mb-3 mx-auto"
                                src={`data:image/jpeg;base64,${image}`}
                                // src="/banner.jpg"
                                style={{ height: "330px", width: isPortrait(image) ? 'auto' : '96%' }}
                            />
                            </Carousel.Item>
                        ))}
                        </Carousel>                  
                        ) : post.oriPostImageData.length === 1 && post.oriPostImageData[0] !== null ? (
                        <img
                            className="d-block rounded-3 mb-3 mx-auto"
                            src={`data:image/jpeg;base64,${post.oriPostImageData[0]}`}
                            // src="/banner.jpg"
                            style={{ height: "330px", width: isPortrait(post.oriPostImageData[0]) ? 'auto' : '96%'  }}
                        />
                    ): null}
                    </div>         
                </div>                                           
                </div>                       
            ) : (
                <div className="text-center" onClick={() => setShowFullPost(!showFullPost)} style={{cursor: 'pointer'}}>
                    {post.image_data.length > 4  ? (
                    // 4 images
                    <div className="row" style={{height: '340px', padding: '5px', marginBottom: '5px'}}>
                        <div className="col-md-6" style={{ width: '60%', maxHeight: '100%', display: 'flex', flexDirection: 'column', marginRight: '-15px', marginLeft: '7px'}}>
                            <img src={`data:image/jpeg;base64,${post.image_data[0]}`} alt="Image 1" className="rounded-3" 
                                    onLoad={(e) => {
                                        const img = e.target;
                                        const settings = getImageStyle(post.image_data[0], '49%');
                                        img.style.width = settings.width;
                                        img.style.height = '49%';
                                        img.style.marginLeft = settings.marginLeft;
                                        img.style.marginRight = settings.marginRight;
                                    }}                            
                                    style={{ marginBottom: '5px' }}/>
                            <img src={`data:image/jpeg;base64,${post.image_data[1]}`} alt="Image 2" className="rounded-3" 
                                    onLoad={(e) => {
                                        const img = e.target;    
                                        const settings = getImageStyle(post.image_data[1], '49%');
                                        img.style.width = settings.width;
                                        img.style.height = '49%';
                                        img.style.marginLeft = settings.marginLeft;
                                        img.style.marginRight = settings.marginRight;                                        
                                    }}
                                    style={{ marginBottom: '5px' }} />
                        </div>
                        <div className="col-md-6" style={{ width: '40%', maxHeight: '100%', display: 'flex', flexDirection: 'column' }}>
                            <img src={`data:image/jpeg;base64,${post.image_data[2]}`} alt="Image 3" className="rounded-3 d-flex" 
                                    onLoad={(e) => {
                                        const img = e.target;    
                                        const settings = getImageStyle(post.image_data[2], '32%');
                                        img.style.width = settings.width;
                                        img.style.height = '32%';
                                        img.style.marginLeft = settings.marginLeft;
                                        img.style.marginRight = settings.marginRight;                                        
                                    }}
                                    style={{ marginBottom: '5px' }} />
                            <img src={`data:image/jpeg;base64,${post.image_data[3]}`} alt="Image 4" className="rounded-3" 
                                    onLoad={(e) => {
                                        const img = e.target;    
                                        const settings = getImageStyle(post.image_data[3], '32%');
                                        img.style.width = settings.width;
                                        img.style.height = '32%';
                                        img.style.marginLeft = settings.marginLeft;
                                        img.style.marginRight = settings.marginRight;                                        
                                    }}
                                    style={{ marginBottom: '5px' }} />
                            <img src={`data:image/jpeg;base64,${post.image_data[4]}`} alt="Image 5" className="rounded-3" 
                                    onLoad={(e) => {
                                        const img = e.target;    
                                        const settings = getImageStyle(post.image_data[4], '32%');
                                        img.style.width = settings.width;
                                        img.style.height = '32%';
                                        img.style.marginLeft = settings.marginLeft;
                                        img.style.marginRight = settings.marginRight;                                        
                                    }}
                                    style={{ marginBottom: '5px' }} />
                        </div>
                    </div>    
                    ) : post.image_data.length > 3 ? (
                        // 4 images
                        <div className="row" style={{height: '340px', padding: '5px', marginBottom: '-15px'}}>
                        <div className="col-md-6" style={{ width: '50%', maxHeight: '100%', display: 'flex', flexDirection: 'column', marginRight: '-15px', marginLeft: '7px'}}>
                            <img src={`data:image/jpeg;base64,${post.image_data[0]}`} alt="Image 1" className="rounded-3" 
                                    onLoad={(e) => {
                                        const img = e.target;   
                                        const settings = getImageStyle(post.image_data[0], '49%'); 
                                        img.style.width = settings.width;
                                        img.style.height = '45%';
                                        img.style.marginLeft = settings.marginLeft;
                                        img.style.marginRight = settings.marginRight; 
                                    }}
                                    style={{ marginBottom: '5px' }}/>
                            <img src={`data:image/jpeg;base64,${post.image_data[1]}`} alt="Image 2" className="rounded-3" 
                                    onLoad={(e) => {
                                        const img = e.target;    
                                        const settings = getImageStyle(post.image_data[1], '49%'); 
                                        img.style.width = settings.width;
                                        img.style.height = '45%';
                                        img.style.marginLeft = settings.marginLeft;
                                        img.style.marginRight = settings.marginRight; 
                                    }}
                                    style={{ marginBottom: '5px' }}/>
                        </div>
                        <div className="col-md-6" style={{ width: '50%', maxHeight: '100%', display: 'flex', flexDirection: 'column' }}>
                            <img src={`data:image/jpeg;base64,${post.image_data[2]}`} alt="Image 4" className="rounded-3" 
                                    onLoad={(e) => {
                                        const img = e.target;    
                                        const settings = getImageStyle(post.image_data[2], '49%'); 
                                        img.style.width = settings.width;
                                        img.style.height = '45%';
                                        img.style.marginLeft = settings.marginLeft;
                                        img.style.marginRight = settings.marginRight; 
                                    }}
                                    style={{ marginBottom: '5px' }}/>                            
                            <img src={`data:image/jpeg;base64,${post.image_data[3]}`} alt="Image 5" className="rounded-3" 
                                    onLoad={(e) => {
                                        const img = e.target; 
                                        const settings = getImageStyle(post.image_data[3], '49%');    
                                        img.style.width = settings.width;
                                        img.style.height = '45%';
                                        img.style.marginLeft = settings.marginLeft;
                                        img.style.marginRight = settings.marginRight; 
                                    }}
                                    style={{ marginBottom: '5px' }}/>
                        </div>
                        </div> 
                    ) : post.image_data.length > 1 ? (
                        <Carousel>
                        {post.image_data.map((image, index) => (
                            <Carousel.Item key={index}>
                            <img
                                className="d-block rounded-3 mb-3 mx-auto"
                                src={`data:image/jpeg;base64,${image}`}
                                onLoad={(e) => {
                                    const img = e.target;    
                                    if (isPortrait(image)) {
                                      img.style.height = '340px';
                                      img.style.width = 'auto';
                                    }
                                    else {
                                      img.style.height = '340px';
                                      img.style.width = '98%';
                                    }
                                }} 
                            />
                            </Carousel.Item>
                        ))}
                        </Carousel>         
                    ) : post.image_data.length === 1 && post.image_data[0] !== null ? (
                    <img
                        className="d-block rounded-3 mb-3 mx-auto"
                        src={`data:image/jpeg;base64,${post.image_data[0]}`}
                            onLoad={(e) => {
                                const img = e.target;    
                                if (isPortrait(post.image_data[0])) {
                                img.style.height = '340px';
                                img.style.width = 'auto';
                                }
                                else {
                                img.style.height = '340px';
                                img.style.width = '94%';
                                }
                            }} 
                    />
                    ) : null}
                </div>
            )}

            {/* Full Post */}
            {
                showFullPost && (
                <GroupFullPost handleClose={handleCloseFullPost} showModal={showFullPost} postProps={post} groupId={groupId} toggleLike={togglelikes} likesCnt={postLLikes}/> 
                )
            }
            {/* Full Post Ends Here */}

            {/* Post user likes list - share- comment section */}
            <div className="" style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '-18px' }} key={post.postID + groupId}>  
                <div style={{marginRight: 'auto', marginTop: '5px', display: 'flex'}} key={post.postID + groupId}>
                    <div style={{marginLeft: '10px'}} key={post.postID + groupId}>
                    {userLList ? 
                        userLList.map((user, index) => (
                            <img className="rounded-circle mt-n5" alt="coder" style={{width: '40px', height: '40px', marginLeft: '-10px'}}
                            src={              
                                user[1] === null
                                ? "/anon.jpg"
                                : `data:image/jpeg;base64,${user[1]}`} />
                        )) : null}
                    </div>
                    <div style={{ marginLeft: '10px' }}>
                        {userLList ?
                        userLList.length > 2 ? (
                            <div className="ml-2">
                            <p className="text-small fw-semibold" style={{ marginBottom: '-4px', fontSize: '.9rem' }}>
                            {userLList[0][0]}, {userLList[1][0]}
                            </p>
                            <p className="small-text text-black-50 text-xs fw-semibold" style={{ margin: '0', fontSize: '.8rem' }}>
                            and {post.postLikes - 2} liked this
                            </p>
                            </div>
                        ) :
                        userLList.length === 2 ? (
                            <>
                            <p className="text-small fw-semibold" style={{ marginBottom: '-4px', fontSize: '.9rem' }}>
                            {userLList[0][0]}, {userLList[1][0]}
                            </p>
                            <p className="small-text text-black-50 text-xs fw-semibold" style={{ margin: '0', fontSize: '.8rem' }}>
                            liked this
                            </p>
                            </>
                        ) : 
                        userLList.length === 1 ? (
                            <div className="ml-2">
                            <p className="text-small fw-semibold" style={{ marginBottom: '-4px', fontSize: '.9rem' }}>
                            {userLList[0][0]}
                            </p>
                            <p className="small-text text-black-50 text-xs fw-semibold" style={{ margin: '0', fontSize: '.8rem' }}>
                            liked this
                            </p>
                            </div>
                        ) : (
                            <div style={{marginLeft: '10px'}}>
                            <p className="text-small fw-semibold" style={{ marginBottom: '-4px', fontSize: '.9rem', marginLeft: '-20px'}}>
                            No one
                            </p>
                            <p className="small-text text-black-50 text-xs fw-semibold" style={{ margin: '0', fontSize: '.8rem', marginLeft: '-20px' }}>
                            liked this yet!
                            </p>
                            </div> 
                        ) : null                                  
                        }
                    </div>
                </div>
                <div className="d-flex" style={{marginTop: '15px'}}>
                    <p className="small-text text-black-50 text-xs" onClick={handleToggleLike} style={{ cursor: "pointer" }} id={post.postID}>
                        <Icon className="small-text text-black-50 text-xs" path={togglelikes ? mdiHeart : mdiHeartOutline} size={1} 
                        style={{ margin: '0 5px' }}/>{postLLikes}
                    </p>
                    {/* <p>
                        <SharePost postId={post.postID} fname={post.first_name} lname={post.last_name} imgData={post.image_data} sharedContent={post.content} 
                        sharedAmount={post.sharedAmt} sharePostID={post.sharePostID} oriPostContent={post.oriPostContent} oriPostImageData={post.oriPostImageData} 
                        oriPostPostTime={post.oriPostPostTime} oriPostUserfn={post.oriPostUserfn} oriPostUserln={post.oriPostUserln} groupID={groupId}/>
                    </p> */}
                    <p>
                        <Comment key={post.postID + post.userID} postID={post.postID} groupID={groupId} postUserID={post.userID} scivId={scivId} msgCode={msgCode}/>
                    </p>
                </div>
            </div>
            {/* Post user likes list - share- comment section Ends */}
        </div>
        {/* Post contents Ends*/}
        </div>
    );
};

export default GroupPostContent;