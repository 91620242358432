import { useState } from "react";
import { FaSearch } from "react-icons/fa";
import Swal from "sweetalert2";
import Cookies from "js-cookie";

import APICall from "../../../api";

import "../../../assets/css/searchBar.css";

export const SearchBar = ({ setSearchUser }) => {
  const [input, setInput] = useState("");

  const fetchData = (value) => {
    try {
      const contentType = "application/json";
      const endPoint = "User/getAllSearchUser";
      const bodyContent = {};
      
      APICall("POST", endPoint, bodyContent, contentType).then(
        (response) => {
          if (!response.success && response.message === 'SessionExpired') {
            localStorage.clear();
            window.location.href = '/login?sessionExpired=true';
          }

          if (response.success) {
            const data = response.data;
            const results = data.filter((user) => {
              var name = user.first_name + user.last_name;
              return (
                user.uid != Cookies.get("userId") && 
                value &&
                user &&
                name &&
                name.toLowerCase().startsWith(value.toLowerCase())
              );
            });
            
            setSearchUser(results);
          } else {
            console.error('Failed to get all users', response.message);
            Swal.fire({
              icon: "error",
              title: "Get all users failed!",
              text: "Something went wrong, please try again later",
            });
          }
        }
      );
    } catch (error) {
      console.error("An error occurred while getting all users", error);
    }
  };

  const handleChange = (value) => {
    setInput(value);
    fetchData(value);
  };

  return (
    <div className="input-wrapper">
      <FaSearch id="search-icon" />
      <input
        placeholder="Search for user..."
        value={input}
        onChange={(e) => handleChange(e.target.value)}
      />
    </div>
  );
};