import React from 'react';
import ReactDOM from 'react-dom/client';
import AppRouter from './AppRouter';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import '@fortawesome/fontawesome-svg-core';
import '@fortawesome/free-regular-svg-icons';
import '@fortawesome/free-solid-svg-icons';
import '@fortawesome/react-fontawesome';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <AppRouter/>
);
