import React, { useState, useEffect, useRef } from "react";
import { useLocation } from 'react-router-dom';
import { Modal } from "react-bootstrap";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { FaBoxOpen } from "react-icons/fa";
import dayjs from 'dayjs';

import Pagination from '@mui/material/Pagination';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
 
import APICall from "../../../api";
import Header from "../Header";
import { AiOutlineIssuesClose } from "react-icons/ai";

import "../../../assets/css/ModalIssue.css";
import 'sweetalert2/src/sweetalert2.scss';

const UserIssues = () => {
    const location = useLocation();
    const pop = location.state?.pop;
    dayjs.locale('en');
    const adapter = new AdapterDayjs();
    
    // ------------------------------------------------------
    // State hooks
    const [newIssue, setNewIssue] = useState(false);
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [allIssue, setAllIssue] = useState(pop ? false : true);
    const [allIssueContent, setAllIssueContent] = useState([]);
    const [issueCounts, setIssueCounts] = useState(null);
    const [ongoingIssue, setOngoingIssue] = useState(pop ? true : false);
    const [Closedissue, setClosedIssue] = useState(false);
    const [waitingIssue, setWaitingIssue] = useState(false);
    const [replyContent, setReplyContent] = useState("");
    const [getInIssue, setGetInIssue] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [page, setPage] = useState(1);
    const bottomRef = useRef(null);
    const pageItemCnt = 10;

  // ---------------------------------------------------------------------
  // API Calling
  const fetchIssues = async (issueId, type, date) => {
    try {
      const contentType = "application/json";
      const endPoint = "User/GetIssues";
      const bodyContent = {
        userId: Cookies.get("userId"),
        issueId: issueId,
        type: type,
        date: date
      }
      
      APICall("POST", endPoint, bodyContent, contentType).then(
        response => {
          if (!response.success && response.message === 'SessionExpired') {
            localStorage.clear();
            window.location.href = '/login?sessionExpired=true';
          }

          if (response.success) {
            const data = response.data;
            getUidIssues(date);
            setAllIssueContent(data);
          }
          else {
            console.error('Failed to get post', response.message);
            Swal.fire({
              icon: "error",
              title: "Get post failed!",
              text: response.message,
            });
          }
        }
      )    
    } catch (error) {
      console.error('An error occurred while fetching posts', error);
    }      
  };

  const addIssues = async (issueId) => {
    try {
      const contentType = "application/json";
      const endPoint = "User/AddIssue";
      const bodyContent = {
        userId: Cookies.get("userId"),
        title: title,
        content: content,
        issueId: issueId ? issueId : null
      }

      APICall("POST", endPoint, bodyContent, contentType).then(
        response => {
          if (!response.success && response.message === 'SessionExpired') {
            localStorage.clear();
            window.location.href = '/login?sessionExpired=true';
          }

          if (response.success) {
            const data = response.data;
            Swal.fire({
                icon: "success",
                title: "SUCCESS",
                text: "Issues Opened Successfully!",
              }).then((result) => {
                if (result.isConfirmed || result.dismiss === Swal.DismissReason.timer) {
                  window.location.reload();
                }
              });
          }
          else {
            console.error('Failed to get post', response.message);
            Swal.fire({
              icon: "error",
              title: "Open Issue failed!",
              text: "Something went wrong, please try again later",
            });
          }
        }
      )    
    } catch (error) {
      console.error('An error occurred while fetching posts', error);
    }      
  };  

  const replyIssue = async (issueId) => {
    try {
      if (replyContent.length <= 0) {
        Swal.fire({
          icon: "warning",
          title: "Empty Content",
          text: "Please input something to reply!",
        });
        return;       
      }

      const contentType = "application/json";
      const endPoint = "User/ReplyIssue";
      const bodyContent = {
        userId: Cookies.get("userId"),
        content: replyContent,
        issueId: issueId,
        is_admin: Cookies.get("is_admin")
      }

      APICall("POST", endPoint, bodyContent, contentType).then(
        response => {
          if (!response.success && response.message === 'SessionExpired') {
            localStorage.clear();
            window.location.href = '/login?sessionExpired=true';
          }

          if (response.success) {
            const data = response.data;
            Swal.fire({
                icon: "success",
                title: "SUCCESS",
                text: "Reply Successfully!",
              }).then((result) => {
                if (result.isConfirmed || result.dismiss === Swal.DismissReason.timer) {
                  setReplyContent("");
                  fetchIssues(issueId);
                }
              });
          }
          else {
            console.error('Failed to get post', response.message);
            Swal.fire({
              icon: "error",
              title: "Open Issue failed!",
              text: "Something went wrong, please try again later",
            });
          }
        }
      )    
    } catch (error) {
      console.error('An error occurred while fetching posts', error);
    }      
  };

  const getUidIssues = async (date) => {
    try {
      const contentType = "application/json";
      const endPoint = "User/GetUidIssues";
      const bodyContent = {
        userId: Cookies.get("userId"),
        date: date
      }

      APICall("POST", endPoint, bodyContent, contentType).then(
        response => {
          if (!response.success && response.message === 'SessionExpired') {
            localStorage.clear();
            window.location.href = '/login?sessionExpired=true';
          }

          if (response.success) {
            const data = response.data;
            setIssueCounts(data);
          }
          else {
            console.error('Failed to get issue', response.message);
            Swal.fire({
              icon: "error",
              title: "Get Issues Failed!",
              text: "Something went wrong, please try again later",
            });
          }
        }
      )    
    } catch (error) {
      console.error('An error occurred while fetching posts', error);
    }      
  };

    // ---------------------------------------------------------------------
    // Effect hooks 
    useEffect(() => {
        if (pop !== undefined) {
          fetchIssues(null, 1);
        } else {
          fetchIssues(null, null);
        }
        getUidIssues();
    }, []);

    useEffect(() => {
      if (bottomRef.current) {
          bottomRef.current.scrollIntoView({ behavior: 'smooth' });
      }
  }, [getInIssue, allIssueContent]);

    // ---------------------------------------------------------------------
    // Event function
    const handleIssue = () => {
        setNewIssue(false);
        addIssues();
    }

    const handleDateChange = (date) => {
      setSelectedDate(date);
      fetchIssues(null, ongoingIssue ? 1 : (Closedissue ? 2 : (waitingIssue ? 0 : null)), date.format('YYYY-MM-DD'));
    };
    
    return (
        <div>
            <Header /> 
            <div className="container" style={{ paddingTop: '3.9rem' }}>
                <div className="row" style={{ marginTop: '15px' }}>
                    {/* Left Panel Browze Starts */}
                    <div className="col-lg-3 col-md-3 col-sm-4 mb-3">
                        <div className="card rounded-0 shadow-sm">
                            <p className="text-black fw-semibold" style={{ marginBottom: '-5px', marginLeft: '30px', fontSize: '1.4rem', marginTop: '5px' }}>Browse</p>
                            <div className="d-flex align-items-center justify-content-center" style={{marginBottom: '-5px'}}>
                                <hr style={{ width: '80%' }}></hr>
                            </div>
                            <div className="d-flex align-items-center" style={{backgroundColor: '#4C6EE5', width: '80%', marginLeft: '30px', alignContent: 'center', height: '45px', cursor: 'pointer', backgroundColor: allIssue ? '#4C6EE5' : 'white'}}
                                 onClick={() => { setSelectedDate(null); setGetInIssue(false); setWaitingIssue(false); setClosedIssue(false); setOngoingIssue(false); setAllIssue(true); fetchIssues(null, null);}}>
                                <span className="" style={{marginLeft: '10px', fontWeight: '500', color: allIssue ? 'white' : 'gray', fontSize: '.9rem', marginTop: '1px'}}>All Issues</span>
                                <div style={{marginLeft: 'auto'}}>
                                    <span style={{marginRight: '3px', color: allIssue ? 'white' : 'gray'}}>{issueCounts ? issueCounts.all : "Loading.."}</span>
                                    <AiOutlineIssuesClose style={{fontSize: '1.3rem', color: allIssue ? 'white' : 'gray', marginRight: '6px', marginBottom: '1.5px'}}/>
                                </div>
                            </div>
                            <div className="d-flex align-items-center" style={{ width: '80%', marginLeft: '30px', alignContent: 'center', height: '45px', cursor: 'pointer', backgroundColor: ongoingIssue ? '#4C6EE5' : 'white'}}
                                 onClick={() => {setSelectedDate(null); setGetInIssue(false); setWaitingIssue(false); setClosedIssue(false); setAllIssue(false); setOngoingIssue(true); fetchIssues(null, 1);}}>
                                <span className="" style={{marginLeft: '10px', fontWeight: '500', fontSize: '.9rem', marginTop: '1px', color: ongoingIssue ? 'white' : 'gray'}}>Ongoing Issues</span>
                                <div style={{marginLeft: 'auto'}}>
                                    <span style={{marginRight: '3px', color: ongoingIssue ? 'white' : 'gray'}}>{issueCounts ? issueCounts.ongoing : "Loading.."}</span>
                                    <AiOutlineIssuesClose style={{fontSize: '1.3rem', color: ongoingIssue ? 'white' : 'gray', marginRight: '6px', marginBottom: '1.5px'}}/>
                                </div>
                            </div>
                            <div className="d-flex align-items-center" style={{ width: '80%', marginLeft: '30px', alignContent: 'center', height: '45px', cursor: 'pointer', backgroundColor: Closedissue ? '#4C6EE5' : 'white'}}
                                 onClick={() => {setSelectedDate(null); setGetInIssue(false); setWaitingIssue(false); setOngoingIssue(false); setAllIssue(false); setClosedIssue(true); fetchIssues(null, 2);}}>
                                <span className="" 
                                        style={{marginLeft: '10px', fontWeight: '500', fontSize: '.9rem', marginTop: '1px', color: Closedissue ? 'white' : 'gray'}}>Closed Issues</span>
                                <div style={{marginLeft: 'auto'}}>
                                    <span style={{marginRight: '3px', color: Closedissue ? 'white' : 'gray'}}>{issueCounts ? issueCounts.closed : "Loading.."}</span>
                                    <AiOutlineIssuesClose style={{fontSize: '1.3rem', color: Closedissue ? 'white' : 'gray', marginRight: '6px', marginBottom: '1.5px'}}/>
                                </div>
                            </div>
                            <div className="d-flex align-items-center" style={{ width: '80%', marginLeft: '30px', alignContent: 'center', height: '45px', cursor: 'pointer', marginBottom: '10px', backgroundColor: waitingIssue ? '#4C6EE5' : 'white'}}
                                 onClick={() => {setSelectedDate(null); setGetInIssue(false); setClosedIssue(false); setAllIssue(false); setOngoingIssue(false); setWaitingIssue(true); fetchIssues(null, 0);}}>
                                <span className="" style={{marginLeft: '10px', fontWeight: '500', fontSize: '.9rem', marginTop: '1px', color: waitingIssue ? 'white' : 'gray'}}>Waiting Issues</span>
                                <div style={{marginLeft: 'auto'}}>
                                    <span style={{marginRight: '3px', color: waitingIssue ? 'white' : 'gray'}}>{issueCounts ? issueCounts.waiting : "Loading.."}</span>
                                    <AiOutlineIssuesClose style={{fontSize: '1.3rem', color: waitingIssue ? 'white' : 'gray', marginRight: '6px', marginBottom: '1.5px'}}/>
                                </div>
                            </div>
                        </div>
                        {
                          !getInIssue ? (
                            <div className="shadow-sm" style={{backgroundColor: 'white', zIndex: 1000, marginTop: '10px'}}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateCalendar
                                  value={selectedDate}
                                  minDate={adapter.date(new Date(2024, 1, 1))}
                                  maxDate={adapter.date(new Date(2030, 12, 31))}
                                  onChange={handleDateChange}
                                />
                              </LocalizationProvider>
                            </div>
                          ) : null
                        }
                    </div>
                    {/* Left Panel Browze Ends */}

                    {/* Right Panel Issues Starts */}
                    <div className="col-lg-9 mb-3">
                        <div className="card rounded-0 shadow-sm">
                            <div className="d-flex" style={{alignItems: 'center'}}>
                                <span className="text-black fw-semibold" 
                                      style={{ marginBottom: '-5px', marginLeft: '30px', fontSize: '1.4rem', marginTop: '5px' }}>Issues</span>
                                <div style={{marginTop: '15px', marginLeft: 'auto'}}>
                                    <button className="rounded-1" 
                                            style={{ border: 'none', backgroundColor: '#5CB85C', marginLeft: 'auto', marginRight: '30px', color: '#ffffff', 
                                                 fontSize: '.9rem', height: '30px'}}
                                            onClick={() => setNewIssue(true)}
                                    >New Issue</button>
                                </div>
                                {
                                    newIssue && (
                                        <Modal show={newIssue} onHide={() => setNewIssue(false)} dialogClassName="custom-modal-issue">
                                            <Modal.Header>
                                                <Modal.Title style={{marginLeft: '10px', marginTop: '10px'}}>Open New Issue</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <div className="d-flex flex-column">
                                                    <span>Title</span>
                                                    <textarea style={{height: '50px', border: '2px solid #ccc', borderRadius: '5px'}}
                                                              placeholder=" Make a clear title"
                                                              onChange={e => {
                                                                // Check if Shift+Enter is pressed
                                                                if (e.shiftKey && e.key === 'Enter') {
                                                                  e.preventDefault();
                                                                  // Insert a newline character at the current cursor position
                                                                  const cursorPosition = e.target.selectionStart;
                                                                  const newTitle = title.substring(0, cursorPosition) + '\n' + title.substring(cursorPosition);
                                                                  setTitle(newTitle);
                                                                  } else {
                                                                  // Otherwise, update the textarea value as usual
                                                                  setTitle(e.target.value);
                                                                }
                                                              }}>
                                                    </textarea>

                                                    <span style={{marginTop: '20px'}}>Content</span>
                                                    <textarea style={{height: '250px', border: '2px solid #ccc', borderRadius: '5px'}} 
                                                              placeholder=" Type in your issue in detail"
                                                              onChange={e => {
                                                                // Check if Shift+Enter is pressed
                                                                if (e.shiftKey && e.key === 'Enter') {
                                                                  e.preventDefault();
                                                                  // Insert a newline character at the current cursor position
                                                                  const cursorPosition = e.target.selectionStart;
                                                                  const newContent = content.substring(0, cursorPosition) + '\n' + content.substring(cursorPosition);
                                                                  setContent(newContent);
                                                                  } else {
                                                                  // Otherwise, update the textarea value as usual
                                                                  setContent(e.target.value);
                                                                }
                                                              }}>
                                                    </textarea>
                                                </div>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <button className="btn btn-secondary" style={{backgroundColor: '#5CB85C', border: 'none'}} onClick={() => handleIssue()}>Open</button>
                                                <button className="btn btn-secondary" style={{backgroundColor: '#D9534F', border: 'none'}} onClick={() => setNewIssue(false)}>Close</button>
                                            </Modal.Footer>
                                        </Modal>
                                    )
                                }
                            </div>

                            <div className="d-flex align-items-center justify-content-center" style={{marginBottom: '-5px', marginTop: '-10px'}}>
                                <hr style={{ width: '94%' }}></hr>
                            </div>                            
                            
                            {
                                !getInIssue && allIssueContent !== null && allIssueContent.length > 0 ? (
                                  <>
                                    {allIssueContent.slice((page-1)*pageItemCnt, page*pageItemCnt).map((con, index) => {
                                        return (
                                            <div className="d-flex align-items-center justify-content-center mb-3" key = {"CON" + index}>
                                                <div className="card rounded-0 shadow-sm" style={{ width: '94%', cursor: 'pointer' }} onClick={() => {setGetInIssue(true); fetchIssues(con.iid, null)}}>
                                                    { con.hasUnseen === 1 && con.status === 1 ? (
                                                      <div className="d-flex" style={{position: 'absolute', color: '#D9534F'}}>
                                                        ●
                                                      </div>
                                                      ) : null
                                                    } 
                                                    <div style={{marginLeft: '10px', marginBottom: '10px'}}>
                                                        <div className="d-flex align-items-center" style={{marginTop: '10px'}}>
                                                            <img src={
                                                                con.profilePic === null ?
                                                                "/anon.jpg" :
                                                                `data:image/jpeg;base64,${con.profilePic}`
                                                              } style={{width: '40px', height: '40px', borderRadius: '20px'}}/>
                                                            <div className="d-flex flex-column" style={{marginLeft: '10px'}}>
                                                                <div className="d-flex">
                                                                    <span className="text-black-50 fw-semibold" style={{fontSize: '.91rem'}}>{con.title}</span>
                                                                    <button className="rounded-2" 
                                                                            style={{color: '#ffffff', marginLeft: '10px', border: 'none', fontSize: '.7rem', 
                                                                                    backgroundColor: con.status === 2 ? '#5CB85C' : (con.status === 1 ? '#D9534F' : '#F0AD4E'), fontWeight: '500'}}>
                                                                        {con.status === 2 ? 'RESOLVED' : (con.status === 1 ? 'ONGOING' : 'WAITING')}
                                                                    </button>
                                                                </div>
                                                                <span className="text-black-50 fw-normal" style={{fontSize: '.7rem'}}>Opened On: {con.created_at}</span>
                                                            </div>
                                                            <div className="d-flex" style={{marginLeft: 'auto', marginRight: '15px'}}>
                                                                <span className="fw-semibold" style={{fontSize: '.9rem', color: '#122f41'}}>Issue #{index+1}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    <div className="d-flex justify-content-end">
                                      <Pagination count={allIssueContent.length / pageItemCnt < 1 ? 1 : allIssueContent.length / pageItemCnt} style={{marginBottom: '10px'}} color="primary" 
                                       onChange={(event, page) => setPage(page)}/>
                                    </div>
                                  </>  
                                ) : (
                                  <div className="d-flex align-items-center justify-content-center">
                                    <span className="fw-semibold text-black-50">No Opened Issues!</span>
                                    <FaBoxOpen style={{ fontSize: '4rem', color: 'gray', marginLeft: '10px' }} />
                                  </div>
                                )
                            }

                            {
                               getInIssue && allIssueContent && (
                                <div className="d-flex flex-column align-items-center justify-content-center" style={{marginBottom: '15px'}}>
                                {allIssueContent.map((con, index) => {
                                    return (
                                        <>
                                            <div className="card rounded-2" style={{width: '94%', border: 'none'}} key = {"ISSUECON" + index} ref={bottomRef}>
                                                <div className="d-flex align-items-center" style={{marginLeft: '10px'}}>
                                                    <img src={
                                                          con.profilePic === null ?
                                                          "/anon.jpg" :
                                                          `data:image/jpeg;base64,${con.profilePic}`
                                                        } style={{width: '40px', height: '40px', borderRadius: '20px'}}/>
                                                    <div className="d-flex flex-column" style={{marginLeft: '10px'}}>
                                                      <div className="d-flex">
                                                        <span className="text-black-50 fw-semibold" style={{fontSize: '.91rem', marginBottom: '-3px'}}>{con.first_name + con.last_name}</span>
                                                        <span className="text-black fw-semibold" style={{fontSize: '.6rem', marginBottom: '-3px', marginLeft: '3px'}}>:{con.is_admin === 0 ? "User" : "Admin"}</span>
                                                      </div>
                                                        <span className="text-black-50 fw-normal" style={{fontSize: '.7rem'}}>{con.created_at}</span>
                                                    </div>
                                                </div>
                                                <div style={{marginBottom: '10px', marginLeft: '60px'}}>
                                                <p className="text-black fw-normal" style={{lineHeight: 1.2, fontSize: '.9rem'}} dangerouslySetInnerHTML={{__html: con.content.replace(/\n/g, '<br />')}}></p>
                                                </div>                            
                                            </div>
                                        </>
                                    )
                                })}
                                {
                                  allIssueContent.filter(con => con.creator === parseInt(Cookies.get("userId"))).length > 0
                                  && allIssueContent[0].status < 2? (
                                    <div className="d-flex align-items-center justify-content-center flex-column" style={{marginBottom: '10px', width: '100%'}}>
                                      <textarea style={{width: '95%', border: '2px solid #ccc', borderRadius: '5px', height: '150px'}} placeholder="Reply here..."
                                                value={replyContent}
                                          onChange={e => {
                                              // Check if Shift+Enter is pressed
                                              if (e.shiftKey && e.key === 'Enter') {
                                                  e.preventDefault();
                                                  // Insert a newline character at the current cursor position
                                                  const cursorPosition = e.target.selectionStart;
                                                  const newReplyContent = replyContent.substring(0, cursorPosition) + '\n' + replyContent.substring(cursorPosition);
                                                  setReplyContent(newReplyContent);
                                                  } else {
                                                  // Otherwise, update the textarea value as usual
                                                  setReplyContent(e.target.value);
                                              }
                                              }}>
                                      </textarea>
                                      <button className="rounded-1 text-black-50 fw-semibold"
                                              style={{marginLeft: 'auto', marginRight: '25px', marginTop: '10px', border: 'none', fontSize: '.9rem', width: '60px', height: '32px'}} 
                                              onClick={() => replyIssue(allIssueContent[0].issues_id)}>Send</button>
                                    </div>
                                  ) : null
                                }
                                </div>
                               ) 
                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserIssues;