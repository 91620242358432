import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { Dropdown } from "react-bootstrap";
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import GroupPostLazyLoader from "./GroupLazyLoad";
import PostNew from "../Post/Post-new";
import GroupMember from "./Groupmember";
import Invitation from "./Group-invitation";
import GroupRemove from "./Group-remove";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxOpen } from '@fortawesome/free-solid-svg-icons';

import { GoRepoLocked } from "react-icons/go";
import { GoTriangleDown } from "react-icons/go";
import { FaUserGroup } from "react-icons/fa6";
import { RiGitRepositoryPrivateFill } from "react-icons/ri";
import { HiDotsVertical } from "react-icons/hi";
import { HiPencil } from "react-icons/hi2";
import { BiPhotoAlbum } from "react-icons/bi";
import { FaUnlock } from "react-icons/fa6";
import ClipLoader from "react-spinners/ClipLoader";
import { Button } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import PlaceholderLoading from 'react-placeholder-loading';

import 'react-loading-skeleton/dist/skeleton.css';
import 'sweetalert2/src/sweetalert2.scss';
import '../../../assets/css/Groupmodal.css'

import APICall from "../../../api";
import HeaderLazyLoad from "../HeaderLazyLoad";
import PopChat from "../PopUpChat";
import UpdateAbout from "./Update-about";
import Visibility from "./Visibility";
import TransferLeader from "./Transfer-leader";
import QuitGroup from "./Group-quit";
import { Modal } from "react-bootstrap";
import { getImageDataFromLocalStorage } from '../../Login/login';


const Group = () => {
  // ----------------------------------------------------------------
  // State hooks
  const [postsData, setPosts] = useState(null);
  const [groupData, setGroupData] = useState([]);
  const [backgroundPicSrc, setBackgroundPicSrc] = useState(null);
  const [activeTab, setActiveTab] = useState('description');
  const [postNew, setPostNew] = useState(false);
  const [textareaValue, setTextAreaValue] = useState('');
  const [chatopen, setChatopen] = useState(false);
  const [groupMemberVisible, setGroupMemberVisible] = useState(false);
  const [invitationVisible, setInvitationVisible] = useState(false);
  const [removeVisible, setRemoveVisible] = useState(false);
  const [authorized, setAuthorized] = useState(true);
  const [grpLeader, setGrpLeader] = useState(false);
  const [showUpdateDropDown, setShowUpdateDropdown] = useState(false);
  const [showUpdateAbout, setShowUpdateAbout] = useState(false);
  const [showVisibility, setShowVisilibity] = useState(false);
  const [showTrasferLeader, setshowTrasferLeader] = useState(false);
  const [showQuit, setShowQuit] = useState(false);
  const [showAmount, setShowAmount] = useState(5);
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);
  const location = useLocation();
  const { viewGid } = useParams();
  let focusCount = 0;

  // ---------------------------------------------------------------------
  // Calling API
  const handleFileChange = (e) => {
    try {
      const file = e.target.files[0];
      const reader = new FileReader();
    
      reader.onload = (event) => {
        const imageData = event.target.result.split(',')[1];
        const contentType = "application/json";
        const endPoint = "Group/uploadGroupBgPicture";
        const bodyContent = {
          groupId: viewGid,
          image_data: imageData
        }

        APICall("POST", endPoint, bodyContent, contentType).then(
          response => {
            if (!response.success && response.message === 'SessionExpired') {
              localStorage.clear();
              window.location.href = '/login?sessionExpired=true';
            }

            if (response.success) {
              fetchBgPicture();
              Swal.fire({
                icon: 'success',
                title: 'Background updated successful!',
                text: 'Success!',
              });                
            }
            else {
              console.error('Failed to upload background image', response.message);
              Swal.fire({
                icon: "error",
                title: "Failed to uplaod background image",
                text: "Something went wrong, please try again later",
              });
            }
          }
        )   
      } 
      reader.readAsDataURL(file);
    } catch (error) {
      console.error('An error occurred while uploading background picture', error);
    }
  };

  const fetchBgPicture = async () => {
    try {
      const contentType = "application/json";
      const endPoint = "Group/retrieveGroupBGPicture";
      const bodyContent = {
        groupId: viewGid
      }

      APICall("POST", endPoint, bodyContent, contentType).then(
        response => {
          if (!response.success && response.message === 'SessionExpired') {
            localStorage.clear();
            window.location.href = '/login?sessionExpired=true';
          }

          if (response.success) {
            setBackgroundPicSrc(response.data);
          }
          else {
            console.error('Failed to get background picture', response.message);
            Swal.fire({
              icon: "error",
              title: "Get background picture failed!",
              text: "Something went wrong, please try again later",
            });
          }
        }
      )  
    } catch (error) {
      console.error('An error occurred while fetching profile data', error);
    }
  };
  
  const fetchPostData = async () => {
    try {
      const contentType = "application/json";
      const endPoint = "Post/getPost";
      const bodyContent = {
        groupId: viewGid,
        userId: Cookies.get("userId"),
        showAmount: showAmount 
      }
      APICall("POST", endPoint, bodyContent, contentType).then(
        response => {
          if (!response.success && response.message === 'SessionExpired') {
            localStorage.clear();
            window.location.href = '/login?sessionExpired=true';
          }

          if (response.success) {
            setPosts(response.data);          
          }
          else {
            console.error('Failed to get post', response.message);
            Swal.fire({
              icon: "error",
              title: "Get group post failed!",
              text: "Something went wrong, please try again later",
            });
          }
        }
      )    
    } catch (error) {
      console.error('An error occurred while fetching group post data', error);
    }
  };

  const publishPost = async () => {
    try {
      if (textareaValue.length > 1000) {
        // Fire alert for success
        Swal.fire({
          icon: "warning",
          title: "Words exceed limit!",
          text: "Only maximum 1000 character allowed",
        });
        return;       
      }
      
      const contentType = "application/json";
      const endPoint = "Post/createPost";
      const bodyContent = {
        "userId" : Cookies.get('userId'),
        "images" : [],
        "content" : textareaValue,
        "groupId" : viewGid
      }

      APICall("POST", endPoint, bodyContent, contentType).then(
        response => {
          if (!response.success && response.message === 'SessionExpired') {
            localStorage.clear();
            window.location.href = '/login?sessionExpired=true';
          }

          if (response.success) {
            // Fire alert for success
            Swal.fire({
              icon: "success",
              title: "SUCCESS",
              text: "Post Published!",
            }).then((result) => {
              if (result.isConfirmed || result.dismiss === Swal.DismissReason.timer) {
                window.location.reload();
              }
            });
          }
          else {
          // Fire alert for fail
            Swal.fire({
              icon: "error",
              title: "Post publishes failed!",
              text: "Something went wrong, please try again later",
            });
          }
        }
      )      

    } catch (error) {
      console.log("An error occured while publishing post", error);
    }
  };

  const checkIsInGroup = async () => {
    try {
      const contentType = "application/json";
      const endPoint = "User/isInGroup";
      const bodyContent = {
          userId : Cookies.get('userId'),
          groupId : viewGid
      }
      
      return APICall("POST", endPoint, bodyContent, contentType).then(
          response => {
            if (!response.success && response.message === 'SessionExpired') {
              localStorage.clear();
              window.location.href = '/login?sessionExpired=true';
            }

            if (response.success) {
              const data = response.data;
              if (!data.inGroup) {
                setAuthorized(false);
              }
            }
            else {
              console.error('Failed to validate group', response.message);
              Swal.fire({
                icon: "error",
                title: "validate group failed!",
                text: "Something went wrong, please try again later",
              });
            }
          }
        )
  } catch (error) {
      console.log("An error occured while validating group");
  }
}

  const getGroupData = async () => {
    try {
      const contentType = "application/json";
      const endPoint = "Group/getGroup";
      const bodyContent = {
          userId : Cookies.get('userId'),
          groupId : viewGid
      }

      return APICall("POST", endPoint, bodyContent, contentType).then(
          response => {
            if (!response.success && response.message === 'SessionExpired') {
              localStorage.clear();
              window.location.href = '/login?sessionExpired=true';
            }

            if (response.success) {
              const data = response.data;
              setGroupData(data);
            }
            else {
              console.error('Failed to get group', response.message);
              Swal.fire({
                icon: "error",
                title: "Get group failed!",
                text: "Something went wrong, please try again later",
              });
            }
          }
        )
    } catch (error) {
        console.log("An error occured while getting group");
    }
  }

  const isGroupLeader = async () => {
    try {
      const contentType = "application/json";
      const endPoint = "Group/IsGroupLeader";
      const bodyContent = {
          userId : Cookies.get('userId'),
          groupId : viewGid
      }
      
      APICall("POST", endPoint, bodyContent, contentType).then(
          response => {
            if (!response.success && response.message === 'SessionExpired') {
              localStorage.clear();
              window.location.href = '/login?sessionExpired=true';
            }

            if (response.success) {
              const isLeader = response.data;
              setGrpLeader(isLeader);
            }
            else {
              console.error('Failed to get group', response.message);
              Swal.fire({
                icon: "error",
                title: "Get group failed!",
                text: "Something went wrong, please try again later",
              });
            }
          }
        )
    } catch (error) {
        console.log("An error occured while getting group");
    }
  }

  const removeGroup = async () => {
    try {
      const contentType = "application/json";
      const endPoint = "Group/delete/" + viewGid;
      const bodyContent = {}
      
      APICall("DELETE", endPoint, bodyContent, contentType).then(
          response => {
            if (!response.success && response.message === 'SessionExpired') {
              localStorage.clear();
              window.location.href = '/login?sessionExpired=true';
            }

            if (response.success) {
              if (response.data) {
                Swal.fire({
                  icon: "success",
                  title: "Delete!",
                  text: "Successfull Deleted!",
                }).then((result) => {
                  if (result.isConfirmed || result.dismiss === Swal.DismissReason.timer) {
                    window.location.href = '/home';
                  }                  
                })
              }
            }
            else {
              console.error('Failed to remove group', response.message);
              Swal.fire({
                icon: "error",
                title: "Remove group failed!",
                text: "Something went wrong, please try again later",
              });
            }
          }
        )
    } catch (error) {
        console.log("An error occured while getting group");
    }    
  }
  
  
  // ---------------------------------------------------------------------
  // Effect hooks
  useEffect(() => {
    checkIsInGroup().then(() => {
      getGroupData().then(() => {
        isGroupLeader().then(() => {
          fetchBgPicture().then(() => {
            fetchPostData();
          })
        })
      })
    });
  }, []);

  useEffect(() => {
		// Check for sessionExpired query parameter
		const params = new URLSearchParams(location.search);
		const openchat = params.get('openchat');
	
		if (openchat === 'true') {
      setTimeout(() => {
        handleChat();
      }, 500)
		}
	  }, [location.search]);

  useEffect(() => {
    if (showAmount > 5) {
      fetchPostData();
    }
  }, [showAmount]);


  // ---------------------------------------------------------------------
  // Event functions
  const handleTabClick = (tab, event) => {
    event.preventDefault();
    setActiveTab(tab);
  };  

  const handleBGClick = (type) => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleClickLoading = () => {
    setShowAmount(prevAmount => prevAmount + 5);
    setLoading(true);

    setTimeout(() => {
        setLoading(false);
    }, 1000);
  }

  const handlePublish = () => {
    if (textareaValue.length <= 0) {
      Swal.fire({
        icon: "warning",
        title: "Post content empty!",
        text: "Please input something!",
      });
      return;
    } 

    publishPost();
  }

  const handleMemberClick = () => {
    setGroupMemberVisible(true);
  }

  const handleCloseMemberClick = () => {
    setGroupMemberVisible(false);
  }  

  const handleCloseInvitationClick = () => {
    setInvitationVisible(false);
  }  

  const handleCloseRemoveClick = () => {
    setRemoveVisible(false);
  }

  const handleCloseCloseUpdateAbout = () => {
    setShowUpdateAbout(false);
  }

  const handleCloseVisibility = () => {
    setShowVisilibity(false);
  }

  const handleCloseTransferLeader = () => {
    setshowTrasferLeader(false);
  }

  const handleCloseQuit = () => {
    setShowQuit(false);
  }

  const deleteGroup = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
        removeGroup();
      }
    })
  }

  const handleBackToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleChat = () => {
    setChatopen(!chatopen);
  }

  // ------------------------------------------------------------------------
  // Styling
  const buttonStyle = {
    marginRight: '10px',
    width: '12%',
    height: '40px',
    backgroundColor: 'white',
    fontSize: '.8rem',
    cursor: 'pointer',
    zIndex: 1000
  };

  const cardList = {
    height: "3.7rem",
  };

  const emojiSettings = {
    borderRadius: '50%',
    border: '2px solid #E8E8E8',
    padding: '4px',
    fontSize: '20px',
    width: '40px',
    height: '40px',
    background: '#F5F5F5',
    cursor: 'pointer'
  }

  return (
    <>
    <HeaderLazyLoad />
      <body style={{backgroundColor: '#F2F3F5', paddingTop: '3.8rem'}}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12" style={{ marginLeft: "6%" }}>
              <div
                className="card text-center border-0"
                style={{ width: "90%" }}
              >
                <img
                  src={
                    backgroundPicSrc !== null ?
                    `data:image/jpeg;base64,${backgroundPicSrc}` :
                    "/default_background.png"
                  }
                  className="img-fluid rounded"
                  style={{ width: "100%", height: "20rem" }}
                  alt="Banner"
                />
                
                <div className="card-body" style={{ background: '#F2F3F5', border: 'none' }}>
                  <div style={{ marginLeft: '10px', display: 'flex', flexDirection: 'column' }}>
                    {
                      authorized && authorized === true ? (
                        <div className="mt-2" style={{ display: 'flex', alignItems: 'center', marginLeft: '-26px'}}>
                        {
                          grpLeader && grpLeader === true ? (
                            <>
                            <button className="rounded-4 border text-black-50 fw-bold" style={buttonStyle} onClick={handleBGClick}>Background</button>
                            <input
                              type="file"
                              ref={fileInputRef}
                              style={{ display: 'none' }}
                              onChange={handleFileChange}
                            />                            
                            </>                            
                          ) : (
                            <>
                            <button className="rounded-4 border text-black-50 fw-bold" style={buttonStyle} onClick={() => setShowQuit(true)}>Quit</button>
                            {
                              showQuit ? (
                                <QuitGroup showModal={showQuit} handleClose={handleCloseQuit} groupId={viewGid}/>
                              ) : null
                            }
                            </>
                          )
                        }
                        <button className="rounded-4 border text-black-50 fw-bold" style={buttonStyle} onClick={() => setInvitationVisible(true)}>Invite</button>
                        {
                          invitationVisible && (
                            <div className="d-flex justify-content-center align-items-center" style={{ position: 'fixed', top: '50%', left: '50%', paddingBottom: '2%', transform: 'translate(-50%, -50%)', 
                                        maxWidth: '800px', width: '100%', background: 'white', zIndex: '1000' }}>                                     
                              <Modal.Body>
                              <button onClick={handleCloseInvitationClick} style={{ position: 'absolute', top: '10px', right: '10px',
                                      fontSize: '1.2em', background: 'transparent', border: 'none' }}>
                                &times;
                              </button>                                     
                                <Invitation groupId={viewGid}/>
                              </Modal.Body>
                            </div>
                          )
                        }

                        {
                          grpLeader && grpLeader === true ? (
                            <>
                            <button className="rounded-4 border text-black-50 fw-bold" style={{...buttonStyle, zIndex: 999}} 
                                    onClick={() => setRemoveVisible(true)}>Remove</button>
                            <div style={{ display: 'inline-block', position: 'relative', marginLeft: 'auto' }}>
                              <button
                                className="rounded-2 border text-black-50 fw-bold"
                                style={{ ...buttonStyle, marginLeft: 'auto', width: '110%' }}
                                onClick={() => setShowUpdateDropdown(!showUpdateDropDown)}
                              >
                                Update Settings <GoTriangleDown style={{fontSize: '1.3rem', marginLeft: '4px'}}/>
                              </button>

                              {showUpdateDropDown && (
                                <Dropdown
                                  className="form-group mb-2 justify-content-between align-items-center mt-2"
                                  style={{ border: '0px', position: 'absolute', left: 0, top: '100%' }}
                                  show={showUpdateDropDown}
                                >
                                  <Dropdown.Menu style={{ width: '163px', minWidth: '110px' }}>
                                    <Dropdown.Item style={{ height: '30px', paddingTop: '0px' }}>
                                      <div className="d-flex align-items-center justify-content-center" onClick={() => setShowUpdateAbout(true)}>
                                        <span className="quantity ml-3 text-black-50 flex-grow-1" style={{ fontWeight: 500 }}>Update Introduction</span>
                                      </div>
                                    </Dropdown.Item>
                                    <Dropdown.Item style={{ height: '35px', borderTop: '1px solid #ccc' }}>
                                      <div className="d-flex align-items-center" onClick={() => setShowVisilibity(true)}>
                                        <span className="quantity ml-3 text-black-50 flex-grow-1" style={{ fontWeight: 500 }}>Change Visibility</span>
                                      </div>
                                    </Dropdown.Item>
                                    <Dropdown.Item style={{ height: '35px', borderTop: '1px solid #ccc' }}>
                                      <div className="d-flex align-items-center" onClick={() => setshowTrasferLeader(true)}>
                                        <span className="quantity ml-3 text-black-50 flex-grow-1" style={{ fontWeight: 500 }}>Transfer Leader</span>
                                      </div>
                                    </Dropdown.Item>                                
                                    <Dropdown.Item style={{ height: '35px', borderTop: '1px solid #ccc' }}>
                                      <div className="d-flex align-items-center" onClick={() => deleteGroup()}>
                                        <span className="quantity ml-3 text-black-50 flex-grow-1" style={{ fontWeight: 500 }}>Delete Group</span>
                                      </div>
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              )}
                              {
                                showUpdateAbout && (
                                  <UpdateAbout showModal={showUpdateAbout} handleClose={handleCloseCloseUpdateAbout} groupId={viewGid}/>
                                )
                              } 
                              {
                                groupData && showVisibility && (
                                  <Visibility showModal={showVisibility} handleClose={handleCloseVisibility} visibility={groupData.private} groupId={viewGid}/>
                                )
                              } 
                              {
                                showTrasferLeader && (
                                  <div className="d-flex justify-content-center align-items-center" style={{ position: 'fixed', top: '50%', left: '50%', paddingBottom: '2%', transform: 'translate(-50%, -50%)', 
                                  maxWidth: '800px', width: '100%', background: 'white', zIndex: '1000' }}>                                     
                                  <Modal.Body>
                                  <button onClick={handleCloseTransferLeader} style={{ position: 'absolute', top: '10px', right: '10px',
                                          fontSize: '1.2em', background: 'transparent', border: 'none' }}>
                                    &times;
                                  </button>                                     
                                    <TransferLeader groupId={viewGid}/>
                                  </Modal.Body>
                                </div>
                                )
                              }                                                        
                            </div>
                            </>
                          ) : null
                        }
                        {
                          removeVisible && (
                            <div className="d-flex justify-content-center align-items-center" style={{ position: 'fixed', top: '50%', left: '50%', paddingBottom: '2%', transform: 'translate(-50%, -50%)', 
                                        maxWidth: '800px', width: '100%', background: 'white', zIndex: '1000' }}>                                     
                              <Modal.Body>
                              <button onClick={handleCloseRemoveClick} style={{ position: 'absolute', top: '10px', right: '10px',
                                      fontSize: '1.2em', background: 'transparent', border: 'none' }}>
                                &times;
                              </button>                                     
                                <GroupRemove groupId={viewGid}/>
                              </Modal.Body>
                            </div>
                          )
                        }                                                          
                      </div>
                      ) : (
                        <div className="mt-5"> </div>
                      )
                    }
                    <div style={{marginTop: '-35px'}}>
                      <h5 className="card-title" style={{ margin: 0 }}>
                        {groupData ? groupData.groupName : "Loading..."}
                      </h5>
                      <p className="card-text">{groupData ? (groupData.private ? "Private Group" : "Public Group") : "Loading..."}</p>
                    </div>                    
                  </div>
                </div>
            </div>
            </div>

            {/* Bottom part*/}
            {/* Bottom part left panel*/}
            <div class="row">
              {/* Left Panel Group Info*/}
              <div class="col-lg-4" style={{ marginLeft: "6%", width: "30%" }}>
                <div class="card mb-2 rounded-4"  style={{width: "95%"}}>
                  <div class="card-body">
                    <div className="d-flex">
                      <h5 class="card-text">About</h5>
                      <HiDotsVertical className="mt-2" style={{marginLeft: 'auto', color: 'black', cursor: 'pointer'}}/>
                    </div>
                    <div>
                      <span className="fw-bold text-black-50" style={{ display: 'block', fontSize: '.9rem' }}>
                        <FaUserGroup style = {{marginTop: '-3px'}}/> {groupData ? groupData.groupMemberCount : '1'} Members 
                      </span>
                      <span className="fw-semibold text-black-50" style={{ display: 'block', fontSize: '.9rem', marginLeft: '19px', marginTop: '-5px'  }}>
                        Invite more members! 
                      </span>
                      <span className="fw-bold text-black-50" style={{ display: 'block', fontSize: '.9rem' }}>
                        {
                          groupData ? (
                            groupData.private === 1 ? (
                              <>
                              <RiGitRepositoryPrivateFill style = {{marginTop: '-4px'}}/> 
                              <span style={{marginLeft: '3px'}}>Private</span>
                              <span className="fw-semibold text-black-50" style={{ display: 'block', fontSize: '.9rem', marginLeft: '19px', marginTop: '-3px', lineHeight: '1.0'}}>
                                Only members in this group can view the group's content!
                              </span>    
                              </>
                            ) : (
                              <>
                              <FaUnlock style = {{marginTop: '-4px'}}/>
                              <span style={{marginLeft: '3px'}}>Public</span>
                              <span className="fw-semibold text-black-50" style={{ display: 'block', fontSize: '.9rem', marginLeft: '19px', marginTop: '-5px' }}>
                                Everyone can view this!
                              </span>                                 
                              </>
                            )
                          ) : "Loading...."
                        }
                      </span>         
                      <p className="mt-3" style={{fontSize: '.95rem', fontWeight: 500, lineHeight: '1.1'}}>
                        {groupData ? groupData.about : 'Loading...'}
                      </p>
                    </div>
                  </div>
                </div>
                
                {/* Left Panel Group Info Ends*/}

                {/* Left Panel Function*/}
                <div class="col-lg-4" style={{width: "95%" }}>
                  {
                    (groupData && !groupData.private) || (authorized && authorized === true )? (
                      <>
                      <div class="card border-bottom-0" style={{ borderTopLeftRadius: '1rem', borderTopRightRadius: '1rem', borderBottomLeftRadius: '0rem', borderBottomRightRadius: '0rem'}}>
                        <div class="card-body">
                          <h5 class="card-text text-black-50 fs-6">Showlists</h5>
                        </div>
                      </div>
                      <div class="card mb-2">
                        <ul class="list-group list-group-flush" style={{ borderTopLeftRadius: '0rem', borderTopRightRadius: '0rem'}}>
                          <li class="list-group-item d-flex flex-column align-items-start" style={cardList}>
                            <div className="d-flex align-items-center">
                              <span style={emojiSettings} onClick={handleMemberClick}>🔥</span>
                              {
                                groupMemberVisible && (
                                  <div className="d-flex justify-content-center align-items-center" style={{ position: 'fixed', top: '50%', left: '50%', paddingBottom: '2%', transform: 'translate(-50%, -50%)', 
                                              maxWidth: '1200px', width: '100%', background: 'white', zIndex: '1000' }}>                                     
                                    <Modal.Body>
                                    <button onClick={handleCloseMemberClick} style={{ position: 'absolute', top: '10px', right: '10px',
                                            fontSize: '1.2em', background: 'transparent', border: 'none' }}>
                                      &times;
                                    </button>                                     
                                      <GroupMember groupId={viewGid}/>
                                    </Modal.Body>
                                  </div>
                                )
                              }
    
                              <div className="d-flex flex-column" style={{marginLeft: '10px'}}>
                                <span className="fw-semibold " style={{ marginBottom: "-3%", fontSize: '.9rem' }}> Team Members </span>
                                <span className="text-black-50" style={{ fontSize: '.8rem', fontWeight: 400 }}> Member List</span>
                              </div>
                            </div>
                          </li>
                          <li class="list-group-item d-flex flex-column align-items-start" style={cardList}>
                            <div className="d-flex align-items-center">
                              <span style={emojiSettings}>🚩</span>
                              <div className="d-flex flex-column" style={{marginLeft: '10px'}}>
                                <span className="fw-semibold " style={{ marginBottom: "-3%", fontSize: '.9rem' }}> Current Points </span>
                                <span className="text-black-50" style={{ fontSize: '.8rem', fontWeight: 400 }}> Points</span>
                              </div>
                            </div>                      
                          </li>
                          <li class="list-group-item d-flex flex-column align-items-start" style={cardList}>
                            <div className="d-flex align-items-center">
                              <span onClick={handleBackToTop} className="emoji" style={emojiSettings}>🗃️</span>
                              <div className="d-flex flex-column" style={{marginLeft: '10px'}}>
                                <span className="fw-semibold" style={{ marginBottom: "-3%", fontSize: '.9rem' }}> Group Post </span>
                                <span className="text-black-50" style={{ fontSize: '.8rem', fontWeight: 400 }}> Post</span>
                              </div>
                            </div>                      
                          </li>
                        </ul>
                      </div>
                      <div>
                        
                        {
                          <PopChat gid={viewGid} handleClose={handleChat} chatShow={chatopen} onClick={handleChat} focusCount={focusCount}/>
                        }
                      </div>
                      </>
                    ) : null
                  }

                </div>                                  
                {/* Left Panel Function Ends*/}
              </div>
              {/* Bottom part left panel Ends*/}

              {/* Bottom part right panel*/}
              <div class="col-lg-7" style={{ marginLeft: "1%", width: "61%" }}>
                <div class="card mb-2 rounded-4">
                  <div class="card-body">
                    <h5 class="card-text">Posts</h5>
                  </div>
                </div>

                {
                  (groupData && !groupData.private) || (authorized && authorized === true) ? (
                    <>
                    <div className="card mb-3">
                      { authorized && authorized === true ? (
                        <>
                        <div className="card-header" style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: '8px', paddingRight: '8px' }}>
                          <ul className="nav nav-tabs card-header-tabs" id="bologna-list" role="tablist">
                            <li className="nav-item" style={{width: '50%'}}>
                              <a className={`nav-link ${activeTab === 'description' ? 'active' : ''}  d-flex justify-content-center align-items-center`} 
                                  role="tab" style={{cursor: 'pointer', fontWeight: '500', color:'gray' }} onClick={(e) => handleTabClick('description', e)}>
                                <HiPencil style={{marginRight: '5px'}}/>
                                Publish
                              </a>
                            </li>
                            <li className="nav-item" style={{width: '50%'}}>
                              <a className={`nav-link ${activeTab === 'history' ? 'active' : ''}  d-flex justify-content-center align-items-center`} 
                                  role="tab" style={{ width: '100%', cursor: 'pointer', fontWeight: '500', color:'gray' }} 
                                  onClick={(e) => {
                                    handleTabClick('history', e);
                                    setPostNew(true);
                                  }}>
                                <BiPhotoAlbum style={{marginRight: '5px'}}/>
                                Photo
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className="card-body" style={{margin: 0, padding: 0}}>
                        <div className="tab-content">
                          <div className={`tab-pane ${activeTab === 'description' ? 'active' : ''}`} role="tabpanel">
                            <div class="card-body d-flex align-items-center" style={{marginLeft: '-2px', transform: 'translateY(1%)', marginTop: '-1px'}}>
                              <img
                                className="mr-3 mb-2 rounded-circle"
                                alt="Bootstrap Media Preview"
                                style={{ width: '40px', height: '40px', marginTop: '-20px'}}
                                src={
                                  getImageDataFromLocalStorage() === "null"
                                    ? "/anon.jpg"
                                    : `data:image/jpeg;base64,${getImageDataFromLocalStorage()}`
                                }
                              />
                              <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" type="text" value={textareaValue}
                                        placeholder= "Type something...." style={{marginLeft: '1px', border: 'None'}} 
                                        onChange={e => {
                                          // Check if Shift+Enter is pressed
                                          if (e.shiftKey && e.key === 'Enter') {
                                            e.preventDefault();
                                            // Insert a newline character at the current cursor position
                                            const cursorPosition = e.target.selectionStart;
                                            const newText = textareaValue.substring(0, cursorPosition) + '\n' + textareaValue.substring(cursorPosition);
                                            setTextAreaValue(newText);
                                          } else {
                                            // Otherwise, update the textarea value as usual
                                            setTextAreaValue(e.target.value);
                                          }
                                        }}
                                        >
                              </textarea>              
                            </div>
                            <div style={{ borderTop: '1px solid #ccc', width: '100%' }}></div>
                          </div>
                          
                          <div className={`tab-pane ${activeTab === 'history' ? 'active' : ''}`} role="tabpanel">
                            <PostNew is_admin = {Cookies.get("is_admin")} click = {postNew} groupID = {Cookies.get("groupId")} style={{ position: 'relative', zIndex: 0 }}/>
                          </div>
                        </div>
                        <div style={{ borderTop: '1px solid #ccc', width: '100%' }}></div>
                        <div>
                          <button className="d-flex align-items-center justify-content-center mt-1 mb-1" variant="outline-primary" 
                                  style={{marginLeft: 'auto', marginRight: '15px', borderRadius: '20px', border: 'None', backgroundColor: '#F0F0F0',
                                        fontSize: '20px', textTransform: 'uppercase', fontWeight: '700', width: '35px', height: '35px'}}
                                        onClick={() => handlePublish()}>
                            <HiPencil/>
                          </button>
                        </div>
                      </div>
                      </>
                        ) : null
                      }
                    </div>
  
                    <div className="row">
                      {/* call post api  */}
                      {postsData && postsData.length > 0 ? (
                        <>
                        {postsData.map((post, index) => {
                          return (
                            <React.Fragment key={post.postID}>
                              {post.sharePostID !== null ? (
                                postsData
                                  .filter(sharedPost => sharedPost.postID === post.sharePostID)
                                  .map((sharedPost, index) => {
                                    if (sharedPost.sharePostID !== null) {
                                      sharedPost = postsData.find((nestSharePost) => sharedPost.sharePostID === nestSharePost.postID);
                                    }

                                    // Append or modify properties in sharedPost
                                    post.oriPostUserId = sharedPost.userID;
                                    post.oriPostUserfn = sharedPost.first_name;
                                    post.oriPostUserln = sharedPost.last_name;
                                    post.oriPostProfilePic = sharedPost.profilePic;
                                    post.oriPostContent = sharedPost.content;
                                    post.oriPostImageData = sharedPost.image_data;
                                    post.oriPostPostTime = sharedPost.postTime;
                                    post.oriIsAdmin = sharedPost.is_admin;
        
                                    return (
                                      <React.Fragment key={index}>
                                        <GroupPostLazyLoader key={index} post={post} groupId={viewGid} />
                                        {/* <GroupPostContent key={index} post={post} groupId={viewGid} /> */}
                                      </React.Fragment>
                                    );
                                  })
                              ) : (
                                <React.Fragment key={index}>
                                  <GroupPostLazyLoader post={post} groupId={viewGid} />
                                  {/* <GroupPostContent post={post} groupId={viewGid}/> */}
                                </React.Fragment>
                              )}
                            </React.Fragment>
                          );
                        })}
                        {
                          loading === false ? (
                            <div className="d-flex align-items-center justify-content-center mb-4">
                              <Button className="mt-2 rounded-1"
                                  style={{backgroundColor: 'gray', border: 'gray solid 2px', width:'130px'}}
                                  onClick={() => handleClickLoading()}
                                  onMouseEnter={(e) => {
                                  e.target.style.color = 'black';  // Change font color on hover
                                  }}
                                  onMouseLeave={(e) => {
                                  e.target.style.color = 'white';  // Revert font color when mouse leaves
                                  }}>
                                  {postsData && postsData.length < showAmount ? "No More Post!" : (
                                    "Load More"
                                  )}
                              </Button>   
                            </div>
                          ) : (
                            <div className="d-flex align-items-center justify-content-center mb-4">
                              <ClipLoader></ClipLoader>
                            </div>
                          )
                        }
                        </>
                        ) : postsData && postsData.length <= 0 ? (
                          <div className="d-flex align-items-center justify-content-center mt-2">
                            <div className="flex-column align-items-center justify-content-center">
                              <span className="text-black-50 fw-semibold" style={{fontSize: '1.3rem', display: 'block'}}>No Post Yet!</span>
                              <FontAwesomeIcon icon={faBoxOpen} style={{fontSize: '5rem', display: 'block', color: 'gray', marginLeft: '8px'}}/>
                            </div>
                          </div>
                        ) : postsData === null && (
                          <>
                            <div className='mb-4'>
                              <div className='d-flex align-items-center mb-3'>
                                <PlaceholderLoading shape="circle" width={40} height={40} />
                                <div className='flex-column' style={{marginLeft: '10px'}}>
                                  <PlaceholderLoading shape="rect" width={340} height={15} />
                                  <PlaceholderLoading shape="rect" width={250} height={15} />
                                </div>
                              </div>
                              <Skeleton count={4} height={15}/>      
                            </div>

                            <div className='mb-4'>
                              <div className='d-flex align-items-center mb-3'>
                                <PlaceholderLoading shape="circle" width={40} height={40} />
                                <div className='flex-column' style={{marginLeft: '10px'}}>
                                  <PlaceholderLoading shape="rect" width={340} height={15} />
                                  <PlaceholderLoading shape="rect" width={250} height={15} />
                                </div>
                              </div>
                              <Skeleton count={4} height={15}/>      
                            </div>    

                            <div className='mb-4'>
                              <div className='d-flex align-items-center mb-3'>
                                <PlaceholderLoading shape="circle" width={40} height={40} />
                                <div className='flex-column' style={{marginLeft: '10px'}}>
                                  <PlaceholderLoading shape="rect" width={340} height={15} />
                                  <PlaceholderLoading shape="rect" width={250} height={15} />
                                </div>
                              </div>
                              <Skeleton count={4} height={15}/>      
                            </div>   
                          </>
                        )
                      }                            
                    </div>
                    </>
                  ) : (
                    <div className="d-flex align-items-center justify-content-center mt-4">
                      <GoRepoLocked style={{fontSize: '5rem', color: 'gray'}}/>
                      No authority to view this group's content as you're not a member of this group.
                    </div>
                    
                  )
                }

              </div>
            </div>

          </div>
        </div>
      </body>
    </>
  );
};

export default Group;
